import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../component/common/Layout";
//Pages
// import { lazy } from "react";

import Login from "../pages/auth/login";
import Dashboard from "../pages/admin/Dashboard";
import Setting from "../pages/admin/Setting";
import Profile from "../pages/admin/profile/Profile";
import ChangePass from "../pages/admin/profile/ChangePass";
import ActiveSalesman from "../pages/admin/salesman/activeSalesman";
import PendingSalesman from "../pages/admin/salesman/pendingSalesman";
import InactiveSalesman from "../pages/admin/salesman/inactiveSalesman";
import DeleteSalesman from "../pages/admin/salesman/deleteSalesman";
import SalesmanDetail from "../pages/admin/salesman/salesmanDetail";
import EditSalesman from "../pages/admin/salesman/editSalesman";
import ActiveMerchant from "../pages/admin/merchant/activeMerchant";
import DeleteMerchant from "../pages/admin/merchant/deleteMerchant";
import PendingMerchant from "../pages/admin/merchant/pendingMerchant";
import EditMerchant from "../pages/admin/merchant/editMerchant";
import AddMerchant from "../pages/admin/merchant/addMarchant";
import AddSalesman from "../pages/admin/salesman/addSalesman";
import MerchantDetail from "../pages/admin/merchant/merchantDetail";
import Country from "../pages/admin/Country";
import Province from "../pages/admin/Province";
import City from "../pages/admin/City";
import AddCountry from "../pages/admin/AddCountry";
import AssignRequest from "../pages/admin/request/AssignRequest";
import CompletedRequest from "../pages/admin/request/CompletedRequest";
import NotCompletedRequest from "../pages/admin/request/NotCompletedReq";
import PendingRequest from "../pages/admin/request/PendingRequest";
import AddProvince from "../pages/admin/AddProvince";
import AddCity from "../pages/admin/AddCity";
import RequestDetail from "../pages/admin/request/RequestDetail";
import Privacy from "../pages/admin/cms/Privacy";
import Terms from "../pages/admin/cms/Terms";
import Region from "../pages/admin/region/Region";
import AddRegion from "../pages/admin/region/AddRegion";
const basePath = "";

const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

const adminRoutes = [
  {
    path: generatePath("dashboard"),
    element: <Dashboard />,
  },
  {
    path: generatePath("setting"),
    element: <Setting />,
  },
  {
    path: generatePath("profile"),
    element: <Profile />,
  },
  {
    path: generatePath("changepass"),
    element: <ChangePass />,
  },
  {
    path: generatePath("location/Region"),
    element: <Region />,
  },
  {
    path: generatePath("location/Region/:id"),
    element: <AddRegion />,
  },
  {
    path: generatePath("location/Region/add"),
    element: <AddRegion />,
  },
  {
    path: generatePath("location/countries"),
    element: <Country />,
  },
  {
    path: generatePath("location/countries/add"),
    element: <AddCountry />,
  },
  {
    path: generatePath("location/countries/:id"),
    element: <AddCountry />,
  },
  {
    path: generatePath("location/provinces"),
    element: <Province />,
  },
  {
    path: generatePath("location/provinces/add"),
    element: <AddProvince />,
  },
  {
    path: generatePath("location/provinces/:id"),
    element: <AddProvince />,
  },
  {
    path: generatePath("location/cities"),
    element: <City />,
  },
  {
    path: generatePath("location/cities/add"),
    element: <AddCity />,
  },
  {
    path: generatePath("location/cities/:id"),
    element: <AddCity />,
  },
  {
    path: generatePath("cms/terms"),
    element: <Terms />,
  },
  {
    path: generatePath("cms/privacy"),
    element: <Privacy />,
  },
  {
    path: generatePath("merchandiser/add"),
    element: <AddSalesman />,
  },
  {
    path: generatePath("merchandiser/pending"),
    element: <PendingSalesman />,
  },
  {
    path: generatePath("merchandiser/active"),
    element: <ActiveSalesman />,
  },
  {
    path: generatePath("merchandiser/Inactive"),
    element: <InactiveSalesman />,
  },
  {
    path: generatePath("merchandiser/delete"),
    element: <DeleteSalesman />,
  },
  {
    path: generatePath("merchandiser/active/:id"),
    element: <SalesmanDetail />,
  },
  {
    path: generatePath("merchandiser/Inactive/:id"),
    element: <SalesmanDetail />,
  },
  {
    path: generatePath("merchandiser/pending/:id"),
    element: <SalesmanDetail />,
  },
  {
    path: generatePath("merchandiser/delete/:id"),
    element: <SalesmanDetail />,
  },
  {
    path: generatePath("editmerchandiser/:id"),
    element: <EditSalesman />,
  },
  {
    path: generatePath("merchant/active"),
    element: <ActiveMerchant />,
  },
  {
    path: generatePath("merchant/Inactive"),
    element: <PendingMerchant />,
  },
  {
    path: generatePath("merchant/delete"),
    element: <DeleteMerchant />,
  },
  {
    path: generatePath("merchant/active/:id"),
    element: <MerchantDetail />,
  },
  {
    path: generatePath("merchant/Inactive/:id"),
    element: <MerchantDetail />,
  },
  {
    path: generatePath("merchant/delete/:id"),
    element: <MerchantDetail />,
  },
  {
    path: generatePath("merchant/add"),
    element: <AddMerchant />,
  },
  {
    path: generatePath("editmerchant/:id"),
    element: <EditMerchant />,
  },
  {
    path: generatePath("request/assign"),
    element: <AssignRequest />,
  },
  {
    path: generatePath("request/pending"),
    element: <PendingRequest />,
  },
  {
    path: generatePath("request/completed"),
    element: <CompletedRequest />,
  },
  {
    path: generatePath("request/notcompleted"),
    element: <NotCompletedRequest />,
  },
  {
    path: generatePath("request/notcompleted/:id"),
    element: <RequestDetail />,
  },
  {
    path: generatePath("request/completed/:id"),
    element: <RequestDetail />,
  },
  {
    path: generatePath("request/pending/:id"),
    element: <RequestDetail />,
  },
];

const AdminNavigation = () => {
  const user = JSON.parse(localStorage.getItem("user-details"));
  return (
    <Routes>
      {/* if user is logged in redirect to dashboard */}
      <Route
        path=""
        element={!user ? <Login /> : <Navigate to="dashboard" />}
      />
      {/* if user is not present show unauthorized ui */}
      <Route element={!user ? <Navigate to="/" /> : <Layout />}>
        {adminRoutes.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        {/* all undefined routes are handled here for admin */}
      </Route>
      <Route
        path="*"
        element={!user ? <Navigate to="/" /> : <Navigate to="dashboard" />}
      />
    </Routes>
  );
};

export default AdminNavigation;
