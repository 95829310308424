import { Box, IconButton } from "@mui/material";
import { Eye } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Activesalesman = ({ row }) => {
  const navigate = useNavigate();
  return (
    <Box className="flex justify-start h-full items-center">
      <IconButton
        color="info"
        onClick={() => {
          navigate(`${row.id}`);
        }}
      >
        <Eye className="inline-block text-[#00B052]"></Eye>
      </IconButton>
    </Box>
  );
};

export default Activesalesman;
