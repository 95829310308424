import { createSlice } from "@reduxjs/toolkit";

export const regionSlice = createSlice({
  name: "region",
  initialState: {
    regionLoading: false,
    regionMessage: "",
    regionError: "",
    region: [],
    regionDeleteLoading: false,
    regionDeleteMessage: "",
    regionDeleteError: "",
    addRegionLoading: false,
    addRegionMessage: "",
    addRegionError: "",
    editRegionLoading: false,
    editRegionMessage: "",
    editRegionError: "",
  },
  reducers: {
    /*----------get region -------*/
    regionRequest: (state) => {
      state.regionLoading = true;
      state.regionMessage = "";
      state.regionError = "";
      state.region = [];
    },
    regionSuccess: (state, action) => {
      state.regionLoading = false;
      state.region = action.payload.response.region;
      state.regionMessage = action.payload.message;
    },
    regionError: (state, action) => {
      state.regionLoading = false;
      state.regionError = action.payload.message;
      state.regionMessage = "";
      state.region = [];
    },

    /*---------- delete -------*/

    regiondeleteRequest: (state) => {
      state.regionDeleteLoading = true;
      state.regionDeleteMessage = "";
      state.regionDeleteError = "";
    },
    regiondeleteSuccess: (state, action) => {
      let { region } = state;
      let tempArray = region?.filter(
        (regions) => regions.id !== action.payload.regionId
      );
      state.regionDeleteLoading = false;
      state.region = tempArray;
      state.regionDeleteMessage = action.payload.message;
    },
    regiondeleteError: (state, action) => {
      state.regionDeleteLoading = false;
      state.regionDeleteError = action.payload.message;
      state.regionDeleteMessage = "";
    },
    regiondeleteReset: (state) => {
      state.regionDeleteLoading = false;
      state.regionDeleteMessage = "";
      state.regionDeleteError = "";
    },

    /*----------add region -------*/

    addRegionRequest: (state) => {
      state.addRegionLoading = true;
      state.addRegionMessage = "";
      state.addRegionError = "";
    },
    addRegionSuccess: (state, action) => {
      state.addRegionLoading = false;
      state.addRegionMessage = action.payload.message;
    },
    addRegionError: (state, action) => {
      state.addRegionLoading = false;
      state.addRegionError = action.payload.message;
      state.addRegionMessage = "";
    },
    addRegionreset: (state) => {
      state.addRegionLoading = false;
      state.addRegionMessage = "";
      state.addRegionError = "";
    },

    /*----------Edit region -------*/

    editRegionRequest: (state) => {
      state.editRegionLoading = true;
      state.editRegionMessage = "";
      state.editRegionError = "";
    },
    editRegionSuccess: (state, action) => {
      state.editRegionLoading = false;
      state.editRegionMessage = action.payload.message;
    },
    editRegionError: (state, action) => {
      state.editRegionLoading = false;
      state.editRegionError = action.payload.message;
      state.editRegionMessage = "";
    },
    editRegionreset: (state) => {
      state.editRegionLoading = false;
      state.editRegionMessage = "";
      state.editRegionError = "";
    },
  },
});

export const {
  regionRequest,
  regionSuccess,
  regionError,
  regiondeleteReset,
  regiondeleteError,
  regiondeleteSuccess,
  regiondeleteRequest,
  addRegionreset,
  addRegionError,
  addRegionSuccess,
  addRegionRequest,
  editRegionreset,
  editRegionError,
  editRegionSuccess,
  editRegionRequest,
} = regionSlice.actions;

export default regionSlice.reducer;
