import countryService from "../services/Country";
import {
  countryActionCreateError,
  countryActionCreateRequest,
  countryActionCreateSuccess,
  countryActionDeleteError,
  countryActionDeleteRequest,
  countryActionDeleteSuccess,
  countryActionUpdateError,
  countryActionUpdateRequest,
  countryActionUpdateSuccess,
  countryError,
  countryRequest,
  countrySuccess,
  setDefaultCountryError,
  setDefaultCountryRequest,
  setDefaultCountrySuccess,
} from "../slices/Country";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- get country -------*/
export function getCountry(payload) {
  return (dispatch) => {
    dispatch(countryRequest());
    countryService
      .country(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(countrySuccess({ ...data, message }));
        } else {
          dispatch(countryError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(countryError(error));
      });
  };
}

/*---------- Delete country -------*/
export function CountryDelete(payload) {
  return (dispatch) => {
    dispatch(countryActionDeleteRequest());
    countryService
      .countryDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(countryActionDeleteSuccess({ id, message }));
        } else {
          dispatch(countryActionDeleteError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(countryActionDeleteError(error));
      });
  };
}

/*---------- Create country -------*/

export function CountryCreate(payload) {
  return (dispatch) => {
    dispatch(countryActionCreateRequest());
    countryService
      .countryCreate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(countryActionCreateSuccess({ id, message }));
        } else {
          dispatch(countryActionCreateError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(countryActionCreateError(error));
      });
  };
}

/*---------- Update country -------*/

export function CountryUpdate(payload) {
  return (dispatch) => {
    dispatch(countryActionUpdateRequest());
    countryService
      .countryUpdate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(countryActionUpdateSuccess({ id, message }));
        } else {
          dispatch(countryActionUpdateError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(countryActionUpdateError(error));
      });
  };
}

/*---------- set country -------*/
export function setCountry(payload) {
  return (dispatch) => {
    dispatch(setDefaultCountryRequest());
    countryService
      .setDefaultCountry(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(setDefaultCountrySuccess({ message, id: payload.id }));
        } else {
          dispatch(setDefaultCountryError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(setDefaultCountryError(error));
      });
  };
}
