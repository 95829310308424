import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginLoading: false,
    loginMessage: "",
    loginError: "",
    loggedInUser: null,
    forgotLoading: false,
    forgotMessage: "",
    forgotError: "",
  },
  reducers: {
    loginRequest: (state) => {
      state.loginLoading = true;
      state.loginMessage = "";
      state.loginError = "";
    },
    loginSuccess: (state, action) => {
      state.loginLoading = false;
      state.loggedInUser = action.payload;
      state.loginMessage = action.payload.message;
    },
    loginError: (state, action) => {
      state.loginLoading = false;
      state.loginMessage = "";
      state.loginError = action.payload.message;
    },

    loginReset: (state, action) => {
      state.loginLoading = false;
      state.loginMessage = "";
      state.loginError = "";
      state.loggedInUser = null;
    },
    // ---------------  forgot admin state --------------------
    forgotRequest: (state) => {
      state.forgotLoading = true;
      state.forgotMessage = "";
      state.forgotError = "";
    },
    forgotSuccess: (state, action) => {
      state.forgotLoading = false;
      state.forgotMessage = "Link send in your email";
      state.forgotError = "";
    },
    forgotError: (state, action) => {
      state.forgotLoading = false;
      state.forgotMessage = "";
      state.forgotError = action.payload.message;
    },
    forgotReset: (state, action) => {
      state.forgotLoading = false;
      state.forgotMessage = "";
      state.forgotError = "";
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginReset,
  loginError,
  forgotRequest,
  forgotSuccess,
  forgotError,
  forgotReset,
} = authSlice.actions;

export default authSlice.reducer;
