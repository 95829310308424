import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CountryCreate,
  CountryUpdate,
  getCountry,
} from "../../middlewares/Country";
import { CountryCode, Countryname } from "../../helpers/yup.validation.schema";
import { countryActionCreateReset } from "../../slices/Country";
import ToastService from "../../helpers/toast-services";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../component/common/BreadCrumb";

function AddCountry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdated, setIsUpdated] = useState(false);

  const countryUpdateId = state?.id || null;
  const countryUpdateCode = state?.code || null;
  const contryUpdateName = state?.name || null;

  useEffect(() => {
    if (!countryUpdateId) {
      dispatch(getCountry());
    }
    if (countryUpdateId) {
      setIsUpdated(true);
    }
  }, [dispatch, countryUpdateId]);

  const { countryActionCreateMessage, countryActionCreateError } = useSelector(
    (state) => state.country
  );

  useEffect(() => {
    if (countryActionCreateMessage) {
      ToastService.success(countryActionCreateMessage);
    }
    if (countryActionCreateError) {
      ToastService.error(countryActionCreateError);
    }

    dispatch(countryActionCreateReset());
  }, [countryActionCreateMessage, countryActionCreateError, dispatch]);

  const AddCountrySchema = Yup.object({
    Countryname: Countryname,
    CountryCode: CountryCode,
  });

  const handleSubmit = (values) => {
    const payload = {
      name: values.Countryname,
      code: `${values.CountryCode.toString()}`,
      is_default: 0,
      is_visible: 1,
    };
    if (isUpdated) {
      dispatch(CountryUpdate({ ...payload, id: countryUpdateId }));
      navigate("/admin/location/countries");
    } else {
      dispatch(CountryCreate(payload));
    }
  };

  const formik = useFormik({
    initialValues: {
      Countryname: isUpdated ? contryUpdateName : "",
      CountryCode: isUpdated ? countryUpdateCode.replace(/^\+/, "") : "",
    },
    validationSchema: AddCountrySchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <div className="country-page">
      <BreadCrumb
        title={isUpdated ? "Edit Country" : "Add Country"}
        pageTitle={"Dashboard"}
      />
      <ToastContainer />
      <div className="bg-white my-5 mt-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Country Name</label>
              <OutlinedInput
                placeholder="Enter Country Name"
                name="Countryname"
                id="Countryname"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Countryname}
              />
              {formik.errors.Countryname && formik.touched.Countryname && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.Countryname}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Country Code</label>
              <OutlinedInput
                placeholder="Enter Country Code"
                name="CountryCode"
                id="CountryCode"
                size="small"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.CountryCode}
                className="no-spin-buttons"
                onKeyDown={handleKeyDown}
              />
              {formik.errors.CountryCode && formik.touched.CountryCode && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.CountryCode}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box className="flex items-end justify-end gap-4 text-end ">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddCountry;
