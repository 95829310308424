import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSetting, updateSetting } from "../../middlewares/adminProfile";
import { email } from "../../helpers/yup.validation.schema";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../helpers/toast-services";
import BreadCrumb from "../../component/common/BreadCrumb";

const Setting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  const { setting, settingLoading, settingMessage, settingError } = useSelector(
    (state) => state.admin
  );
  const handleSubmit = (values) => {
    const payload = {
      app_name: values.name,
      support_email: values.email,
      app_version: values.version,
    };
    console.log(payload);
    dispatch(updateSetting(payload));
  };

  const settingvalid = Yup.object({
    email: email,
    name: Yup.string().required("App name is required"),
    version: Yup.string().required("App version is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: setting ? setting.support_email : "",
      name: setting ? setting.app_name : "",
      version: setting ? setting.app_version : "",
    },
    enableReinitialize: true,
    validationSchema: settingvalid,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (settingMessage) {
      ToastService.success(settingMessage);
      dispatch(getSetting());
    }
    if (settingError) {
      ToastService.error(settingError);
    }
    // dispatch(settingReset());
  }, [settingMessage, settingError, dispatch]);
  return (
    <div className="Setting-page">
      <BreadCrumb title="Setting" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white my-5 p-7 rounded-lg">
        {!settingLoading ? (
          <form
            className="space-y-4 md:space-y-6"
            onSubmit={formik.handleSubmit}
          >
            <Box>
              <h1 className="text-2xl font-bold mb-3">Company Info</h1>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box className="mb-5">
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        App Name
                      </label>
                      <OutlinedInput
                        placeholder="Enter App Name"
                        name="name"
                        id="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.name}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Support Email
                      </label>
                      <OutlinedInput
                        placeholder="Enter Support Email"
                        name="email"
                        id="email"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.email}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <h1 className="text-2xl font-bold mb-3">App Version</h1>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box className="mb-5">
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Android App Version
                      </label>
                      <OutlinedInput
                        placeholder="Enter Android App Version"
                        name="version"
                        id="version"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.version}
                      />
                      {formik.errors.version && formik.touched.version && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.version}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="text-end">
              <Button
                type="submit"
                className="text-white button-style capitalize"
                variant="contained"
              >
                Update
              </Button>
            </Box>
          </form>
        ) : (
          <Box className="text-center">
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Setting;
