import { Box, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { salesmanRestore } from "../../../middlewares/salesman";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { ArchiveRestore, Eye } from "lucide-react";

const Deletesalesman = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className="flex justify-start h-full items-center">
      <IconButton
        color="info"
        onClick={() => {
          navigate(`${row.id}`);
        }}
      >
        <Eye className="inline-block text-[#00B052]" />
      </IconButton>
      <IconButton
        onClick={() => {
          Swal.fire({
            title: "Are you sure restore merchandiser?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              title: "Swal-title-class",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(salesmanRestore({ salesmanId: row?.id }));
            }
          });
        }}
      >
        <ArchiveRestore className="inline-block text-red-500 fill-red-100" />
      </IconButton>
    </Box>
  );
};

export default Deletesalesman;
