import { Box, Button } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { bulkMerchant, bulkReq } from "../../middlewares/BulkUpload";
import { useDispatch } from "react-redux";

function BulkUpload({ title, type }) {
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const payload = {
      file: values.file,
    };
    if (type === "Merchant") {
      dispatch(bulkMerchant(payload));
    }
    if (type === "Request") {
      dispatch(bulkReq(payload));
    }
  };

  const bulkUpload = Yup.object({
    file: "",
  });

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: bulkUpload,
    onSubmit: handleSubmit,
  });
  const handleFileChange = (event) => {
    formik.setFieldValue("file", event.currentTarget.files[0]);
    formik.handleSubmit();
  };
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="file"
          name="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="bulkUploadFile"
          onChange={handleFileChange}
          hidden
        />
        <Button
          className="text-white button-style capitalize bulkuploadbtn"
          variant="contained"
        >
          <label htmlFor="bulkUploadFile">{title}</label>
        </Button>
      </form>
    </Box>
  );
}

export default BulkUpload;
