import regionService from "../services/region";
import {
  regionError,
  regionRequest,
  regionSuccess,
  regiondeleteError,
  regiondeleteRequest,
  regiondeleteSuccess,
  addRegionError,
  addRegionSuccess,
  addRegionRequest,
  editRegionRequest,
  editRegionError,
  editRegionSuccess,
} from "../slices/region";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- get region -------*/
export function getRegion(payload) {
  return (dispatch) => {
    dispatch(regionRequest());
    regionService
      .regionGet(payload)
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(regionSuccess({ response, message }));
        } else {
          dispatch(regionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(regionError(error));
      });
  };
}

// /*---------- delete region -------*/

export function regionDelete(payload) {
  return (dispatch) => {
    dispatch(regiondeleteRequest());

    regionService
      .regionDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(regiondeleteSuccess({ regionId: id, message }));
        } else {
          dispatch(regiondeleteError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(regiondeleteError(error));
      });
  };
}

/*---------- Create City -------*/

export function RegionCreate(payload) {
  return (dispatch) => {
    dispatch(addRegionRequest());
    regionService
      .regionCreate(payload)
      .then((data) => {
        const { message, status } = data;
        // let { id } = payload;
        if (status === 1) {
          dispatch(addRegionSuccess({ message }));
        } else {
          dispatch(addRegionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addRegionError(error));
      });
  };
}

/*---------- update region detail-------*/
export function updateRegion(payload) {
  return (dispatch) => {
    dispatch(editRegionRequest());
    regionService
      .regionEdit(payload)
      .then((data) => {
        console.log(data);
        const { message, status } = data;
        if (status === 1) {
          dispatch(editRegionSuccess({ message }));
        } else {
          dispatch(editRegionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(editRegionError(error));
      });
  };
}
