import React, { useEffect } from "react";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { changeLeftsidebarSizeType } from "../../../slices/layout";
import ProfileMenu from "../Profilemenu/ProfileMenu";

const Header = () => {
  const dispatch = useDispatch();

  const { layoutType, layoutSidebarSizeType } = useSelector(
    (state) => state.layout
  );

  const handleTopbarHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay");

    if (windowSize < 768) {
      document.body.classList.add("overflow-hidden");
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        document.querySelector(".app-menu").classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        document.querySelector(".app-menu").classList.add("hidden");
      }
      dispatch(changeLeftsidebarSizeType("lg"));
    } else {
      dispatch(
        changeLeftsidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm")
      );
    }
  };

  useEffect(() => {
    // resize
    const handleResizeLayout = () => {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize < 768) {
        dispatch(changeLeftsidebarSizeType("lg"));
      } else if (windowSize <= 1024) {
        if (layoutType === "vertical") {
          dispatch(changeLeftsidebarSizeType("sm"));
        } else {
          dispatch(changeLeftsidebarSizeType("lg"));
        }
      } else {
        dispatch(changeLeftsidebarSizeType("lg"));

        // dispatch(changeLeftsidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm"));
      }
    };

    // out side click
    const outerSideElement = () => {
      var windowSize = document.documentElement.clientWidth;
      var sidebarOverlay = document.getElementById("sidebar-overlay");
      if (sidebarOverlay) {
        sidebarOverlay.addEventListener("click", () => {
          if (!sidebarOverlay.classList.contains("hidden")) {
            if (windowSize <= 768) {
              document?.querySelector(".app-menu")?.classList.add("hidden");
              document.body.classList.remove("overflow-hidden");
              sidebarOverlay.classList.add("hidden");
            } else {
              dispatch(changeLeftsidebarSizeType("lg"));
            }
          }
        });
      }
    };

    // scroll
    const scrollNavigation = () => {
      var scrollUp = document.documentElement.scrollTop;
      if (scrollUp >= 50) {
        document.getElementById("page-topbar")?.classList.add("is-sticky");
      } else {
        document.getElementById("page-topbar")?.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", scrollNavigation, true);
    window.addEventListener("click", outerSideElement);
    window.addEventListener("resize", handleResizeLayout);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
      window.removeEventListener("click", outerSideElement);
      window.removeEventListener("resize", handleResizeLayout);
    };
  }, [dispatch, layoutType]);
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="md:left-vertical-menu 
                group-data-[sidebar-size=md]:md:left-vertical-menu-md 
                group-data-[sidebar-size=sm]:md:left-vertical-menu-sm 
                group-data-[layout=horizontal]:left-0 fixed right-0 z-[1000] left-0 print:hidden 
                group-data-[navbar=bordered]:m-4 
                group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 
                group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar 
                group-data-[layout=horizontal]:z-[1004]"
      >
        <div className="layout-width">
          <div className="flex items-center px-4 mx-auto bg-topbar border-b-2 border-topbar  group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md h-header shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none">
            <div className="flex items-center w-full group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl navbar-header group-data-[layout=horizontal]: xl:pr-3 ">
              <button
                onClick={handleTopbarHamburgerIcon}
                type="button"
                className="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear bg-topbar rounded-md btn hover:bg-slate-100 
                               
                                group-data-[topbar=brand]:bg-topbar-brand 
                                group-data-[topbar=brand]:border-topbar-brand 
                                group-data-[topbar=brand]:text-topbar-item-brand 
                                group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand 
                                group-data-[topbar=brand]:hover:text-topbar-item-hover-brand 
                                group-data-[layout=horizontal]:flex 
                                group-data-[layout=horizontal]:md:hidden
                                hamburger-icon"
                id="topnav-hamburger-icon"
              >
                <ChevronsLeft className="w-5 h-5 group-data-[sidebar-size=sm]:hidden" />
                <ChevronsRight className="hidden w-5 h-5 group-data-[sidebar-size=sm]:block" />
              </button>

              <div className="flex gap-3 ms-auto">
                <ProfileMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
