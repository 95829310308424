import { createSlice } from "@reduxjs/toolkit";

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countryLoading: false,
    countryMessage: "",
    countryError: "",
    country: [],
    countryActionLoading: false,
    countryActionMessage: "",
    countryActionError: "",
    countryActionCreateMessage: "",
    countryActionCreateError: "",
    countryActionUpdateMessage: "",
    countryActionUpdateError: "",
    setDefaultLoading: false,
    setDefaultMessage: "",
    setDefaultError: "",
  },
  reducers: {
    /*---------- Get-Country -------*/

    countryRequest: (state) => {
      state.countryLoading = true;
      state.countryMessage = "";
      state.countryError = "";
      state.country = [];
    },
    countrySuccess: (state, action) => {
      state.countryLoading = false;
      state.country = action.payload.response;
      state.countryMessage = action.payload.message;
    },
    countryError: (state, action) => {
      state.countryLoading = false;
      state.countryError = action.payload;
      state.countryMessage = "";
      state.country = [];
    },

    /*---------- Delete-Country -------*/

    countryActionDeleteRequest: (state) => {
      state.countryActionLoading = true;
      state.countryActionMessage = "";
      state.countryActionError = "";
    },
    countryActionDeleteSuccess: (state, action) => {
      let { country } = state;
      let tempArray = country?.filter(
        (countrys) => countrys.id !== action.payload.id
      );
      state.countryActionLoading = false;
      state.country = tempArray;
      state.countryActionMessage = action.payload.message;
    },
    countryActionDeleteError: (state, action) => {
      state.countryActionLoading = false;
      state.countryActionError = action.payload;
      state.countryActionMessage = "";
    },
    countryActionDeleteReset: (state) => {
      state.countryActionLoading = false;
      state.countryActionMessage = "";
      state.countryActionError = "";
    },

    /*---------- Create-Country -------*/

    countryActionCreateRequest: (state) => {
      state.countryActionLoading = true;
      state.countryActionMessage = "";
      state.countryActionCreateMessage = "";
    },
    countryActionCreateSuccess: (state, action) => {
      state.countryActionLoading = false;
      state.country.push(action.payload.country);
      state.countryActionCreateMessage = action.payload.message;
    },
    countryActionCreateError: (state, action) => {
      state.countryActionLoading = false;
      state.countryActionCreateError = action.payload.message;
      state.countryActionCreateMessage = "";
    },
    countryActionCreateReset: (state) => {
      state.countryActionLoading = false;
      state.countryActionMessage = "";
      state.countryActionCreateMessage = "";
    },

    /*---------- Update-Country -------*/

    countryActionUpdateRequest: (state) => {
      state.countryActionLoading = true;
      state.countryActionMessage = "";
      state.countryActionError = "";
    },
    countryActionUpdateSuccess: (state, action) => {
      state.countryActionLoading = false;
      const index = state.country.findIndex(
        (countrys) => countrys.id === action.payload.country.id
      );
      if (index !== -1) {
        state.country[index] = action.payload.country;
      }
      state.countryActionMessage = action.payload.message;
    },
    countryActionUpdateError: (state, action) => {
      state.countryActionLoading = false;
      state.countryActionUpdateError = action.payload;
      state.countryActionMessage = "";
    },
    countryActionUpdateReset: (state) => {
      state.countryActionLoading = false;
      state.countryActionMessage = "";
      state.countryActionError = "";
    },

    /*---------- set-default-Country -------*/

    setDefaultCountryRequest: (state) => {
      state.setDefaultLoading = true;
      state.setDefaultMessage = "";
      state.setDefaultError = "";
    },
    setDefaultCountrySuccess: (state, action) => {
      let {
        payload: { id },
      } = action;
      let { country } = state;
      state.setDefaultLoading = false;
      state.country = country.map((item) => {
        if (item.id === id) {
          return { ...item, is_default: item?.is_default === 0 ? 1 : 0 };
        }
        return { ...item, is_default: 0 };
      });
      state.setDefaultMessage = action.payload.message;
    },
    setDefaultCountryError: (state, action) => {
      state.setDefaultLoading = false;
      state.setDefaultError = action.payload.message;
      state.setDefaultMessage = "";
    },
  },
});

export const {
  countryRequest,
  countrySuccess,
  countryError,
  countryActionDeleteRequest,
  countryActionDeleteSuccess,
  countryActionDeleteError,
  countryActionDeleteReset,
  countryActionCreateRequest,
  countryActionCreateSuccess,
  countryActionCreateError,
  countryActionCreateReset,
  countryActionUpdateRequest,
  countryActionUpdateSuccess,
  countryActionUpdateError,
  countryActionUpdateReset,
  setDefaultCountryError,
  setDefaultCountryRequest,
  setDefaultCountrySuccess,
} = countrySlice.actions;

export default countrySlice.reducer;
