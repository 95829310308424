import LocalstorageService from "../helpers/localstorage-services";
import AuthService from "../services/auth";
import {
  loginError,
  loginRequest,
  loginSuccess,
  forgotRequest,
  forgotSuccess,
  forgotError,
} from "../slices/auth";

export function LoginUser(payload) {
  return (dispatch) => {
    dispatch(loginRequest());

    AuthService.login(payload)
      .then((data) => {
        const { response, message, status } = data;
        if (status === 1) {
          const localStorage = { user: response };
          LocalstorageService.loginUser(localStorage);
          dispatch(loginSuccess({ ...data, message }));
        } else {
          dispatch(loginError({ message }));
        }
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
}

/*---------- forgot -------*/
export function forgotAdmin(payload) {
  return (dispatch) => {
    dispatch(forgotRequest());
    AuthService.forgot(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(forgotSuccess({ message }));
        } else {
          dispatch(forgotError({ message }));
        }
      })
      .catch((error) => {
        dispatch(forgotError(error));
      });
  };
}
