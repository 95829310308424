import merchantService from "../services/merchant";
import {
  merchantRequest,
  merchantSuccess,
  merchantError,
  merchantActionRequest,
  merchantActionSuccess,
  merchantActionError,
  addEditMerchantError,
  addEditMerchantSuccess,
  addEditMerchantRequest,
  getMerchantDetailSuccess,
  getMerchantDetailError,
  getMerchantDetailReq,
  merchantOptionsSuccess,
  merchantOptionsRequest,
  merchantOptionsError,
} from "../slices/merchant";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- get merchant -------*/
export function getMerchant(payload) {
  return (dispatch) => {
    dispatch(merchantRequest());
    merchantService
      .merchantGet(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(merchantSuccess({ ...data, message }));
        } else {
          dispatch(merchantError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(merchantError(error));
      });
  };
}
/*---------- get merchant -------*/
export function getMerchantForSwap(payload) {
  return (dispatch) => {
    dispatch(merchantOptionsRequest());
    merchantService
      .merchantGet(payload)
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(merchantOptionsSuccess({ response, message }));
        } else {
          dispatch(merchantOptionsError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(merchantOptionsError(error));
      });
  };
}

/*---------- active-deactive salesman -------*/

export function merchantStatus(payload) {
  return (dispatch) => {
    dispatch(merchantActionRequest());
    merchantService
      .merchantStatus(payload)
      .then((data) => {
        const { message, status } = data;
        let { merchantId } = payload;
        if (status === 1) {
          dispatch(
            merchantActionSuccess({
              merchantId,
              message,
            })
          );
        } else {
          dispatch(merchantActionError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(merchantActionError(error));
      });
  };
}
/*---------- create marchant -------*/

export function merchantCreate(payload) {
  return (dispatch) => {
    dispatch(addEditMerchantRequest());
    merchantService
      .merchantcreate(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(addEditMerchantSuccess({ message }));
        } else {
          dispatch(addEditMerchantError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addEditMerchantError(error));
      });
  };
}

// /*---------- delete marchant -------*/

export function merchantDelete(payload) {
  return (dispatch) => {
    dispatch(merchantActionRequest());
    merchantService
      .merchantDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { merchantId } = payload;
        if (status === 1) {
          dispatch(
            merchantActionSuccess({
              merchantId,
              message,
            })
          );
        } else {
          dispatch(merchantActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(merchantActionError(error));
      });
  };
}

// /*---------- restore salesman -------*/

export function merchantRestore(payload) {
  return (dispatch) => {
    dispatch(merchantActionRequest());
    merchantService
      .merchantRestore(payload)
      .then((data) => {
        const { message, status } = data;
        let { merchantId } = payload;
        if (status === 1) {
          dispatch(
            merchantActionSuccess({
              merchantId,
              message,
            })
          );
        } else {
          dispatch(merchantActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(merchantActionError(error));
      });
  };
}

/*---------- get Merchant detail-------*/
export function getMerchantDetail(payload) {
  return (dispatch) => {
    dispatch(getMerchantDetailReq());
    merchantService
      .getMarchentDetail(payload)
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(
            getMerchantDetailSuccess({
              response,
            })
          );
        } else {
          dispatch(getMerchantDetailError({ message }));
        }
      })
      .catch((error) => {
        dispatch(getMerchantDetailError(error));
      });
  };
}

/*---------- update Merchant detail-------*/
export function updateMerchantDetail(payload) {
  return (dispatch) => {
    dispatch(getMerchantDetailReq());
    merchantService
      .updateMarchentDetail(payload)
      .then((data) => {
        console.log(data);
        const {
          message,
          status,
          response: { _updateUser },
        } = data;
        if (status === 1) {
          dispatch(
            getMerchantDetailSuccess({
              response: _updateUser,
              message,
            })
          );
        } else {
          dispatch(getMerchantDetailError({ message }));
        }
      })
      .catch((error) => {
        dispatch(getMerchantDetailError(error));
      });
  };
}
