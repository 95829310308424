import AdminService from "../services/adminProfile";
import {
  adminRequest,
  adminSuccess,
  adminError,
  changePassAdminRequest,
  changePassAdminSuccess,
  changePassAdminError,
  updateAdminRequest,
  updateAdminSuccess,
  updateAdminError,
  logoutError,
  logoutRequest,
  logoutSuccess,
  getAdminDashRequest,
  getAdminDashSuccess,
  getAdminDashError,
  settingError,
  settingRequest,
  settingSuccess,
} from "../slices/adminProfile";
import { handleAuthError } from "../utlils/authErrorHandler";
import LocalstorageService from "./../helpers/localstorage-services";

/*------------- Get User -------------*/

export function getSingleAdmin() {
  return (dispatch) => {
    dispatch(adminRequest());

    AdminService.getAdmin()
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(adminSuccess({ ...data, message }));
        } else {
          dispatch(adminError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(adminError(error));
      });
  };
}

/*------------- Get admin dashboard -------------*/

export function getAdminDash() {
  return (dispatch) => {
    dispatch(getAdminDashRequest());
    AdminService.getAdminDashboard()
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(getAdminDashSuccess({ response, message }));
        } else {
          dispatch(getAdminDashError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(getAdminDashError(error));
      });
  };
}

/*------------- Change password -------------*/

export function changePassword(payload) {
  return (dispatch) => {
    dispatch(changePassAdminRequest());
    AdminService.changePass(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(changePassAdminSuccess({ ...data, message }));
        } else {
          dispatch(changePassAdminError({ ...data, message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(changePassAdminError(error));
      });
  };
}

/*------------- Update admin -------------*/

export function updateAdmin(payload) {
  return (dispatch) => {
    dispatch(updateAdminRequest());
    AdminService.UpdateAdmin(payload)
      .then((data) => {
        console.log(data);
        const { message, status } = data;
        if (status === 1) {
          dispatch(updateAdminSuccess({ ...data, message }));
        } else {
          dispatch(updateAdminError({ ...data, message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(updateAdminError(error));
      });
  };
}

/*------------- Logout admin -------------*/

export function logoutUser() {
  return (dispatch) => {
    dispatch(logoutRequest());
    AdminService.logout()
      .then((response) => {
        const { message, status } = response;
        if (status === 1) {
          LocalstorageService.removeFromLocalStorage("user-details");
          dispatch(logoutSuccess({ message }));
        } else {
          dispatch(logoutError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(logoutError(error));
      });
  };
}

/*------------- Get setting -------------*/

export function getSetting() {
  return (dispatch) => {
    dispatch(settingRequest());
    AdminService.setting()
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(settingSuccess({ response }));
        } else {
          dispatch(settingError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(settingError(error));
      });
  };
}

/*------------- update setting -------------*/

export function updateSetting(payload) {
  return (dispatch) => {
    AdminService.updateSetting(payload)
      .then((data) => {
        const { message, response, status } = data;
        if (status === 1) {
          dispatch(settingSuccess({ response, message }));
        } else {
          dispatch(settingError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(settingError(error));
      });
  };
}
