import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  OutlinedInput,
} from "@mui/material";
import * as Yup from "yup";
import BlankSvg from "../../../assets/Images/blank.png";
import {
  getSalesmanDetail,
  updateSalesmanDetail,
} from "../../../middlewares/salesman";
import { useFormik } from "formik";
import { name, selectRegion } from "../../../helpers/yup.validation.schema";
import { Edit } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { getRegion } from "../../../middlewares/region";
import ModelSalesman from "../../../component/tableActionbar/salesman/modelSalesman";
import { addRequestReset } from "../../../slices/request";

function EditSalesman() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [regionChange, setRegionChange] = useState(false);
  const [modalId, setModalId] = useState("");
  const { addRequestMessage } = useSelector((state) => state.request);

  const {
    salesmanDetail,
    getSalesmanDetailLoading,
    getSalesmanDetailMessage,
    getSalesmanDetailError,
  } = useSelector((state) => state.salesman);
  const [imgSrc, setImgSrc] = useState(BlankSvg);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (getSalesmanDetailMessage) {
      navigate("/admin/merchandiser/active");
    }
    if (getSalesmanDetailError) {
      ToastService.error(getSalesmanDetailError);
    }
  }, [getSalesmanDetailMessage, getSalesmanDetailError, dispatch, navigate]);

  useEffect(() => {
    if (id) {
      dispatch(
        getSalesmanDetail({
          salesmanId: id,
        })
      );
      dispatch(getRegion());
    }
  }, [dispatch, id]);

  const { region } = useSelector((state) => state.region);

  const EditSalesman = Yup.object({
    fullName: name,
    regionId: selectRegion,
  });

  const handleSubmit = (values) => {
    if (salesmanDetail?.pendingRequestCount && regionChange) {
      setOpen(true);
      setModalId(salesmanDetail?.id);
    } else {
      dispatch(
        updateSalesmanDetail({ salesmanId: salesmanDetail?.id, ...values })
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImgSrc(reader.result);
          setErrorMessage("");
          formik.setFieldValue("file", file);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("file", null);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: salesmanDetail?.fullName,
      file: null,
      regionId: salesmanDetail ? salesmanDetail.regionId : "",
    },
    enableReinitialize: true,
    validationSchema: EditSalesman,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (salesmanDetail) {
      setImgSrc(
        salesmanDetail?.profileImage
          ? `${process.env.REACT_APP_AWS_URL}/${salesmanDetail?.profileImage}`
          : BlankSvg
      );
    }
  }, [salesmanDetail]);

  useEffect(() => {
    if (addRequestMessage) {
      let { values } = formik;
      dispatch(
        updateSalesmanDetail({ salesmanId: salesmanDetail?.id, ...values })
      );
      dispatch(addRequestReset());
    }
  }, [addRequestMessage, dispatch, formik, salesmanDetail]);

  return (
    <div className="requestdetail-page">
      <ModelSalesman
        open={open}
        handleClose={() => setOpen(false)}
        id={modalId}
        forEdit={true}
      />
      <BreadCrumb title="Edit Merchandiser" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white my-5 p-5 rounded-lg">
        {!getSalesmanDetailLoading ? (
          <Grid
            container
            spacing={2}
            style={{ marginTop: "0px", padding: "20px" }}
          >
            <Grid sm={12} md={12} style={{ marginBottom: "20px" }}>
              <form
                className="space-y-4 md:space-y-6"
                onSubmit={formik.handleSubmit}
              >
                <Box className="">
                  <div>
                    <label className="font-medium">Profile Image</label>
                    <br />
                    <div className="flex flex-col mt-2 justify-center items-start gap-x-2.5">
                      <div className="relative">
                        <div className="w-24 h-24 overflow-hidden rounded-[50%]">
                          <img
                            src={imgSrc}
                            alt="flag"
                            className="w-full h-full object-cover"
                          />
                        </div>

                        <div className="absolute bottom-0 right-0 h-[32px] w-[32px] rounded-[50%] bg-[#E5FFF1] flex items-center justify-center">
                          <input
                            type="file"
                            name="file"
                            accept="image/png, image/jpeg,image/jpg"
                            id="editimg"
                            onChange={(e) => {
                              handleFileChange(e); // Call your custom function
                            }}
                            hidden
                          />
                          <label htmlFor="editimg" className="cursor-pointer ">
                            <Edit className="text-[#00B052]" />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="text-red-500 text-sm mt-1">
                        {errorMessage}
                      </div>
                    )}
                    {formik.errors.file && formik.touched.file && (
                      <Box className="text-red-500 text-sm mt-1">
                        {formik.errors.file}
                      </Box>
                    )}
                  </div>
                </Box>
                <Box>
                  <FormControl className="w-full" variant="outlined">
                    <label htmlFor="fullName">Full Name</label>
                    <OutlinedInput
                      placeholder="Enter Full Name"
                      name="fullName"
                      id="fullName"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                    />
                    {formik.errors.fullName && formik.touched.fullName && (
                      <Box className="text-red-500 text-sm mt-1">
                        {formik.errors.fullName}
                      </Box>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl className="w-full" variant="outlined">
                    <label htmlFor="regionId">Region</label>
                    <select
                      name="regionId"
                      id="regionId"
                      onChange={(e) => {
                        setRegionChange(true);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.regionId ? formik.values.regionId : ""
                      }
                      className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
                    >
                      <option value="" hidden>
                        Select Region
                      </option>
                      {region?.map((reg) => (
                        <option key={reg.id} value={reg.id}>
                          {reg.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.regionId && formik.touched.regionId && (
                      <Box className="text-red-500 text-sm mt-1">
                        {formik.errors.regionId}
                      </Box>
                    )}
                  </FormControl>
                </Box>
                <Box className="flex items-end justify-end gap-4 text-end">
                  <Button
                    type="submit"
                    className="text-white button-style capitalize"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        ) : (
          <Box className="flex flex-row items-center justify-center py-10">
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
}

export default EditSalesman;
