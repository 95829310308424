import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/region";

/*---------- get region -------*/

const regionGet = async (payload) => {
  const URL = `${URI}/get`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get region:", error);
    throw error;
  }
};

//   /*---------- delete marchant-------*/

const regionDelete = async (payload) => {
  const URL = `${URI}/delete`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete region :", error);
    throw error;
  }
};

/*---------- Create region -------*/
const regionCreate = async (payload) => {
  const URL = `${URI}/create`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create region:", error);
    throw error;
  }
};

/*---------- Create region -------*/
const regionEdit = async (payload) => {
  const URL = `${URI}/update`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred edit region:", error);
    throw error;
  }
};

const regionService = {
  regionGet,
  regionDelete,
  regionCreate,
  regionEdit,
};

export default regionService;
