import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalesman } from "../../../middlewares/salesman";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import { salesmanActionReset } from "../../../slices/salesman";
import BreadCrumb from "../../../component/common/BreadCrumb";
import TableContainer from "../../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../../component/common/CustomLoader";
import { Box, Typography } from "@mui/material";
import blankSalesman from "../../../assets/Images/blank.png";
import PendingSalesmanActionBar from "../../../component/tableActionbar/salesman/pendingSalesman";

function PendingSalesman() {
  const dispatch = useDispatch();
  const [salesmanToDisplay, setSalesmanToDisplay] = useState([]);
  const {
    salesman,
    salesmanLoading,
    salesmanActionMessage,
    salesmanActionError,
  } = useSelector((state) => state.salesman);

  useEffect(() => {
    dispatch(
      getSalesman({
        type: "pending",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setSalesmanToDisplay(salesman);
  }, [salesman]);

  useEffect(() => {
    if (salesmanActionMessage) {
      ToastService.success(salesmanActionMessage);
    }
    if (salesmanActionError) {
      ToastService.error(salesmanActionError);
    }
    dispatch(salesmanActionReset());
  }, [salesmanActionMessage, salesmanActionError, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Image",
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover "
              src={
                row.original.profileImage
                  ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${row.original.profileImage}`
                  : blankSalesman
              }
              alt={row.original.fullName}
            />
          </Box>
        ),
      },
      {
        header: "Name",
        enableColumnFilter: false,
        accessorKey: "fullName",
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Region",
        accessorKey: "region.name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Phone Number",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">
              {row.original.countryCode} {row.original.phoneNo}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <PendingSalesmanActionBar row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="pendingSalesman-page">
      <BreadCrumb title="Pending Merchandiser" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!salesmanLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={salesmanToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search Merchandiser"
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default PendingSalesman;
