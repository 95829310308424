import provinceService from "../services/Province";

import {
  provinceActionCreateError,
  provinceActionCreateRequest,
  provinceActionCreateSuccess,
  provinceActionDeleteError,
  provinceActionDeleteRequest,
  provinceActionDeleteSuccess,
  provinceActionUpdateError,
  provinceActionUpdateRequest,
  provinceActionUpdateSuccess,
  provinceError,
  provinceRequest,
  provinceSuccess,
} from "../slices/Province";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- Get Province -------*/
export function getProvince(payload) {
  return (dispatch) => {
    dispatch(provinceRequest());
    provinceService
      .province(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(provinceSuccess({ ...data, message }));
        } else {
          dispatch(provinceError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(provinceError(error));
      });
  };
}

/*---------- Delete Province -------*/
export function ProvinceDelete(payload) {
  return (dispatch) => {
    dispatch(provinceActionDeleteRequest());
    provinceService
      .provinceDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(provinceActionDeleteSuccess({ id, message }));
        } else {
          dispatch(provinceActionDeleteError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(provinceActionDeleteError(error));
      });
  };
}

/*---------- Create Province -------*/

export function ProvinceCreate(payload) {
  return (dispatch) => {
    dispatch(provinceActionCreateRequest());
    provinceService
      .provinceCreate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        console.log(id, "middded", payload);
        if (status === 1) {
          dispatch(provinceActionCreateSuccess({ id, message }));
        } else {
          dispatch(provinceActionCreateError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(provinceActionCreateError(error));
      });
  };
}

/*---------- Update Province -------*/

export function ProvinceUpdate(payload) {
  return (dispatch) => {
    dispatch(provinceActionUpdateRequest());
    provinceService
      .provinceUpdate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        console.log(id, "middded", payload);
        if (status === 1) {
          dispatch(provinceActionUpdateSuccess({ id, message }));
        } else {
          dispatch(provinceActionUpdateError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(provinceActionUpdateError(error));
      });
  };
}
