import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getMerchant } from "../../../middlewares/merchant";
import ActiveMarchantActionBar from "../../../component/tableActionbar/marchant/activeMerchant";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import {
  addEditMerchantreset,
  bulkReset,
  getMerchantDetailReset,
  merchantActionReset,
} from "../../../slices/merchant";
import blankSalesman from "../../../assets/Images/blank.png";
import BulkUpload from "../../../component/common/BulkUpload";
import { Box, Button, Typography } from "@mui/material";
import BreadCrumb from "../../../component/common/BreadCrumb";
import TableContainer from "../../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../../component/common/CustomLoader";
import Select from "react-select";
import { getRegion } from "../../../middlewares/region";

function ActiveMerchant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [merchantToDisplay, setMerchantToDisplay] = useState([]);
  const {
    merchant,
    merchantLoading,
    merchantActionMessage,
    merchantActionError,
    addEditMerchantMessage,
    getMerchantDetailMessage,
    bulkUploadError,
    bulkUploadMessage,
  } = useSelector((state) => state.merchant);

  const { region } = useSelector((state) => state.region);

  useEffect(() => {
    dispatch(
      getMerchant({
        type: "active",
      })
    );
    dispatch(getRegion());
  }, [dispatch]);

  useEffect(() => {
    setMerchantToDisplay(merchant);
  }, [merchant]);

  useEffect(() => {
    if (merchantActionMessage) {
      ToastService.success(merchantActionMessage);
    }
    if (merchantActionError) {
      ToastService.error(merchantActionError);
    }
    if (bulkUploadMessage) {
      ToastService.success(bulkUploadMessage);
      dispatch(bulkReset());
    }
    if (bulkUploadError) {
      ToastService.error(bulkUploadError);
      dispatch(bulkReset());
    }
    if (addEditMerchantMessage) {
      ToastService.success(addEditMerchantMessage);
      dispatch(addEditMerchantreset());
    }
    if (getMerchantDetailMessage) {
      ToastService.success(getMerchantDetailMessage);
      dispatch(getMerchantDetailReset());
    }
    dispatch(merchantActionReset());
  }, [
    merchantActionMessage,
    merchantActionError,
    addEditMerchantMessage,
    getMerchantDetailMessage,
    bulkUploadMessage,
    bulkUploadError,
    dispatch,
  ]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Image",
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover "
              src={
                row.original.profileImage
                  ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${row.original.profileImage}`
                  : blankSalesman
              }
              alt={row.original.fullName}
            />
          </Box>
        ),
      },
      {
        header: "Name",
        enableColumnFilter: false,
        accessorKey: "fullName",
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Region",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          let selectedRegion = region.find(
            (reg) => reg.id === row.original.regionId
          );
          return (
            <Box className="flex items-center">
              <Typography variant="p">
                {row.original.region?.name
                  ? row.original.region?.name
                  : selectedRegion?.name}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: "Phone Number",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">
              {row.original.countryCode} {row.original.phoneNo}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <ActiveMarchantActionBar row={row.original} />
          </Typography>
        ),
      },
    ],
    [region]
  );

  const RegionOptions =
    region?.map((mer) => ({
      label: mer.name,
      value: mer.id,
    })) || [];

  // Handle region filter
  const [selectedRegion, setSelectedRegion] = useState(null);
  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption?.value || null);
    dispatch(
      getMerchant({
        type: "active",
        regionId: selectedOption?.value,
      })
    );
  };

  return (
    <div className="merchant-page">
      <BreadCrumb title="Active Merchant" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!merchantLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={merchantToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search Merchant"
              extraSearch={
                <Select
                  className="select-css custom-select-container"
                  classNamePrefix="react-select"
                  data-choices
                  isClearable
                  name="region"
                  placeholder="Select a Region"
                  options={RegionOptions}
                  onChange={handleRegionChange}
                  value={RegionOptions.find(
                    (option) => option.value === selectedRegion
                  )}
                />
              }
              extraButton={
                <div className="flex">
                  <Button
                    className="text-white button-style capitalize"
                    variant="contained"
                    sx={{ marginLeft: "10px !important" }}
                    onClick={() => navigate(`/admin/merchant/add`)}
                  >
                    Add Merchant
                  </Button>
                  <BulkUpload title="Bulk Upload" type="Merchant" />
                  <a
                    href={require("../../../assets/Excel/Merchant.xlsx")}
                    download
                  >
                    <Button
                      className="text-white button-style capitalize"
                      variant="contained"
                    >
                      Download
                    </Button>
                  </a>
                </div>
              }
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default ActiveMerchant;
