import React, { useEffect } from "react";
import BlankSvg from "../../../assets/Images/blank.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../../middlewares/Country";
import { getCity } from "../../../middlewares/City";
import { getProvince } from "../../../middlewares/Province";
import { merchantCreate } from "../../../middlewares/merchant";
import {
  lat,
  long,
  name,
  phone,
  selectRegion,
} from "../../../helpers/yup.validation.schema";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import { useNavigate } from "react-router-dom";
import { addEditMerchantreset } from "../../../slices/merchant";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { Pencil } from "lucide-react";
import { getRegion } from "../../../middlewares/region";

function AddMerchant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addEditMerchantMessage, addEditMerchantError } = useSelector(
    (state) => state.merchant
  );

  const [imageSrc, setImageSrc] = useState(BlankSvg);
  const [cityArray, setCityArray] = useState([]);
  const [provinceArray, setProvinceArray] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    dispatch(getCountry());
    dispatch(getProvince());
    dispatch(getCity());
    dispatch(getRegion());
  }, [dispatch]);

  // const [regionToDisplay, setRegionToDisplay] = useState([]);
  const { region } = useSelector((state) => state.region);
  const { country } = useSelector((state) => state.country);
  const { city } = useSelector((state) => state.city);
  const { province } = useSelector((state) => state.province);
  const defaultCountry = country?.find((cntry) => cntry.is_default === 1);
  useEffect(() => {
    let tempArray = province?.filter(
      (prov) => prov?.country_id === defaultCountry?.id
    );
    setProvinceArray(tempArray);
    setCountryCode(defaultCountry ? defaultCountry.code : "");
  }, [defaultCountry, province]);

  //add  by deep for lat long
  const [latlong, setLatlong] = useState({ latitude: null, longitude: null });
  useEffect(() => {
    if ("geolocation" in navigator) {
      // });
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude.toFixed(2);
        const longitude = position.coords.longitude.toFixed(2);
        setLatlong({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        });
      });
    }
  }, []);
  //===============

  const handleSubmit = (values) => {
    const payload = {
      fullName: values.name,
      regionId: values.region,
    };

    if (values.email) payload.email = values.email;
    if (countryCode) payload.countryCode = countryCode;
    if (values.phone) payload.phoneNo = values.phone;
    if (values.locationLat) payload.locationLAT = values.locationLat;
    if (values.locationLong) payload.locationLONG = values.locationLong;
    if (values.address) payload.addressLine = values.address;
    if (values.city) payload.cityId = values.city;
    if (values.province) payload.provinceId = values.province;
    if (values.country) payload.countryId = values.country;
    if (values.file) payload.file = values.file;

    dispatch(merchantCreate(payload));
  };

  const AddMerchant = Yup.object({
    file: "",
    name: name,
    email: "",
    phone: phone,
    locationLong: long,
    locationLat: lat,
    address: "",
    city: "",
    country: "",
    province: "",
    region: selectRegion,
  });

  const formik = useFormik({
    initialValues: {
      file: "",
      name: "",
      email: "",
      phone: "",
      locationLong: latlong ? latlong.longitude : "",
      locationLat: latlong ? latlong.latitude : "",
      address: "",
      city: "",
      country: defaultCountry ? defaultCountry.id : "",
      province: "",
      region: "",
    },
    enableReinitialize: true,
    validationSchema: AddMerchant,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (addEditMerchantMessage) {
      navigate("/admin/merchant/active");
    }
    if (addEditMerchantError) {
      ToastService.error(addEditMerchantError);
      dispatch(addEditMerchantreset());
    }
  }, [addEditMerchantMessage, addEditMerchantError, dispatch, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("file", e.target.files[0]);
          setErrorMessage("");
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("file", "");
      }
    }
  };

  const handleChangeCountry = (e) => {
    let tempArray = province?.filter(
      (prov) => prov.country_id === e.target.value
    );
    let selectedCountry = country.find(
      (country) => country.id === e.target.value
    );
    let cntryCode = selectedCountry ? selectedCountry.code : null;
    setCountryCode(cntryCode);
    setProvinceArray(tempArray);
    setCityArray([]);
  };

  const handleProvinceCountry = (e) => {
    let tempArray = city.filter((prov) => prov.province_id === e.target.value);
    setCityArray(tempArray);
  };

  return (
    <div className="merchant-page">
      <BreadCrumb title="Add Merchant" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white m-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box className="">
            <div>
              <label className="font-medium">Profile Image</label>
              <br />
              <div className="flex flex-col mt-2 justify-center items-start gap-x-2.5">
                <div className="relative">
                  <div className="w-24 h-24 overflow-hidden rounded-[50%]">
                    <img
                      src={imageSrc}
                      alt="flag"
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="absolute bottom-0 right-0 h-[32px] w-[32px] rounded-[50%] bg-[#E5FFF1] flex items-center justify-center">
                    <input
                      type="file"
                      name="file"
                      accept="image/png, image/jpeg,image/jpg"
                      id="editimg"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      hidden
                    />
                    <label htmlFor="editimg" className="cursor-pointer ">
                      <Pencil className="inline-block text-16 text-[#2e7d32]"></Pencil>
                    </label>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
              )}
            </div>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Name</label>
              <OutlinedInput
                placeholder="Enter Name"
                name="name"
                id="name"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Email</label>
              <OutlinedInput
                placeholder="Enter Email"
                name="email"
                id="email"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Phone Number</label>
              <div className="relative">
                <span className="absolute top-[10%] px-4 py-[10px] z-20 border-r-[2px] border-solid">
                  {countryCode}
                </span>
                <OutlinedInput
                  placeholder="Enter Phone Number"
                  name="phone"
                  id="phone"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className="w-full pl-16"
                ></OutlinedInput>
              </div>
              {formik.errors.phone && formik.touched.phone && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.phone}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Latitude</label>
              <OutlinedInput
                placeholder="Enter location latitude"
                name="locationLat"
                id="locationLat"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={latlong ? latlong.latitude : formik.value.locationLAT}
                value={formik.values.locationLat}
              />
              {formik.errors.locationLat && formik.touched.locationLat && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.locationLat}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Longitude</label>
              <OutlinedInput
                placeholder="Enter location longitude"
                name="locationLong"
                id="locationLong"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={latlong ? latlong.longitude : formik.value.locationLONG}
                value={formik.values.locationLong}
              />
              {formik.errors.locationLong && formik.touched.locationLong && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.locationLong}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Address</label>
              <OutlinedInput
                placeholder="Enter Address"
                name="address"
                id="address"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
              {formik.errors.address && formik.touched.address && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.address}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label htmlFor="language" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Region
              </label>
              <select
                name="region"
                id="region"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.region}
                className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option value="" hidden>
                  Select Region
                </option>
                {region?.map((reg) => (
                  <option key={reg.id} value={reg.id}>
                    {reg.name}
                  </option>
                ))}
              </select>
              {formik.errors.region && formik.touched.region && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.region}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label htmlFor="language" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Country
              </label>
              <select
                name="country"
                id="country"
                onChange={(e) => {
                  handleChangeCountry(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option value="" hidden>
                  Select Country
                </option>
                {country?.map((cntry) => (
                  <option key={cntry.id} value={cntry.id}>
                    {cntry.name}
                  </option>
                ))}
              </select>
              {formik.errors.country && formik.touched.country && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.country}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Province</label>
              <select
                name="province"
                id="province"
                onChange={(e) => {
                  handleProvinceCountry(e);
                  formik.handleChange(e);
                }}
                disabled={provinceArray.length === 0}
                onBlur={formik.handleBlur}
                value={formik.values.province}
                className="select-style font-medium p-4  mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option selected hidden>
                  Select Province
                </option>
                {provinceArray?.map((province) => (
                  <option key={province.id} value={province.id}>
                    {province.name}
                  </option>
                ))}
              </select>
              {formik.errors.province && formik.touched.province && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.province}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>City</label>
              <select
                name="city"
                id="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                disabled={cityArray.length === 0}
                // value={`${formik?.values?.languageId}`}
                className="select-style font-medium p-4  mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                {/* <option selected={!formik?.values?.languageId} hidden> */}
                <option selected hidden>
                  Select City
                </option>
                {cityArray?.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              {formik.errors.city && formik.touched.city && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.city}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box className="flex items-end justify-end gap-4 text-end">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddMerchant;
