import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import {
  confirmPassword,
  newPassword,
} from "../../helpers/yup.validation.schema";
import Logo from "../../assets/Images/Logo.svg";
import { Eye, EyeOff } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAdmin } from "../../middlewares/resetPassApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../helpers/toast-services";
import { resetpassreset } from "../../slices/resetPassApp";

function ForgotPassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetpassMessage, resetpassError } = useSelector(
    (state) => state.resetpass
  );
  // ---------------------- Password hide show --------------------------------

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSubmit = (values) => {
    const payload = {
      password: values.password,
      id: id,
    };
    dispatch(resetPasswordAdmin(payload));
  };

  const resetSchema = Yup.object({
    password: newPassword,
    confirmPassword: confirmPassword,
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (resetpassMessage) {
      navigate("/resetpassword/successfully");
    }
    if (resetpassError) {
      ToastService.error(resetpassError);
      dispatch(resetpassreset());
    }
  }, [resetpassMessage, resetpassError, dispatch, navigate]);

  return (
    <section className="bg-gray-50 ">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img src={Logo} alt="Logo" className="mb-5 h-[100px]" />
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Reset Password
              </h1>
            </div>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-semibold text-gray-900 "
                >
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <div
                    className="absolute right-3 top-[9px]"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <Eye className="w-5" />
                    ) : (
                      <EyeOff className="w-5" />
                    )}
                  </div>
                </div>
                {formik.errors.password && formik.touched.password && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-semibold text-gray-900 "
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="••••••••"
                    className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 `}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                  <div
                    className="absolute right-3 top-[9px]"
                    onClick={handleTogglePasswordVisibility2}
                  >
                    {showPassword2 ? (
                      <Eye className="w-5" />
                    ) : (
                      <EyeOff className="w-5" />
                    )}
                  </div>
                </div>
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.confirmPassword}
                    </div>
                  )}
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
