import { createSlice } from "@reduxjs/toolkit";

export const requestSlice = createSlice({
  name: "request",
  initialState: {
    addRequestLoading: false,
    addRequestMessage: "",
    addRequestError: "",
    requests: [],
    request: null,
    getRequestLoading: false,
    getRequestMessage: "",
    getRequestError: "",
    getRequestDetailLoading: false,
    getRequestDetailMessage: "",
    getRequestDetailError: "",
    swapRequestLoading: false,
    swapRequestMessage: "",
    swapRequestError: "",
  },
  reducers: {
    getRequest: (state) => {
      state.getRequestLoading = true;
      state.getRequestMessage = "";
      state.getRequestError = "";
      state.requests = [];
    },
    getRequestSuccess: (state, action) => {
      state.getRequestLoading = false;
      state.requests = action.payload.response;
      state.getRequestMessage = action.payload.message;
    },
    getRequestError: (state, action) => {
      state.getRequestLoading = false;
      state.getRequestError = action.payload;
      state.getRequestMessage = "";
      state.requests = [];
    },

    /*----------get request detail  -------*/

    getRequestDetail: (state) => {
      state.getRequestDetailLoading = true;
      state.getRequestDetailMessage = "";
      state.getRequestDetailError = "";
      state.request = null;
    },
    getRequestDetailSuccess: (state, action) => {
      state.getRequestDetailLoading = false;
      state.request = action.payload.response;
      state.getRequestDetailMessage = action.payload.message;
    },
    getRequestDetailError: (state, action) => {
      state.getRequestDetailLoading = false;
      state.getRequestDetailError = action.payload;
      state.getRequestDetailMessage = "";
      state.request = null;
    },

    /*----------add/edit request -------*/

    addRequest: (state) => {
      state.addRequestLoading = true;
      state.addRequestMessage = "";
      state.addRequestError = "";
    },
    addRequestSuccess: (state, action) => {
      state.addRequestLoading = false;
      state.addRequestMessage = action.payload.message;
    },
    addRequestError: (state, action) => {
      state.addRequestLoading = false;
      state.addRequestError = action.payload;
      state.addRequestMessage = "";
    },
    addRequestReset: (state) => {
      state.addRequestLoading = false;
      state.addRequestMessage = "";
      state.addRequestError = "";
    },

    /*----------swap request -------*/
    // swapRequest: (state) => {
    //   state.swapRequestLoading = true;
    //   state.swapRequestMessage = "";
    //   state.swapRequestError = "";
    // },
    // swapRequestSuccess: (state, action) => {
    //   state.swapRequestLoading = false;
    //   state.swapRequestMessage = "request successfully!";
    // },
    // swapRequestError: (state, action) => {
    //   state.swapRequestLoading = false;
    //   state.swapRequestError = action.payload;
    //   state.swapRequestMessage = "";
    // },
  },
});

export const {
  getRequest,
  getRequestSuccess,
  getRequestError,
  getRequestDetail,
  getRequestDetailSuccess,
  getRequestDetailError,
  addRequest,
  addRequestSuccess,
  addRequestError,
  addRequestReset,
} = requestSlice.actions;

export default requestSlice.reducer;
