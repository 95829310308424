import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/profile";

/*------------- Get Admin -------------*/

const getAdmin = async () => {
  const URL = `${URI}/adminProfile`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    const response = await axios.post(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred admin dashboard profile:", error);
    throw error;
  }
};

/*------------- Get Admin Dashboard -------------*/

const getAdminDashboard = async () => {
  const URL = `${URI}/adminDashboard`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    if (!token) {
      throw new Error("Token not found in localStorage");
    }

    const response = await axios.post(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during admin profile:", error);
    throw error;
  }
};

/*------------- change Password Admin -------------*/

const changePass = async (payload) => {
  const URL = `${URI}/changePassword`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    if (!token) {
      throw new Error("Token not found in localStorage");
    }
    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during admin change password:", error);
    throw error;
  }
};

/*------------- Update Admin -------------*/

const UpdateAdmin = async (payload) => {
  const URL = `${URI}/updateProfile`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    if (!token) {
      throw new Error("Token not found in localStorage");
    }
    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "this is  response from service");
    return response.data;
  } catch (error) {
    console.error("Error occurred during admin update profile:", error);
    throw error;
  }
};

/*------------- Logout Admin -------------*/

const logout = async () => {
  const URL = `${URI}/logout`;
  let user = localStorage.getItem("user-details");
  let headers = {
    Authorization: `Bearer ${JSON.parse(user).token}`,
  };
  try {
    const response = await axios.post(URL, null, { headers });
    return response.data;
  } catch (error) {
    console.error("Error occurred during logout:", error);
    throw error;
  }
};

/*---------- get setting -------*/

const setting = async (payload) => {
  const URL = `${URI}/getSettings`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get setting:", error);
    throw error;
  }
};

/*---------- update setting -------*/

const updateSetting = async (payload) => {
  const URL = `${URI}/updateSettings`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred update setting:", error);
    throw error;
  }
};

const AdminService = {
  getAdmin,
  changePass,
  UpdateAdmin,
  logout,
  setting,
  updateSetting,
  getAdminDashboard, //deleteUser
};

export default AdminService;
