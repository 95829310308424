import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/location";

/*---------- Get City -------*/

const city = async (payload) => {
  const URL = `${URI}/get-city`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get city:", error);
    throw error;
  }
};

/*---------- Delete City -------*/
const cityDelete = async (payload) => {
  const URL = `${URI}/delete-city`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete salesman:", error);
    throw error;
  }
};

/*---------- Create City -------*/
const cityCreate = async (payload) => {
  const URL = `${URI}/create-city`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create city:", error);
    throw error;
  }
};

/*---------- Update City -------*/
const cityUpdate = async (payload) => {
  const URL = `${URI}/update-city`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create city:", error);
    throw error;
  }
};

/*---------- set Country -------*/
// const setDefaultCity = async (payload) => {
//   const URL = `${URI}/set-default-city`;

//   try {
//     let user = localStorage.getItem("user-details");
//     let token = JSON.parse(user).token;

//     const response = await axios.post(URL, payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log("Error occurred set default City:", error);
//     throw error;
//   }
// };

const cityService = {
  city,
  cityDelete,
  cityCreate,
  cityUpdate,
  // setDefaultCity,
};

export default cityService;
