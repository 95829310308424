import { Box, IconButton } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { CountryDelete } from "../../../middlewares/Country";
import { useNavigate } from "react-router-dom";
import { Pencil, Trash2 } from "lucide-react";

const CountryActions = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className="flex justify-start h-full items-center">
      <IconButton
        color="info"
        onClick={() => {
          navigate(`${row.id}`, {
            state: { id: row?.id, code: row?.code, name: row?.name },
          });
        }}
      >
        <Pencil className="inline-block text-[#2e7d32]" />
      </IconButton>
      {row.is_default !== 1 ? (
        <IconButton
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to delete this country?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              customClass: {
                title: "Swal-title-class",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(CountryDelete({ id: row?.id }));
              }
            });
          }}
        >
          <Trash2 className="inline-block text-red-500 fill-red-100" />
        </IconButton>
      ) : (
        <IconButton></IconButton>
      )}
    </Box>
  );
};

export default CountryActions;
