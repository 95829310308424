import { CircleAlert, CircleCheckBig } from "lucide-react";
import { toast } from "react-toastify";

function success(message = "Success", optionalConfig = {}) {
  toast.success(message, {
    style: { backgroundColor: "#2BBC91", color: "#fff" },
    icon: <CircleCheckBig />,
    ...optionalConfig,
  });
}

function error(message = "Error!", optionalConfig = {}) {
  toast.error(message, {
    icon: <CircleAlert />,
    ...optionalConfig,
  });
}

const ToastService = {
  success,
  error,
};
export default ToastService;
