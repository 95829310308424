import { createSlice } from "@reduxjs/toolkit";

export const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    merchantLoading: false,
    merchantMessage: "",
    merchantError: "",
    createdMerchants: [],
    merchantActionLoading: false,
    merchantActionMessage: "",
    merchantActionError: "",
    addEditMerchantLoading: false,
    addEditMerchantMessage: "",
    addEditMerchantError: "",
    getMerchantDetailLoading: false,
    getMerchantDetailMessage: "",
    getMerchantDetailError: "",
    merchantDetail: null,
    bulkUploadLoading: false,
    bulkUploadMessage: "",
    bulkUploadError: "",
    merchantOptions: [],
  },
  reducers: {
    bulkRequest: (state) => {
      state.bulkUploadLoading = true;
      state.bulkUploadMessage = "";
      state.bulkUploadError = "";
    },
    bulkSuccess: (state, action) => {
      let { payload : { response}  } = action
      let {merchant} = state
      state.bulkUploadLoading = false;
      state.merchant = [...response, ...merchant] 
      state.bulkUploadMessage = action.payload.message;
    },
    bulkError: (state, action) => {
      state.bulkUploadLoading = false;
      state.bulkUploadError = action.payload;
      state.bulkUploadMessage = "";
    },
    bulkReset: (state) => {
      state.bulkUploadLoading = false;
      state.bulkUploadMessage = "";
      state.bulkUploadError = "";
    },

    /*----------get merchant -------*/
    merchantRequest: (state) => {
      state.merchantLoading = true;
      state.merchantMessage = "";
      state.merchantError = "";
      state.merchant = [];
    },
    merchantSuccess: (state, action) => {
      state.merchantLoading = false;
      state.merchant = action.payload.response;
      state.merchantMessage = action.payload.message;
    },
    merchantError: (state, action) => {
      state.merchantLoading = false;
      state.merchantError = action.payload;
      state.merchantMessage = "";
      state.merchant = [];
    },

    /*----------get merchant options -------*/
    merchantOptionsRequest: (state) => {
      state.merchantOptions = [];
    },
    merchantOptionsSuccess: (state, action) => {
      state.merchantOptions = action.payload.response;
    },
    merchantOptionsError: (state, action) => {
      state.merchantOptions = [];
    },

    /*----------add merchant -------*/

    addEditMerchantRequest: (state) => {
      state.addEditMerchantLoading = true;
      state.addEditMerchantMessage = "";
      state.addEditMerchantError = "";
    },
    addEditMerchantSuccess: (state, action) => {
      state.addEditMerchantLoading = false;
      state.addEditMerchantMessage = action.payload.message;
    },
    addEditMerchantError: (state, action) => {
      state.addEditMerchantLoading = false;
      state.addEditMerchantError = action.payload;
      state.addEditMerchantMessage = "";
    },
    addEditMerchantreset: (state) => {
      state.addEditMerchantLoading = false;
      state.addEditMerchantMessage = "";
      state.addEditMerchantError = "";
    },

    /*---------- active-deactive-delete -------*/

    merchantActionRequest: (state) => {
      state.merchantActionLoading = true;
      state.merchantActionMessage = "";
      state.merchantActionError = "";
    },
    merchantActionSuccess: (state, action) => {
      let { merchant } = state;
      let tempArray = merchant?.filter(
        (merchants) => merchants.id !== action.payload.merchantId
      );
      state.merchantActionLoading = false;
      state.merchant = tempArray;
      state.merchantActionMessage = action.payload.message;
    },
    merchantActionError: (state, action) => {
      state.merchantActionLoading = false;
      state.merchantActionError = action.payload.message;
      state.merchantActionMessage = "";
    },
    merchantActionReset: (state) => {
      state.merchantActionLoading = false;
      state.merchantActionMessage = "";
      state.merchantActionError = "";
    },

    /*----------get / edit merchant detail  -------*/

    getMerchantDetailReq: (state) => {
      state.getMerchantDetailLoading = true;
      state.getMerchantDetailMessage = "";
      state.getMerchantDetailError = "";
      state.merchantDetail = null;
    },
    getMerchantDetailSuccess: (state, action) => {
      state.getMerchantDetailLoading = false;
      state.merchantDetail = action.payload.response;
      state.getMerchantDetailMessage = action.payload.message;
    },
    getMerchantDetailError: (state, action) => {
      state.getMerchantDetailLoading = false;
      state.getMerchantDetailError = action.payload;
      state.getMerchantDetailMessage = "";
      state.merchantDetail = null;
    },
    getMerchantDetailReset: (state) => {
      state.getMerchantDetailLoading = false;
      state.getMerchantDetailMessage = "";
      state.getMerchantDetailError = "";
    },
  },
});

export const {
  merchantRequest,
  merchantSuccess,
  merchantError,
  merchantActionRequest,
  merchantActionSuccess,
  merchantActionError,
  merchantActionReset,
  addEditMerchantError,
  addEditMerchantSuccess,
  addEditMerchantRequest,
  addEditMerchantreset,
  getMerchantDetailReq,
  getMerchantDetailSuccess,
  getMerchantDetailError,
  getMerchantDetailReset,
  MerchantDetailResetWithoutId,
  bulkError,
  bulkSuccess,
  bulkRequest,
  bulkReset,
  merchantOptionsRequest,
  merchantOptionsSuccess,
  merchantOptionsError,
} = merchantSlice.actions;

export default merchantSlice.reducer;
