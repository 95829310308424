import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignRequest } from "../../../middlewares/request";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import { addRequestReset } from "../../../slices/request";
import BulkUpload from "../../../component/common/BulkUpload";
import { bulkReset } from "../../../slices/merchant";
import Select from "react-select";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { getRegion } from "../../../middlewares/region";

function AssignRequest() {
  const dispatch = useDispatch();
  const { addRequestError, addRequestMessage } = useSelector(
    (state) => state.request
  );
  const { bulkUploadMessage, bulkUploadError } = useSelector(
    (state) => state.merchant
  );

  useEffect(() => {
    dispatch(getRegion());
  }, [dispatch]);
  const { region } = useSelector((state) => state.region);

  const handleSubmit = (values) => {
    const payload = {
      regionId: values.region,
    };
    dispatch(assignRequest(payload));
  };

  const AssignReq = Yup.object({
    region: Yup.string().required("Please select region"),
  });

  const formik = useFormik({
    initialValues: {
      region: "",
    },
    validationSchema: AssignReq,
    onSubmit: handleSubmit,
  });

  const RegionOptions = region?.map((reg) => ({
    label: reg.name,
    value: reg.id,
  }));

  useEffect(() => {
    if (addRequestMessage) {
      ToastService.success(addRequestMessage);
      formik.resetForm();
      dispatch(addRequestReset());
    }
    if (addRequestError) {
      ToastService.error(addRequestError);
      dispatch(addRequestReset());
    }
    if (bulkUploadMessage) {
      ToastService.success(bulkUploadMessage);
      dispatch(bulkReset());
    }
    if (bulkUploadError) {
      ToastService.error(bulkUploadError);
      dispatch(bulkReset());
    }
  }, [
    addRequestMessage,
    addRequestError,
    bulkUploadMessage,
    bulkUploadError,
    dispatch,
    formik,
  ]);

  return (
    <div className="assignrequest-page">
      <BreadCrumb title="Assign Request" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white my-5 p-7 rounded-lg">
        <Box className="flex justify-end">
          <BulkUpload title="Bulk Upload" type="Request" />
          <a href={require("../../../assets/Excel/Request.xlsx")} download>
            <Button
              className="text-white button-style capitalize"
              variant="contained"
            >
              Download
            </Button>
          </a>
        </Box>
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label
                className="mb-2 font-semibold"
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              >
                Region
              </label>
              <Select
                className="select-css custom-select-container"
                classNamePrefix="react-select"
                data-choices
                name="region"
                options={RegionOptions}
                placeholder="Select merchandiser"
                onChange={(selectedOption) =>
                  formik.setFieldValue("region", selectedOption.value)
                }
                value={
                  formik.values.region
                    ? RegionOptions.find(
                        (option) => option.value === formik.values.region
                      )
                    : ""
                }
              />
              {formik.errors.region && formik.touched.region && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.region}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box className="flex items-end justify-end gap-4 text-end">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AssignRequest;
