import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../middlewares/Country";
import { ProvinceCreate, ProvinceUpdate } from "../../middlewares/Province";
import {
  selectCountry,
  Provincename,
} from "../../helpers/yup.validation.schema";
import ToastService from "../../helpers/toast-services";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { provinceActionCreateReset } from "../../slices/Province";
import BreadCrumb from "../../component/common/BreadCrumb";

function AddProvince() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdated, setIsUpdated] = useState(false);

  const provinceUpdateId = state?.id || null;
  const countryUpdateId = state?.countryId || null;
  const provinceUpdateName = state?.name || null;

  useEffect(() => {
    if (!provinceUpdateId) {
      dispatch(getCountry());
    }
    if (provinceUpdateId) {
      setIsUpdated(true);
      dispatch(getCountry());
    }
  }, [dispatch, provinceUpdateId]);

  const { provinceActionCreateMessage, provinceActionCreateError } =
    useSelector((state) => state.province);

  const { country } = useSelector((state) => state.country);

  useEffect(() => {
    if (provinceActionCreateMessage) {
      ToastService.success(provinceActionCreateMessage);
    }
    if (provinceActionCreateError) {
      ToastService.error(provinceActionCreateError);
    }
    dispatch(provinceActionCreateReset());
  }, [provinceActionCreateMessage, provinceActionCreateError, dispatch]);

  const AddProvinceSchema = Yup.object({
    country: selectCountry,
    Provincename: Provincename,
  });

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      name: values.Provincename,
      country_id: values.country,
      is_visible: 1,
      is_default: 0,
    };

    if (isUpdated) {
      navigate("/admin/location/provinces");
      dispatch(ProvinceUpdate({ ...payload, id: provinceUpdateId }));
    } else {
      dispatch(ProvinceCreate(payload));
    }
    resetForm(); // Reset the form after submission
  };

  const formik = useFormik({
    initialValues: {
      country: isUpdated ? countryUpdateId : "",
      Provincename: isUpdated ? provinceUpdateName : "",
    },
    validationSchema: AddProvinceSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="province-page">
      <BreadCrumb
        title={isUpdated ? "Edit Province" : "Add Province"}
        pageTitle={"Dashboard"}
      />
      <ToastContainer />
      <div className="bg-white my-5 mt-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label htmlFor="country" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Country
              </label>
              <select
                name="country"
                id="country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option selected hidden>
                  Select Country
                </option>
                {country?.map((countrys) => (
                  <option key={countrys.id} value={countrys.id}>
                    {countrys.name}
                  </option>
                ))}
              </select>
              {formik.errors.country && formik.touched.country && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.country}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Province Name</label>
              <OutlinedInput
                placeholder="Enter Province Name"
                name="Provincename"
                id="Provincename"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Provincename}
              />
              {formik.errors.Provincename && formik.touched.Provincename && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.Provincename}
                </Box>
              )}
            </FormControl>
          </Box>

          <Box className="flex items-end justify-end gap-4 text-end">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddProvince;
