import Logo from "../../assets/Images/Logo.svg";
import LockIcon from "../../assets/Images/password.svg";
import {
  confirmPassword,
  newPasswordApp,
} from "../../helpers/yup.validation.schema";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, InputAdornment, OutlinedInput } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../helpers/toast-services";
import { resetUserPassword } from "../../middlewares/resetPassApp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Eye, EyeOff } from "lucide-react";
import { resetpassreset } from "../../slices/resetPassApp";

function ResetPassword() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetpassMessage, resetpassError } = useSelector(
    (state) => state.resetpass
  );

  // ---------------------- Password hide show --------------------------------

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  // ---------------------- validation --------------------------------
  const handleSubmit = (values) => {
    const payload = {
      password: values.password,
      id: id,
    };
    dispatch(resetUserPassword(payload));
  };

  const resetSchema = Yup.object({
    password: newPasswordApp,
    confirmPassword: confirmPassword,
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (resetpassMessage) {
      navigate("/resetpassword/successfully");
    }
    if (resetpassError) {
      ToastService.error(resetpassError);
      dispatch(resetpassreset());
    }
  }, [resetpassMessage, resetpassError, dispatch, navigate]);

  return (
    <div className="reset-page bg-gray-50">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-[40rem] xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="flex items-center justify-center">
              <div className="w-[150px] h-auto mb-5 ">
                <img src={Logo} alt="logo" className="" />
              </div>
            </div>
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Reset Password
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  New Password
                </label>
                <OutlinedInput
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="w-full"
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={LockIcon}
                        alt="LockIcon"
                        className="cursor-pointer h-8 "
                      />{" "}
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <Box onClick={handleTogglePasswordVisibility}>
                        {showPassword ? (
                          <Eye className="text-[#1C59F0]" />
                        ) : (
                          <EyeOff className="text-[#1C59F0]" />
                        )}
                      </Box>
                    </InputAdornment>
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Confirm Password
                </label>
                <OutlinedInput
                  placeholder="Confirm Password"
                  type={showPassword2 ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full"
                  value={formik.values.confirmPassword}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={LockIcon}
                        alt="LockIcon"
                        className="cursor-pointer h-8"
                      />{" "}
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <Box onClick={handleTogglePasswordVisibility2}>
                        {showPassword2 ? (
                          <Eye className="text-[#1C59F0]" />
                        ) : (
                          <EyeOff className="text-[#1C59F0]" />
                        )}
                      </Box>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="text-center mt-5">
                <div className="text-red-500 font-semibold text-sm mt-1">
                  {formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : formik.errors.confirmPassword &&
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword}
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                className="w-full MuiButton-containedgreen"
              >
                Reset Password
              </Button>
            </form>
          </div>
        </div>
      </div>
      {/* <Grid container className="md:h-screen flex items-center justify-center">
        <Grid item className="max-h-auto">
          <Box className="flex justify-center flex-col h-full p-12 w-[600px] rounded-2xl shadow bg-white small-device-width">
            <img src={Logo} alt="logo" className="mb-16 mt-5 mx-auto" />
            <h1>Reset Password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-6 w-full">
                <FormControl className="w-full" variant="outlined">
                  <label className="text-base text-[#1C59F0] font-semibold mb-2">
                    New Password
                  </label>
                  <OutlinedInput
                    placeholder="New Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={LockIcon}
                          alt="LockIcon"
                          className="cursor-pointer h-8 "
                        />{" "}
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Box onClick={handleTogglePasswordVisibility}>
                          {showPassword ? (
                            <Eye className="text-[#1C59F0]" />
                          ) : (
                            <EyeOff className="text-[#1C59F0]" />
                          )}
                        </Box>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="mb-8 w-full">
                <FormControl className="w-full" variant="outlined">
                  <label className="text-base text-[#1C59F0] font-semibold mb-2">
                    Confirm Password
                  </label>
                  <OutlinedInput
                    placeholder="Confirm Password"
                    type={showPassword2 ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={LockIcon}
                          alt="LockIcon"
                          className="cursor-pointer h-8"
                        />{" "}
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Box onClick={handleTogglePasswordVisibility2}>
                          {showPassword2 ? (
                            <Eye className="text-[#1C59F0]" />
                          ) : (
                            <EyeOff className="text-[#1C59F0]" />
                          )}
                        </Box>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div className="text-center mt-5">
                  <div className="text-red-500 font-semibold text-sm mt-1">
                    {formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : formik.errors.confirmPassword &&
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword}
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                className="w-full MuiButton-containedgreen"
              >
                Reset Password
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default ResetPassword;
