import React, { useEffect } from "react";
import BlankSvg from "../../../assets/Images/blank.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../../middlewares/Country";
import {
  email,
  phone,
  selectRegion,
} from "../../../helpers/yup.validation.schema";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { Eye, EyeOff, Pencil } from "lucide-react";
import { salesmanAdd } from "../../../middlewares/salesman";
import { addSalesmanreset } from "../../../slices/salesman";
import { getRegion } from "../../../middlewares/region";

function AddMerchant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addSalesmanMessage, addSalesmanError } = useSelector(
    (state) => state.salesman
  );

  const [imageSrc, setImageSrc] = useState(BlankSvg);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    dispatch(getCountry());
    dispatch(getRegion());
  }, [dispatch]);
  const { region } = useSelector((state) => state.region);
  const { country } = useSelector((state) => state.country);
  const defaultCountry = country?.find((cntry) => cntry.is_default === 1);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      fullName: values.name,
      countryCode: `+${values.country}`,
      phoneNo: values.phone,
      password: values.password,
      regionId: values.region,
    };
    if (values.file) {
      payload.file = values.file;
    }
    dispatch(salesmanAdd(payload));
  };

  const AddMerchant = Yup.object({
    file: "",
    name: Yup.string().required("Merchandiser name is required"),
    email: email,
    phone: phone,
    password: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .matches(/^\S*$/, "Password cannot contain spaces")
      .max(50, "Password should not contain more than 50 characters")
      .required("Password is required"),
    region: selectRegion,
  });

  const formik = useFormik({
    initialValues: {
      file: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      region: "",
      country: defaultCountry ? +defaultCountry.code : "",
    },
    validationSchema: AddMerchant,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (addSalesmanMessage) {
      navigate("/admin/merchandiser/active");
    }
    if (addSalesmanError) {
      ToastService.error(addSalesmanError);
      dispatch(addSalesmanreset());
    }
  }, [addSalesmanMessage, addSalesmanError, dispatch, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("file", e.target.files[0]);
          setErrorMessage("");
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("file", "");
      }
    }
  };

  const [showField, setShowField] = useState({
    Password: false,
  });
  const handleVisiblityChange = (field) => {
    let tempObj = JSON.parse(JSON.stringify(showField));
    tempObj[`${field}`] = !showField[`${field}`];
    setShowField(tempObj);
  };

  return (
    <div className="Merchandiser-page">
      <BreadCrumb title="Add Merchandiser" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white m-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box className="">
            <div>
              <label className="font-medium">Profile Image</label>
              <br />
              <div className="flex flex-col mt-2 justify-center items-start gap-x-2.5">
                <div className="relative">
                  <div className="w-24 h-24 overflow-hidden rounded-[50%]">
                    <img
                      src={imageSrc}
                      alt="flag"
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="absolute bottom-0 right-0 h-[32px] w-[32px] rounded-[50%] bg-[#E5FFF1] flex items-center justify-center">
                    <input
                      type="file"
                      name="file"
                      accept="image/png, image/jpeg,image/jpg"
                      id="editimg"
                      onChange={(e) => {
                        handleFileChange(e); // Call your custom function
                      }}
                      hidden
                    />
                    <label htmlFor="editimg" className="cursor-pointer ">
                      <Pencil className="inline-block text-16 text-[#2e7d32]"></Pencil>
                    </label>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
              )}
            </div>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Name</label>
              <OutlinedInput
                placeholder="Enter Name"
                name="name"
                id="name"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Email</label>
              <OutlinedInput
                placeholder="Enter Email"
                name="email"
                id="email"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label htmlFor="language" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Region
              </label>
              <select
                name="region"
                id="region"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.region}
                className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option value="" hidden>
                  Select Region
                </option>
                {region?.map((reg) => (
                  <option key={reg.id} value={reg.id}>
                    {reg.name}
                  </option>
                ))}
              </select>
              {formik.errors.region && formik.touched.region && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.region}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Password</label>
              <OutlinedInput
                type={showField.Password ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter Old Password"
                // className="rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                endAdornment={
                  <InputAdornment
                    position="end"
                    onClick={() => handleVisiblityChange("Password")}
                    className="cursor-pointer"
                  >
                    {showField.Password ? <Eye /> : <EyeOff />}
                  </InputAdornment>
                }
              />
              {formik.errors.password && formik.touched.password && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.password}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Phone Number</label>
              <div className="relative">
                <span className="absolute top-[10%] left-1 px-1  z-20 border-r-[2px] border-solid">
                  <select
                    name="country"
                    id="country"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    className="border-none py-[10px] select-style bg-none px-3"
                  >
                    {country?.map((cntry) => (
                      <option key={cntry.id} value={+cntry.code}>
                        {cntry.code}
                      </option>
                    ))}
                  </select>
                </span>
                <OutlinedInput
                  placeholder="Enter Phone Number"
                  name="phone"
                  id="phone"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className="w-full pl-16"
                ></OutlinedInput>
              </div>
              {formik.errors.phone && formik.touched.phone && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.phone}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box className="flex items-end justify-end gap-4 text-end">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddMerchant;
