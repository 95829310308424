// import LocalstorageService from "../../helpers/localstorage-services";
import CMSService from "../../../services/admin/cms/cms.js";
import {
  cmsRequest,
  cmsSuccess,
  cmsError,
  editcmsSuccess,
  editcmsError,
  editcmsRequest,
} from "../../../slices/admin/cms/cms.slice.js";
import { handleAuthError } from "../../../utlils/authErrorHandler.js";

export function GetSingleCMS(payload) {
  return (dispatch) => {
    dispatch(cmsRequest());
    CMSService.getSingleCMS(payload)
      .then((res) => {
        const { response, message, status } = res;
        if (status === 1) {
          dispatch(cmsSuccess({ data: response, message }));
        } else {
          dispatch(cmsError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        let message = error.message;
        dispatch(cmsError({ message }));
      });
  };
}

export function EditCMS(payload) {
  return (dispatch) => {
    dispatch(editcmsRequest());
    CMSService.editCms(payload)
      .then((res) => {
        const {
          response: { updatedCms },
          message,
          status,
        } = res;
        if (status === 1) {
          dispatch(editcmsSuccess({ data: updatedCms, message }));
        } else {
          dispatch(editcmsError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        let message = error.message;
        dispatch(editcmsError({ message }));
      });
  };
}
