import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminLoading: false,
    adminMessage: "",
    adminError: "",
    getAdmin: null,
    adminDashLoading: false,
    adminDashMessage: "",
    adminDashError: "",
    getAdminDashData: null,
    changePassAdmin: null,
    changePassAdminLoading: false,
    changePassAdminMsg: "",
    changePassAdminError: "",
    updateAdmin: null,
    updateAdminLoading: false,
    updateAdminMsg: "",
    updateAdminError: "",
    logoutLoading: false,
    logoutMessage: "",
    logoutError: "",
    logoutAdmin: null,
    settingLoading: false,
    settingMessage: "",
    settingError: "",
    setting: null,
  },
  reducers: {
    /*------------- Get setting -------------*/

    settingRequest: (state) => {
      state.settingLoading = true;
      state.settingMessage = "";
      state.settingError = "";
      state.setting = null;
    },
    settingSuccess: (state, action) => {
      state.settingLoading = false;
      state.setting = action.payload.response;
      state.settingMessage = action.payload.message;
      state.settingError="";
    },
    settingError: (state, action) => {
      state.settingLoading = false;
      state.settingError = action.payload.message;
      state.settingMessage = "";
      state.setting = null;
    },
    settingReset: (state) => {
      state.settingLoading = false;
      state.settingMessage = "";
      state.settingError = "";
    },

    /*------------- Get Admin -------------*/
    adminRequest: (state) => {
      state.adminLoading = true;
      state.adminMessage = "";
      state.adminError = "";
    },
    adminSuccess: (state, action) => {
      state.adminLoading = false;
      state.getAdmin = action.payload;
      state.adminMessage = action.payload.message;
    },
    adminError: (state, action) => {
      state.adminLoading = false;
      state.adminError = action.payload.message;
    },

    /*------------- Get Admin dashboard -------------*/
    getAdminDashRequest: (state) => {
      state.adminDashLoading = true;
      state.adminDashMessage = "";
      state.adminDashError = "";
    },
    getAdminDashSuccess: (state, action) => {
      state.adminDashLoading = false;
      state.getAdminDashData = action.payload.response;
      state.adminDashMessage = action.payload.message;
    },
    getAdminDashError: (state, action) => {
      state.adminDashLoading = false;
      state.adminDashError = action.payload.message;
      state.adminDashMessage = "";
    },

    /*------------- change  Admin Pass -------------*/
    changePassAdminRequest: (state) => {
      state.changePassAdminLoading = true;
      state.changePassAdminMsg = "";
      state.changePassAdminError = "";
    },
    changePassAdminSuccess: (state, action) => {
      state.changePassAdminLoading = false;
      state.changePassAdminMsg = action.payload.message;
      state.changePassAdmin = action.payload;
    },
    changePassAdminError: (state, action) => {
      state.changePassAdminLoading = false;
      state.changePassAdminError = action.payload.message;
    },

    /*------------- Update Admin -------------*/
    updateAdminRequest: (state) => {
      state.updateAdminLoading = true;
      state.updateAdminMsg = "";
      state.updateAdminError = "";
    },
    updateAdminSuccess: (state, action) => {
      state.updateAdminLoading = false;
      state.updateAdminMsg =  action.payload.message;
      state.updateAdmin = action.payload;
    },
    updateAdminError: (state, action) => {
      state.updateAdminLoading = false;
      state.updateAdminError = action.payload.message;
      state.updateAdminMsg = "";
    },

    /*------------- logout Admin -------------*/
    logoutRequest: (state) => {
      state.logoutLoading = true;
      state.logoutMessage = "";
      state.logoutError = "";
    },
    logoutSuccess: (state, action) => {
      state.logoutLoading = false;
      state.logoutAdmin = "success";
      state.logoutMessage = action.payload.message;
      state.logoutError = false;
    },
    logoutError: (state, action) => {
      state.logoutLoading = false;
      state.logoutError = action.payload.message;
      state.logoutMessage = "";
    },
    logoutReste: (state) => {
      state.logoutLoading = false;
      state.logoutMessage = "";
      state.logoutError = "";
    },
  },
});

export const {
  adminRequest,
  adminSuccess,
  adminError,
  getAdminDashRequest,
  getAdminDashSuccess,
  getAdminDashError,
  changePassAdminRequest,
  changePassAdminSuccess,
  changePassAdminError,
  updateAdminRequest,
  updateAdminSuccess,
  updateAdminError,
  logoutRequest,
  logoutSuccess,
  logoutError,
  logoutReste,
  settingSuccess,
  settingError,
  settingRequest,
  settingReset,
} = adminSlice.actions;

export default adminSlice.reducer;
