import React, { useEffect } from "react";
import userProfile from "../../../assets/Images/Logo.svg";
import { Dropdown } from "./Dropdown";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { LockKeyhole, LogOut, User2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../middlewares/adminProfile";
import { loginReset } from "../../../slices/auth";

function ProfileMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logoutMessage } = useSelector((state) => state.admin);

  const logutmodel = () => {
    Swal.fire({
      title: "Are you sure you want to logout ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "Swal-title-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutUser());
      }
    });
  };

  useEffect(() => {
    if (logoutMessage) {
      dispatch(loginReset());
      navigate("/");
    }
  }, [logoutMessage]);

  return (
    <Dropdown className="relative flex items-center h-header">
      <Dropdown.Trigger
        type="button"
        className="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover  group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=brand]:text-topbar-item-brand"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
      >
        <div className="w-24">
          <img src={userProfile} alt="" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content
        placement="right-end"
        className="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] "
        aria-labelledby="dropdownMenuButton"
      >
        <button className="flex gap-3 mb-3">
          <div className="relative inline-block shrink-0">
            <div className="w-16">
              <img src={userProfile} alt="" />
            </div>
          </div>
          <div>
            <p className="otp-color capitalize ">admin</p>
          </div>
        </button>
        <ul>
          <li>
            <Link
              className="block otp-color ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              to="/admin/profile"
            >
              <User2 className="inline-block size-4 ltr:mr-2 rtl:ml-2"></User2>{" "}
              Profile
            </Link>
          </li>
          <li>
            <Link
              to="/admin/changepass"
              className="block otp-color ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
            >
              <LockKeyhole className="inline-block size-4 ltr:mr-2 rtl:ml-2"></LockKeyhole>{" "}
              Change Password
            </Link>
          </li>
          <li className="pt-2 mt-2 border-t border-slate-200">
            <button
              className="block otp-color cursor-pointer ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              onClick={logutmodel}
            >
              <LogOut className="inline-block size-4 ltr:mr-2 rtl:ml-2"></LogOut>{" "}
              Sign Out
            </button>
          </li>
        </ul>
      </Dropdown.Content>
    </Dropdown>
  );
}

export default ProfileMenu;
