import { Box, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { salesmanDelete, salesmanStatus } from "../../../middlewares/salesman";
import { useDispatch } from "react-redux";
import { CircleCheckBig, Eye, Trash2 } from "lucide-react";

const Pendingsalesman = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box className="flex justify-start h-full items-center">
      <IconButton
        color="info"
        onClick={() => {
          navigate(`${row.id}`);
        }}
      >
        <Eye className="inline-block text-[#00B052]" />
      </IconButton>
      <IconButton
        onClick={() => {
          Swal.fire({
            title: "Are you sure approve merchandiser?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              title: "Swal-title-class",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(salesmanStatus({ salesmanId: row?.id, status: true }));
            }
          });
        }}
      >
        <CircleCheckBig className="text-xl text-[#00B052] " />
      </IconButton>
      <IconButton
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to delete this merchandiser?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              title: "Swal-title-class",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(salesmanDelete({ salesmanId: row?.id }));
            }
          });
        }}
      >
        <Trash2 className="inline-block text-red-500 fill-red-100" />
      </IconButton>
    </Box>
  );
};

export default Pendingsalesman;
