import React, { useEffect } from "react";
import * as Yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { SquareX } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import { swapRequestForMerchant } from "../../../middlewares/request";
import {
  getMerchantForSwap,
  merchantDelete,
} from "../../../middlewares/merchant";
import { addRequestReset } from "../../../slices/request";

const ModalMerchant = ({ open, handleClose, id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      dispatch(
        getMerchantForSwap({
          type: "active",
        })
      );
    }
  }, [dispatch, open]);
  const { merchantOptions } = useSelector((state) => state.merchant);
  const MerchantOptions =
    merchantOptions
      ?.filter((mer) => mer?.id !== id)
      ?.map((mer) => ({
        label: mer.fullName,
        value: mer.id,
      })) || [];

  const handleSubmit = (values) => {
    const payload = {
      oldMerchantId: id,
      newMerchantId: values.swapreq,
    };
    dispatch(swapRequestForMerchant(payload));
    formik.resetForm();
  };

  const swapRequ = Yup.object({
    swapreq: Yup.string().required("Please select merchandiser"),
  });

  const formik = useFormik({
    initialValues: {
      swapreq: "",
    },
    validationSchema: swapRequ,
    onSubmit: handleSubmit,
  });

  const { addRequestMessage } = useSelector((state) => state.request);

  useEffect(() => {
    if (addRequestMessage) {
      if (id) {
        dispatch(merchantDelete({ merchantId: id }));
        handleClose();
        dispatch(addRequestReset());
      }
    }
  }, [addRequestMessage, dispatch, id, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <div className="flex justify-between items-center border-b border-[#eaeaea] pb-1">
          <h3 className="text-black font-semibold"> Select Merchant</h3>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={handleClose}
          >
            <SquareX className="text-black" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="gray-border border-radius-10 p-3">
          <form onSubmit={formik.handleSubmit}>
            <Select
              className="select-css custom-select-container"
              classNamePrefix="react-select"
              data-choices
              name="swapreq"
              options={MerchantOptions}
              onChange={(selectedOption) =>
                formik.setFieldValue("swapreq", selectedOption.value)
              }
            />
            {formik.errors.swapreq && formik.touched.swapreq && (
              <Box className="text-red-500 text-sm mt-1">
                {formik.errors.swapreq}
              </Box>
            )}
            <div className="flex justify-end mt-6 gap-3">
              <Button
                type="submit"
                className="text-white button-style capitalize"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalMerchant;
