import { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import * as Yup from "yup";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EditCMS, GetSingleCMS } from "../../../middlewares/admin/cms/cms";
import { useFormik } from "formik";
import { Content, Title } from "../../../helpers/validation/AddCMS";
import ToastService from "../../../helpers/toast-services";
import { ToastContainer } from "react-toastify";
import { cmsReset } from "../../../slices/admin/cms/cms.slice";
import BreadCrumb from "../../../component/common/BreadCrumb";

function Privacy() {
  const editor = useRef(null);
  const dispatch = useDispatch();
  const [initalVal, setInitialVal] = useState({ title: "", content: "" });
  const { cms, editError, editMessage } = useSelector((state) => state.cms);

  useEffect(() => {
    dispatch(GetSingleCMS({ type: "policy" }));
  }, [dispatch]);

  const handleSubmit = (values) => {
    let type = "policy";
    const { title, content } = values;
    dispatch(EditCMS({ title, content, type }));
  };

  const CMSSchema = Yup.object({
    title: Title,
    content: Content,
  });
  useEffect(() => {
    if (cms) {
      setInitialVal({ title: cms?.title, content: cms?.content });
    }
  }, [cms]);

  const formik = useFormik({
    initialValues: initalVal,
    validationSchema: CMSSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (editMessage) {
      ToastService.success(editMessage);
    }
    if (editError) {
      ToastService.error(editMessage);
    }
    dispatch(cmsReset());
  }, [editError, editMessage, dispatch]);

  return (
    <div className="user-page">
      <ToastContainer />
      <BreadCrumb title="Privacy Policy" pageTitle={"Dashboard"} />
      <div className="mt-5 md:p-5 bg-white border border-[#ddd] border-solid ">
        <form onSubmit={formik.handleSubmit}>
          <div className="py-2 mb-4">
            <label htmlFor="title" className="font-bold w-[90px]">
              Title
            </label>
            <br />
            <input
              name="title"
              id="title"
              type="text"
              className={`rounded-lg w-full border-solid border border-zinc-400 mt-2 px-2 py-2 rounded-m ${
                formik.errors.title && formik.touched.title
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
            {formik.errors.title && formik.touched.title && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.title}
              </div>
            )}
          </div>
          <div>
            <label className="font-bold"> Description</label>
            <JoditEditor
              ref={editor}
              className={`${
                formik.errors.content && formik.touched.content
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              config={{ placeholder: "Start Typing..." }}
              value={formik.values.content}
              onBlur={(newContent) =>
                formik.setFieldValue("content", newContent)
              } // Update content using setFieldValue
            />
            {formik.errors.content && formik.touched.content && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.content}
              </div>
            )}
          </div>
          <div className="mt-5 text-end">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Privacy;
