import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/auth";
const AdminURI = process.env.REACT_APP_BASE_URL + "/admin/auth";

const reset = async (payload) => {
  const URL = `${URI}/resetPassword`;
  try {
    const response = await axios.post(URL, payload);
    return response.data;
  } catch (error) {
    console.error("Error occurred during reset:", error);
    throw error;
  }
};

const AdminResetPassWord = async (payload) => {
  const URL = `${AdminURI}/resetPassword`;
  try {
    const response = await axios.post(URL, payload);
    return response.data;
  } catch (error) {
    console.error("Error occurred during reset:", error);
    throw error;
  }
};

const resetService = {
  reset,
  AdminResetPassWord,
};

export default resetService;
