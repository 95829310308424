import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//Pages

import Login from "../pages/auth/login";
import ResetPasswordApp from "../pages/auth/ResetPasswordApp";
import ResetPasswordAdmin from "../pages/auth/ResetPasswordAdmin";
import ResetPassSuccessfully from "../pages/auth/ResetPassSuccessfully";
import ForgotPassword from "../pages/auth/ForgotPassword";

const basePath = "";

const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

const webRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: generatePath("resetpassword/:id"),
    element: <ResetPasswordApp />,
  },
  {
    path: generatePath("resetpassword/successfully"),
    element: <ResetPassSuccessfully />,
  },
  {
    path: generatePath("resetAdminPassword/:id"),
    element: <ResetPasswordAdmin />,
  },
  {
    path: generatePath("forgotpassword"),
    element: <ForgotPassword />,
  },
];

const WebNavigation = () => {
  const user = JSON.parse(localStorage.getItem("user-details"));
  return (
    <Routes>
      <Route
        path=""
        element={!user ? <Login /> : <Navigate to="/admin/dashboard" />}
      />
      {webRoutes.map((route, index) => {
        return (
          <Route
            key={`${route.path}${index}`}
            path={route.path}
            element={route.element}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default WebNavigation;
