import { createSlice } from "@reduxjs/toolkit";

export const salesmanSlice = createSlice({
  name: "salesman",
  initialState: {
    salesmanLoading: false,
    salesmanMessage: "",
    salesmanError: "",
    salesman: [],
    salesmanActionLoading: false,
    salesmanActionMessage: "",
    salesmanActionError: "",
    getSalesmanDetailLoading: false,
    getSalesmanDetailMessage: "",
    getSalesmanDetailError: "",
    salesmanDetail: null,
    addSalesmanLoading: false,
    addSalesmanMessage: "",
    addSalesmanError: "",
    salesmanFilterLoading: false,
    salesmanFilterMessage: "",
    salesmanFilterError: "",
    salesmanFilter: [],
  },
  reducers: {
    salesmanRequest: (state) => {
      state.salesmanLoading = true;
      state.salesmanMessage = "";
      state.salesmanError = "";
      state.salesman = [];
    },
    salesmanSuccess: (state, action) => {
      state.salesmanLoading = false;
      state.salesman = action.payload.response;
      state.salesmanMessage = action.payload.message;
    },
    salesmanError: (state, action) => {
      state.salesmanLoading = false;
      state.salesmanError = action.payload.message;
      state.salesmanMessage = "";
      state.salesman = [];
    },

    /*---------- active-deactive-delete -------*/

    salesmanActionRequest: (state) => {
      state.salesmanActionLoading = true;
      state.salesmanActionMessage = "";
      state.salesmanActionError = "";
    },
    salesmanActionSuccess: (state, action) => {
      let { salesman } = state;
      let tempArray = salesman?.filter(
        (salesmans) => salesmans.id !== action.payload.salesmanId
      );
      state.salesmanActionLoading = false;
      state.salesman = tempArray;
      state.salesmanActionMessage = action.payload.message;
    },
    salesmanActionError: (state, action) => {
      state.salesmanActionLoading = false;
      state.salesmanActionError = action.payload.message;
      state.salesmanActionMessage = "";
    },
    salesmanActionReset: (state) => {
      state.salesmanActionLoading = false;
      state.salesmanActionMessage = "";
      state.salesmanActionError = "";
    },

    /*----------add merchant -------*/

    addSalesmanRequest: (state) => {
      state.addSalesmanLoading = true;
      state.addSalesmanMessage = "";
      state.addSalesmanError = "";
    },
    addSalesmanSuccess: (state, action) => {
      state.addSalesmanLoading = false;
      state.addSalesmanMessage = action.payload.message;
    },
    addSalesmanError: (state, action) => {
      state.addSalesmanLoading = false;
      state.addSalesmanError = action.payload.message;
      state.addSalesmanMessage = "";
    },
    addSalesmanreset: (state) => {
      state.addSalesmanLoading = false;
      state.addSalesmanMessage = "";
      state.addSalesmanError = "";
    },

    /*----------get salesman detail  -------*/

    getSalesmanDetailRequest: (state) => {
      state.getSalesmanDetailLoading = true;
      state.getSalesmanDetailMessage = "";
      state.getSalesmanDetailError = "";
      state.salesmanDetail = null;
    },
    getSalesmanDetailSuccess: (state, action) => {
      state.getSalesmanDetailLoading = false;
      state.salesmanDetail = action.payload.response;
      state.getSalesmanDetailMessage = action.payload.message;
    },
    getSalesmanDetailError: (state, action) => {
      state.getSalesmanDetailLoading = false;
      state.getSalesmanDetailError = action.payload.message;
      state.getSalesmanDetailMessage = "";
      state.salesmanDetail = null;
    },
    getSalesmanDetailReset: (state) => {
      state.getSalesmanDetailLoading = false;
      state.getSalesmanDetailMessage = "";
      state.getSalesmanDetailError = "";
    },

    /*----------get filter salesman detail  -------*/

    salesmanFilterRequest: (state) => {
      state.salesmanFilterLoading = true;
      state.salesmanFilterMessage = "";
      state.salesmanFilterError = "";
      state.salesmanFilter = [];
    },
    salesmanFilterSuccess: (state, action) => {
      state.salesmanFilterLoading = false;
      state.salesmanFilter = action.payload.response;
      state.salesmanFilterMessage = action.payload.message;
    },
    salesmanFilterError: (state, action) => {
      state.salesmanFilterLoading = false;
      state.salesmanFilterError = action.payload.message;
      state.salesmanFilterMessage = "";
      state.salesmanFilter = [];
    },
  },
});

export const {
  salesmanRequest,
  salesmanSuccess,
  salesmanError,
  salesmanFilterRequest,
  salesmanFilterSuccess,
  salesmanFilterError,
  salesmanActionRequest,
  salesmanActionSuccess,
  salesmanActionError,
  salesmanActionReset,
  getSalesmanDetailSuccess,
  getSalesmanDetailError,
  getSalesmanDetailRequest,
  getSalesmanDetailReset,
  addSalesmanreset,
  addSalesmanError,
  addSalesmanSuccess,
  addSalesmanRequest,
} = salesmanSlice.actions;

export default salesmanSlice.reducer;
