import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { RegionCreate, updateRegion } from "../../../middlewares/region";
import ToastService from "../../../helpers/toast-services";
import { addRegionreset, editRegionreset } from "../../../slices/region";

function AddCity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    addRegionError,
    addRegionMessage,
    editRegionMessage,
    editRegionError,
  } = useSelector((state) => state.region);

  const [isUpdated, setIsUpdated] = useState(false);

  const regionId = state?.id || null;
  const regionName = state?.name || null;

  useEffect(() => {
    if (regionId) {
      setIsUpdated(true);
    }
  }, [regionId]);

  useEffect(() => {
    if (addRegionMessage) {
      navigate("/admin/location/Region");
    }
    if (editRegionMessage) {
      navigate("/admin/location/Region");
    }
    if (addRegionError) {
      ToastService.error(addRegionError);
      dispatch(addRegionreset());
    }
    if (editRegionError) {
      ToastService.error(editRegionError);
      dispatch(editRegionreset());
    }
  }, [
    addRegionMessage,
    addRegionError,
    editRegionMessage,
    editRegionError,
    dispatch,
    navigate,
  ]);

  const RegionSchema = Yup.object({
    regionname: Yup.string().required("Region name is required"),
  });

  const handleSubmit = (values) => {
    const payload = {
      name: values.regionname,
    };

    if (isUpdated) {
      dispatch(updateRegion({ ...payload, id: regionId }));
    } else {
      dispatch(RegionCreate(payload));
    }
  };

  const formik = useFormik({
    initialValues: {
      regionname: isUpdated ? regionName : "",
    },
    validationSchema: RegionSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="addedit-Region-page">
      <BreadCrumb
        title={isUpdated ? "Edit Region" : "Add Region"}
        pageTitle={"Dashboard"}
      />
      <ToastContainer />
      <div className="bg-white my-5 mt-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Name</label>
              <OutlinedInput
                placeholder="Enter region name"
                name="regionname"
                id="regionname"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.regionname}
              />
              {formik.errors.regionname && formik.touched.regionname && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.regionname}
                </Box>
              )}
            </FormControl>
          </Box>

          <Box className="flex items-end justify-end gap-4 text-end ">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddCity;
