import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CustomLoader = () => {
  return (
    <Box className="mx-5 mt-5 bg-white flex items-center justify-center py-10">
      <CircularProgress />
    </Box>
  );
};

export default CustomLoader;
