import React, { useEffect } from "react";
import * as Yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { SquareX } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getFilterSalesman,
  salesmanDelete,
} from "../../../middlewares/salesman";
import { useFormik } from "formik";
import { swapRequest } from "../../../middlewares/request";

const ModelSalesman = ({ open, handleClose, id, forEdit = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFilterSalesman({ salesmanId: id }));
  }, [dispatch, id]);
  const { salesmanFilter } = useSelector((state) => state.salesman);
  const MerchantOptions =
    salesmanFilter?.map((mer) => ({
      label: mer.fullName,
      value: mer.id,
    })) || [];

  const handleSubmit = (values) => {
    const payload = {
      oldSalesmanId: id,
      newSalesmanId: values.swapreq,
    };
    dispatch(swapRequest(payload));
  };

  const swapRequ = Yup.object({
    swapreq: Yup.string().required("Please select merchandiser"),
  });

  const formik = useFormik({
    initialValues: {
      swapreq: "",
    },
    validationSchema: swapRequ,
    onSubmit: handleSubmit,
  });

  const { addRequestMessage } = useSelector((state) => state.request);

  useEffect(() => {
    if (addRequestMessage) {
      if (id) {
        if (!forEdit) {
          dispatch(salesmanDelete({ salesmanId: id }));
        }
        if (forEdit) {
          handleClose();
        }
      }
    }
  }, [addRequestMessage, dispatch, id, forEdit, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <div className="flex justify-between items-center border-b border-[#eaeaea] pb-1">
          <h3 className="text-black font-semibold"> Select Merchandiser</h3>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={handleClose}
          >
            <SquareX className="text-black" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="gray-border border-radius-10 p-3">
          <form onSubmit={formik.handleSubmit}>
            <Select
              className="select-css custom-select-container"
              classNamePrefix="react-select"
              data-choices
              name="swapreq"
              options={MerchantOptions}
              onChange={(selectedOption) =>
                formik.setFieldValue("swapreq", selectedOption.value)
              }
            />
            {formik.errors.swapreq && formik.touched.swapreq && (
              <Box className="text-red-500 text-sm mt-1">
                {formik.errors.swapreq}
              </Box>
            )}
            <div className="flex justify-end mt-6 gap-3">
              <Button
                type="submit"
                className="text-white button-style capitalize"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModelSalesman;
