import { createSlice } from "@reduxjs/toolkit";

export const resetpassSlice = createSlice({
  name: "resetpass",
  initialState: {
    resetpassLoading: false,
    resetpassMessage: "",
    resetpassError: "",
    resetpassInUser: null,
  },
  reducers: {
    resetpassRequest: (state) => {
      state.resetpassLoading = true;
      state.resetpassMessage = "";
      state.resetpassError = "";
    },
    resetpassSuccess: (state, action) => {
      state.resetpassLoading = false;
      state.resetpassMessage = action.payload.message;
    },
    resetpassError: (state, action) => {
      state.resetpassLoading = false;
      state.resetpassError = action.payload.message;
      state.resetpassMessage = "";
    },
    resetpassreset: (state, action) => {
      state.resetpassLoading = false;
      state.resetpassMessage = "";
      state.resetpassError = "";
    },
  },
});

export const {
  resetpassRequest,
  resetpassreset,
  resetpassSuccess,
  resetpassError,
} = resetpassSlice.actions;

export default resetpassSlice.reducer;
