import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReqDetail } from "../../../middlewares/request";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Typography, Modal, Box } from "@mui/material";
import blankSalesman from "../../../assets/Images/blank.png";
import BreadCrumb from "../../../component/common/BreadCrumb";

function RequestDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { request, getRequestDetailLoading } = useSelector(
    (state) => state.request
  );

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(
        getReqDetail({
          id: id,
        })
      );
    }
  }, [dispatch, id]);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="requestdetail-page">
      <BreadCrumb title="Detail" pageTitle={"Dashboard"} />
      <div className="bg-white m-5 p-5 rounded-lg">
        {!getRequestDetailLoading ? (
          request ? (
            <>
              <Grid
                container
                spacing={2}
                style={{ marginTop: "0px", padding: "20px" }}
              >
                <Grid sm={12} md={6} style={{ marginBottom: "20px" }}>
                  <Typography
                    variant="h7"
                    className="text-center font-semibold"
                  >
                    Merchandiser Details
                  </Typography>
                  <img
                    className="h-24 w-24 my-4 object-cover "
                    alt={request.salesman.fullName}
                    src={
                      request.salesman.profileImage
                        ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.salesman.profileImage}`
                        : blankSalesman
                    }
                    onClick={() =>
                      handleOpen(
                        request.salesman.profileImage
                          ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.salesman.profileImage}`
                          : blankSalesman
                      )
                    }
                  />
                  <p>
                    <strong>Name:</strong> {request.salesman.fullName}
                  </p>
                  <p>
                    <strong>email:</strong> {request.salesman.email}
                  </p>
                  <p>
                    <strong>Phone No.:</strong> {request.salesman.countryCode}{" "}
                    {request.salesman.phoneNo}
                  </p>
                </Grid>
                <Grid sm={12} md={6} style={{ marginBottom: "20px" }}>
                  <Typography
                    variant="h7"
                    className="text-center font-semibold"
                  >
                    Merchant Details
                  </Typography>
                  <img
                    className="h-24 w-24 my-4 object-cover "
                    alt={request.merchant.fullName}
                    src={
                      request.merchant.profileImage
                        ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.merchant.profileImage}`
                        : blankSalesman
                    }
                    onClick={() =>
                      handleOpen(
                        request.merchant.profileImage
                          ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.merchant.profileImage}`
                          : blankSalesman
                      )
                    }
                  />
                  <p>
                    <strong>Name:</strong> {request.merchant.fullName}
                  </p>
                  <p>
                    <strong>email:</strong> {request.merchant.email}
                  </p>
                  <p>
                    <strong>Phone No.:</strong> {request.merchant.countryCode}{" "}
                    {request.merchant.phoneNo}
                  </p>
                  <p>
                    <strong>Address:</strong>{" "}
                    {request.merchant.city
                      ? `${request.merchant.city.name}, ${request.merchant.city.province.name}, ${request.merchant.city.province.country.name}`
                      : ""}
                  </p>
                </Grid>
              </Grid>
              <Box>
                <Typography variant="h7" className="text-center font-semibold">
                  Images
                </Typography>
                <Grid
                  container
                  spacing={2}
                  className="mt-0 p-5 justify-between"
                >
                  <Grid sm={12} md={6} style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="h7"
                      className="text-center font-semibold"
                    >
                      Before Image
                    </Typography>
                    <img
                      className="h-24 w-24 my-4 object-cover "
                      alt={request.merchant.fullName}
                      src={
                        request.beforeImage
                          ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.beforeImage}`
                          : blankSalesman
                      }
                      onClick={() =>
                        handleOpen(
                          request.beforeImage
                            ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.beforeImage}`
                            : blankSalesman
                        )
                      }
                    />
                  </Grid>
                  <Grid sm={12} md={6} style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="h7"
                      className="text-center font-semibold"
                    >
                      After Image
                    </Typography>
                    <img
                      className="h-24 w-24 my-4 object-cover "
                      alt={request.merchant.fullName}
                      src={
                        request.afterImage
                          ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.afterImage}`
                          : blankSalesman
                      }
                      onClick={() =>
                        handleOpen(
                          request.afterImage
                            ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${request.afterImage}`
                            : blankSalesman
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <p>No data found</p>
          )
        ) : (
          <CircularProgress />
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              maxHeight: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default RequestDetail;
