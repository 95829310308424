import bulkService from "../services/BulkUpload";
import { bulkError, bulkRequest, bulkSuccess } from "../slices/merchant";
import { handleAuthError } from "../utlils/authErrorHandler";

export function bulkMerchant(payload) {
  return (dispatch) => {
    dispatch(bulkRequest());
    bulkService
      .bulkUploadMerchant(payload)
      .then((data) => {
        const {
          message,
          status,
          response: { createdMerchants },
        } = data;
        if (status === 1) {
          dispatch(bulkSuccess({ response: createdMerchants, message }));
        } else {
          dispatch(bulkError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(bulkError(error));
      });
  };
}

export function bulkReq(payload) {
  return (dispatch) => {
    dispatch(bulkRequest());
    bulkService
      .bulkUploadRequest(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(bulkSuccess({ message }));
        } else {
          dispatch(bulkError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(bulkError(error));
      });
  };
}
