import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/merchant";

/*---------- get merchant -------*/

const merchantGet = async (payload) => {
  const URL = `${URI}/get`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get merchant:", error);
    throw error;
  }
};

/*---------- active-deactive merchant -------*/

const merchantStatus = async (payload) => {
  const URL = `${URI}/active-deactive`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred status salesman:", error);
    throw error;
  }
};

/*---------- create marchant-------*/

const merchantcreate = async (payload) => {
  const URL = `${URI}/create`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred create merchant:", error);
    throw error;
  }
};

//   /*---------- delete marchant-------*/

const merchantDelete = async (payload) => {
  const URL = `${URI}/delete`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete merchant :", error);
    throw error;
  }
};

//  /*---------- restore marchat-------*/

const merchantRestore = async (payload) => {
  const URL = `${URI}/restore`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred restore merchant :", error);
    throw error;
  }
};

/*---------- get merchant detail -------*/

const getMarchentDetail = async (payload) => {
  const URL = `${URI}/detail`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get merchant detail:", error);
    throw error;
  }
};

/*---------- update merchant -------*/

const updateMarchentDetail = async (payload) => {
  const URL = `${URI}/update`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred update merchant detail:", error);
    throw error;
  }
};

const merchantService = {
  merchantGet,
  merchantDelete,
  merchantRestore,
  merchantcreate,
  getMarchentDetail,
  merchantStatus,
  updateMarchentDetail,
};

export default merchantService;
