import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../middlewares/Country";
import ToastService from "../../helpers/toast-services";
import { countryActionDeleteReset } from "../../slices/Country";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../component/common/CustomLoader";
import BreadCrumb from "../../component/common/BreadCrumb";
import TableContainer from "../../component/common/tablecomponet/TableContainer";
import { Button, Typography } from "@mui/material";
import CountryActions from "../../component/tableActionbar/location/CountryActions";
import DefaultLocationSwitch from "../../component/tableActionbar/location/DefaultLocationSwitch";

function Country() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryToDisplay, setCountryToDisplay] = useState([]);
  const { country, countryLoading, countryActionMessage, countryActionError } =
    useSelector((state) => state.country);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    setCountryToDisplay(country);
  }, [country]);

  useEffect(() => {
    if (countryActionMessage) {
      ToastService.success(countryActionMessage);
    }
    if (countryActionError) {
      ToastService.error(countryActionError);
    }
    dispatch(countryActionDeleteReset());
  }, [countryActionMessage, countryActionError, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Code",
        accessorKey: "code",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p">{row.original.code}</Typography>
        ),
      },
      {
        header: "Default Country",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <DefaultLocationSwitch row={row.original} type="Country" />
          </Typography>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <CountryActions row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="country-page">
      <BreadCrumb title="Countries" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!countryLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={countryToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search Country"
              extraButton={
                <Button
                  className="text-white button-style capitalize"
                  variant="contained"
                  sx={{ marginLeft: "10px !important" }}
                  onClick={() => navigate(`/admin/location/countries/add`)}
                >
                  Add Country
                </Button>
              }
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Country;
