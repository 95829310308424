import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { salesmanDelete, salesmanStatus } from "../../../middlewares/salesman";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircleX, Eye, Pencil, Trash2 } from "lucide-react";
import ModelSalesman from "./modelSalesman";

const Activesalesman = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalId, setModalId] = useState("");
  return (
    <>
      <Box className="flex justify-start h-full items-center">
        <IconButton
          color="info"
          onClick={() => {
            navigate(`${row.id}`);
          }}
        >
          <Eye className="inline-block text-[#00B052]" />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(`/admin/editmerchandiser/${row.id}`);
          }}
        >
          <Pencil className="inline-block text-[#00B052]" />
        </IconButton>
        <IconButton
          onClick={() => {
            Swal.fire({
              title: "Are you sure reject merchandiser?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              customClass: {
                title: "Swal-title-class",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(
                  salesmanStatus({ salesmanId: row?.id, status: false })
                );
              }
            });
          }}
        >
          <CircleX className="text-xl text-[#d32f2f] " />
        </IconButton>
        <IconButton
          onClick={() => {
            if (row?.pendingRequestCount === 0) {
              Swal.fire({
                title: "Are you sure you want to delete this merchandiser?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                customClass: {
                  title: "Swal-title-class",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(salesmanDelete({ salesmanId: row?.id }));
                }
              });
            } else {
              setOpen(true);
              setModalId(row?.id);
            }
          }}
        >
          <Trash2 className="inline-block text-red-500 fill-red-100" />
        </IconButton>
      </Box>
      <ModelSalesman
        open={open}
        handleClose={() => setOpen(false)}
        id={modalId}
      />
    </>
  );
};

export default Activesalesman;
