import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { LoginUser } from "../../middlewares/auth";
import { useDispatch, useSelector } from "react-redux";
import { Password, email } from "../../helpers/yup.validation.schema";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import ToastService from "../../helpers/toast-services";
import Logo from "../../assets/Images/Logo.svg";
import { useState } from "react";
import { logoutReste } from "../../slices/adminProfile";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginLoading, loginMessage, loggedInUser, loginError } = useSelector(
    (state) => state.auth
  );
  const { logoutMessage, logoutLoading } = useSelector((state) => state.admin);

  // ---------------------- Password hide show --------------------------------

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (loginMessage) {
      navigate("/admin/dashboard");
      ToastService.success(loginMessage);
    }

    if (!logoutLoading) {
      if (logoutMessage) {
        ToastService.success(logoutMessage);
        dispatch(logoutReste());
      }
    }
  }, [
    loginLoading,
    loggedInUser,
    loginMessage,
    dispatch,
    navigate,
    logoutMessage,
    logoutLoading,
  ]);

  useEffect(() => {
    if (!loginLoading) {
      if (loginError) {
        ToastService.error(loginError);
      }
    }
  }, [loginLoading, loginError]);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    dispatch(LoginUser(payload));
  };

  const LoginSchema = Yup.object({
    email: email,
    password: Password,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });
  return (
    <section className="bg-gray-50">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img src={Logo} alt="Logo" className="mb-5 h-[100px]" />
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Sign in to GlastonBury
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-semibold text-gray-900 "
                >
                  Your email
                </label>

                <input
                  type="text"
                  name="email"
                  id="email"
                  className={`bg-gray-50 border ${
                    formik.errors.email && formik.touched.email
                      ? "border-red-500"
                      : "border-gray-300"
                  } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 `}
                  placeholder="name@company.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    className={`bg-gray-50 border ${
                      formik.errors.password && formik.touched.password
                        ? "border-red-500"
                        : "border-gray-300"
                    } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-[9px]"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <Eye className="w-5" />
                    ) : (
                      <EyeOff className="w-5" />
                    )}
                  </button>
                </div>
                {formik.errors.password && formik.touched.password && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <NavLink
                  to="/forgotpassword"
                  className="text-sm text-right w-full font-semibold text-primary-600 hover:underline"
                >
                  Forgot password?
                </NavLink>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
