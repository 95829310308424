import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/salesman";

/*---------- get salesman -------*/

const salesman = async (payload) => {
  const URL = `${URI}/getSalesman`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get salesman:", error);
    throw error;
  }
};

/*---------- active-deactive salesman -------*/

const salesmanStatus = async (payload) => {
  const URL = `${URI}/active-deactive`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred status salesman:", error);
    throw error;
  }
};

/*---------- pending to active salesman -------*/

const salesmanPendingtoActive = async (payload) => {
  const URL = `${URI}/activePendingSalesman`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred status salesman:", error);
    throw error;
  }
};

/*---------- delete salesman-------*/

const salesmanDelete = async (payload) => {
  const URL = `${URI}/delete`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete salesman:", error);
    throw error;
  }
};

/*---------- restore salesman-------*/

const salesmanRestore = async (payload) => {
  const URL = `${URI}/restore`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred restore salesman:", error);
    throw error;
  }
};

/*---------- get salesman detail -------*/

const getSalesmanDetail = async (payload) => {
  const URL = `${URI}/detail`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get salesman detail:", error);
    throw error;
  }
};

/*---------- update salesman detail -------*/
const updateSalesmanDetail = async (payload) => {
  const URL = `${URI}/update`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get salesman detail:", error);
    throw error;
  }
};

/*---------- add marchandiser-------*/

const addSalesmam = async (payload) => {
  const URL = `${URI}/add`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred create merchandiser:", error);
    throw error;
  }
};

/*---------- get filteredsalesman -------*/

const salesmanFilter = async (payload) => {
  const URL = `${URI}/getFilteredSalesman`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get filter salesman:", error);
    throw error;
  }
};

const salesmanService = {
  salesman,
  salesmanStatus,
  salesmanPendingtoActive,
  salesmanDelete,
  salesmanRestore,
  getSalesmanDetail,
  updateSalesmanDetail,
  addSalesmam,
  salesmanFilter,
};

export default salesmanService;
