import { createSlice } from "@reduxjs/toolkit";

export const citySlice = createSlice({
  name: "city",
  initialState: {
    cityLoading: false,
    cityMessage: "",
    cityError: "",
    city: [],
    cityActionLoading: false,
    cityActionMessage: "",
    cityActionError: "",
    cityActionCreateMessage: "",
    cityActionCreateError: "",
    setDefaultLoading: false,
    setDefaultMessage: "",
    setDefaultError: "",
  },
  reducers: {
    /*---------- Get-City -------*/

    cityRequest: (state) => {
      state.cityLoading = true;
      state.cityMessage = "";
      state.cityError = "";
      state.city = [];
    },
    citySuccess: (state, action) => {
      state.cityLoading = false;
      state.city = action.payload.response;
      state.cityMessage = action.payload.message;
    },
    cityError: (state, action) => {
      state.cityLoading = false;
      state.cityError = action.payload;
      state.cityMessage = "";
      state.city = [];
    },

    /*---------- Delete-City -------*/

    cityActionDeleteRequest: (state) => {
      state.cityActionLoading = true;
      state.cityActionMessage = "";
      state.cityActionError = "";
    },
    cityActionDeleteSuccess: (state, action) => {
      let { city } = state;
      let tempArray = city?.filter((citys) => citys.id !== action.payload.id);
      state.cityActionLoading = false;
      state.city = tempArray;
      state.cityActionMessage = action.payload.message;
    },
    cityActionDeleteError: (state, action) => {
      state.cityActionLoading = false;
      state.cityActionError = action.payload;
      state.cityActionMessage = "";
    },
    cityActionDeleteReset: (state) => {
      state.cityActionLoading = false;
      state.cityActionMessage = "";
      state.cityActionError = "";
    },

    /*---------- Create-City -------*/

    cityActionCreateRequest: (state) => {
      state.cityActionLoading = true;
      state.cityActionCreateMessage = "";
      state.cityActionCreateError = "";
    },
    cityActionCreateSuccess: (state, action) => {
      state.cityActionLoading = false;
      state.city.push(action.payload.city);
      state.cityActionCreateMessage = action.payload.message;
    },
    cityActionCreateError: (state, action) => {
      state.cityActionLoading = false;
      state.cityActionCreateError = action.payload;
      state.cityActionCreateMessage = "";
    },
    cityActionCreateReset: (state) => {
      state.cityActionLoading = false;
      state.cityActionMessage = "";
      state.cityActionCreateMessage = "";
    },

    /*---------- Update-City -------*/

    cityActionUpdateRequest: (state) => {
      state.cityActionLoading = true;
      state.cityActionMessage = "";
      state.cityActionError = "";
    },
    cityActionUpdateSuccess: (state, action) => {
      state.cityActionLoading = false;
      const index = state.city.findIndex(
        (citys) => citys.id === action.payload.city.id
      );
      if (index !== -1) {
        state.city[index] = action.payload.city;
      }
      state.cityActionMessage = action.payload.message;
    },
    cityActionUpdateError: (state, action) => {
      state.cityActionLoading = false;
      state.cityActionError = action.payload;
      state.cityActionMessage = "";
    },
    cityActionUpdateReset: (state) => {
      state.cityActionLoading = false;
      state.cityActionMessage = "";
      state.cityActionError = "";
    },

    // /*---------- set-default-city -------*/

    // setDefaultCityRequest: (state) => {
    //   state.setDefaultLoading = true;
    //   state.setDefaultMessage = "";
    //   state.setDefaultError = "";
    // },
    // setDefaultCitySuccess: (state, action) => {
    //   let {
    //     payload: { id },
    //   } = action;
    //   let { city } = state;
    //   state.setDefaultLoading = false;
    //   state.city = city.map((item) => {
    //     if (item.id === id) {
    //       return { ...item, is_default: item?.is_default === 0 ? 1 : 0 };
    //     }
    //     return { ...item, is_default: 0 };
    //   });
    //   state.setDefaultMessage = action.payload.message;
    // },
    // setDefaultCityError: (state, action) => {
    //   state.setDefaultLoading = false;
    //   state.setDefaultError = action.payload.message;
    //   state.setDefaultMessage = "";
    // },
  },
});

export const {
  cityRequest,
  citySuccess,
  cityError,
  cityActionDeleteRequest,
  cityActionDeleteSuccess,
  cityActionDeleteError,
  cityActionDeleteReset,
  cityActionCreateRequest,
  cityActionCreateSuccess,
  cityActionCreateError,
  cityActionCreateReset,
  cityActionUpdateRequest,
  cityActionUpdateSuccess,
  cityActionUpdateError,
  cityActionUpdateReset,
  // setDefaultCityError,
  // setDefaultCityRequest,
  // setDefaultCitySuccess,
} = citySlice.actions;

export default citySlice.reducer;
