import React, { useState } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPassword,
  newPassword,
  oldPassword,
} from "../../../helpers/yup.validation.schema";
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { changePassword } from "../../../middlewares/adminProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { Eye, EyeOff } from "lucide-react";

function ChangePass() {
  const { changePassAdminMsg } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    if (changePassAdminMsg) {
      ToastService.success(changePassAdminMsg);
    }
  }, [changePassAdminMsg]);

  // ---------------------- Password hide show --------------------------------

  const [showField, setShowField] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const handleVisiblityChange = (field) => {
    let tempObj = JSON.parse(JSON.stringify(showField));
    tempObj[`${field}`] = !showField[`${field}`];
    setShowField(tempObj);
  };

  const handleSubmit = (values) => {
    const payload = {
      oldPassword: values.old_password,
      newPassword: values.password,
    };
    dispatch(changePassword(payload));
    formik.resetForm();
  };

  const changePassSchema = Yup.object({
    old_password: oldPassword,
    password: newPassword,
    confirm_password: confirmPassword,
  });

  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePassSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div>
        <ToastContainer />
        <BreadCrumb title="Change Password" pageTitle={"Dashboard"} />
        <div className="mt-5 mx-5">
          <div className="profile-form">
            <div className="bg-white p-5 rounded-lg">
              <form onSubmit={formik.handleSubmit}>
                <div className="p-5 py-2">
                  <FormControl className="w-full">
                    <label className="font-medium">Old Password</label>
                    <OutlinedInput
                      type={showField.oldPassword ? "text" : "password"}
                      name="old_password"
                      id="old_password"
                      placeholder="Enter Old Password"
                      className="rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.old_password}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => handleVisiblityChange("oldPassword")}
                        >
                          {showField.oldPassword ? <Eye /> : <EyeOff />}
                        </InputAdornment>
                      }
                    />
                    {formik.errors.old_password &&
                    formik.touched.old_password ? (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.old_password}
                      </div>
                    ) : null}
                  </FormControl>
                </div>
                <div className="p-5 py-2">
                  <FormControl className="w-full">
                    <label className="font-medium">New Password</label>
                    <OutlinedInput
                      type={showField.newPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter New Password"
                      className="rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => handleVisiblityChange("newPassword")}
                        >
                          {showField.newPassword ? <Eye /> : <EyeOff />}
                        </InputAdornment>
                      }
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </FormControl>
                </div>
                <div className="p-5 py-2">
                  <FormControl className="w-full">
                    <label className="font-medium">Confirm Password</label>
                    <OutlinedInput
                      type={showField.confirmPassword ? "text" : "password"}
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Enter Confirm Password"
                      className="rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirm_password}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            handleVisiblityChange("confirmPassword")
                          }
                        >
                          {showField.confirmPassword ? <Eye /> : <EyeOff />}
                        </InputAdornment>
                      }
                    />
                    {formik.errors.confirm_password &&
                    formik.touched.confirm_password ? (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.confirm_password}
                      </div>
                    ) : null}
                  </FormControl>
                </div>
                <div className="ml-5 mt-5">
                  <Button
                    type="submit"
                    variant="outlined"
                    className="updatebtn capitalize "
                  >
                    Update
                  </Button>
                </div>
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePass;
