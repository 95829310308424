import React from "react";
import { useDispatch } from "react-redux";
import { Switch } from "@mui/material";
import { setCountry } from "../../../middlewares/Country";
// import { setCity } from "../../../middlewares/City";
// import { setProvince } from "../../../middlewares/Province";

const BlockAccount = ({ row, type }) => {
  const dispatch = useDispatch();

  const handleChange = () => {
    if (type === "Country") {
      dispatch(setCountry({ id: row.id }));
    }
    // if (type === "Province") {
    //   dispatch(setProvince({ id: row.id }));
    // }
    // if (type === "City") {
    //   dispatch(setCity({ id: row.id }));
    // }
  };
  return (
    <Switch
      onChange={handleChange}
      checked={row?.is_default === 1}
      disabled={row?.is_default === 1}
    />
  );
};

export default BlockAccount;
