import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/requests";

/*---------- get request -------*/

const getreq = async (payload) => {
  const URL = `${URI}/get`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get request:", error);
    throw error;
  }
};

/*---------- get request detail -------*/

const getreqdetail = async (payload) => {
  const URL = `${URI}/detail`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get request detail:", error);
    throw error;
  }
};

/*---------- create marchant-------*/

const assignRequest = async (payload) => {
  const URL = `${URI}/assign-request`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred request assign:", error);
    throw error;
  }
};

/*----------swap request-------*/

const swapRequest = async (payload) => {
  const URL = `${URI}/swapRequest`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred swap request:", error);
    throw error;
  }
};

/*----------swap request for merchant -------*/

const swapRequestForMerchant = async (payload) => {
  const URL = `${URI}/swapMerchantRequest`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred swap request:", error);
    throw error;
  }
};

const requestService = {
  assignRequest,
  getreq,
  getreqdetail,
  swapRequest,
  swapRequestForMerchant
};

export default requestService;
