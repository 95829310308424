import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormControl, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProvince } from "../../middlewares/Province";
import { CityCreate, CityUpdate } from "../../middlewares/City";
import { Cityname, selectProvince } from "../../helpers/yup.validation.schema";
import ToastService from "../../helpers/toast-services";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { cityActionCreateReset } from "../../slices/City";
import BreadCrumb from "../../component/common/BreadCrumb";

function AddCity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdated, setIsUpdated] = useState(false);

  const cityUpdateId = state?.id || null;
  const provinceUpdateId = state?.provinceId || null;
  const cityUpdateName = state?.name || null;

  useEffect(() => {
    if (!cityUpdateId) {
      dispatch(getProvince());
    }
    if (cityUpdateId) {
      setIsUpdated(true);
      dispatch(getProvince());
    }
  }, [dispatch, cityUpdateId]);

  const { cityActionCreateMessage, cityActionCreateError } = useSelector(
    (state) => state.city
  );

  const { province } = useSelector((state) => state.province);

  useEffect(() => {
    if (cityActionCreateMessage) {
      ToastService.success(cityActionCreateMessage);
    }
    if (cityActionCreateError) {
      ToastService.error(cityActionCreateError);
    }
    dispatch(cityActionCreateReset());
  }, [cityActionCreateMessage, cityActionCreateError, dispatch]);

  const AddCitySchema = Yup.object({
    province: selectProvince,
    Cityname: Cityname,
  });

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      name: values.Cityname,
      province_id: values.province,
      is_visible: 1,
      is_default: 0,
    };

    if (isUpdated) {
      navigate("/admin/location/cities");
      dispatch(CityUpdate({ ...payload, id: cityUpdateId }));
    } else {
      dispatch(CityCreate(payload));
    }
    resetForm(); // Reset the form after submission
  };

  const formik = useFormik({
    initialValues: {
      province: isUpdated ? provinceUpdateId : "",
      Cityname: isUpdated ? cityUpdateName : "",
    },
    validationSchema: AddCitySchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="province-page">
      <BreadCrumb
        title={isUpdated ? "Edit City" : "Add City"}
        pageTitle={"Dashboard"}
      />
      <ToastContainer />
      <div className="bg-white my-5 mt-5 p-7 rounded-lg">
        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label htmlFor="language" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Province
              </label>
              <select
                name="province"
                id="province"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.province} // Ensure this is correctly set
                defaultValue={formik.values.province} // Add defaultValue when isUpdated is true
                className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
              >
                <option value="" disabled hidden>
                  Select Province
                </option>
                {province?.map((provinces) => (
                  <option key={provinces.id} value={provinces.id}>
                    {provinces.name}
                  </option>
                ))}
              </select>
              {formik.errors.province && formik.touched.province && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.province}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full" variant="outlined">
              <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>City Name</label>
              <OutlinedInput
                placeholder="Enter City Name"
                name="Cityname"
                id="Cityname"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Cityname}
              />
              {formik.errors.Cityname && formik.touched.Cityname && (
                <Box className="text-red-500 text-sm mt-1">
                  {formik.errors.Cityname}
                </Box>
              )}
            </FormControl>
          </Box>

          <Box className="flex items-end justify-end gap-4 text-end ">
            <Button
              type="submit"
              className="text-white button-style capitalize"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AddCity;
