import cityService from "../services/City";
import {
  cityActionCreateError,
  cityActionCreateRequest,
  cityActionCreateSuccess,
  cityActionDeleteError,
  cityActionDeleteRequest,
  cityActionDeleteSuccess,
  cityActionUpdateError,
  cityActionUpdateRequest,
  cityActionUpdateSuccess,
  cityError,
  cityRequest,
  citySuccess,
} from "../slices/City";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- get country -------*/
export function getCity(payload) {
  return (dispatch) => {
    dispatch(cityRequest());
    cityService
      .city(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(citySuccess({ ...data, message }));
        } else {
          dispatch(cityError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(cityError(error));
      });
  };
}

/*---------- Delete country -------*/
export function cityDelete(payload) {
  return (dispatch) => {
    dispatch(cityActionDeleteRequest());
    cityService
      .cityDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(cityActionDeleteSuccess({ id, message }));
        } else {
          dispatch(cityActionDeleteError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(cityActionDeleteError(error));
      });
  };
}

/*---------- Create City -------*/

export function CityCreate(payload) {
  return (dispatch) => {
    dispatch(cityActionCreateRequest());
    cityService
      .cityCreate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(cityActionCreateSuccess({ id, message }));
        } else {
          dispatch(cityActionCreateError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(cityActionCreateError(error));
      });
  };
}

/*---------- Update City -------*/

export function CityUpdate(payload) {
  return (dispatch) => {
    dispatch(cityActionUpdateRequest());
    cityService
      .cityUpdate(payload)
      .then((data) => {
        const { message, status } = data;
        let { id } = payload;
        if (status === 1) {
          dispatch(cityActionUpdateSuccess({ id, message }));
        } else {
          dispatch(cityActionUpdateError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(cityActionUpdateError(error));
      });
  };
}
