import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Grid, Typography, Box } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { getAdminDash } from "../../middlewares/adminProfile";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../component/common/BreadCrumb";
import ModelSalesman from "../../component/tableActionbar/salesman/modelSalesman";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAdminDash());
  }, [dispatch]);
  const { getAdminDashData, adminDashLoading } = useSelector(
    (state) => state.admin
  );

  const [data, setData] = useState(null);

  const BgColorObj = {
    active: "#f1faff",
    pending: "#fff8dd",
    delete: "#fff5f8",
  };

  useEffect(() => {
    setData(getAdminDashData);
  }, [getAdminDashData]);

  const ColorObj = {
    active: "#009ef7",
    pending: "#ffc700",
    delete: "#f1416c",
  };

  const renderCard = (title, count, type = "active", slug) => (
    <Grid
      item
      sm={12}
      md={4}
      sx={{ marginBottom: "20px", display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          padding: "20px",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "space-between",
          flex: 1,
          alignItems: "center",
          backgroundColor: BgColorObj[type],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h2" color={`${ColorObj[type]}`}>
            {count}
          </Typography>
          <Typography variant="h6" color={`${ColorObj[type]}`}>
            {title}
          </Typography>
        </Box>
        <IconButton
          size="large"
          className={`border border-white text-[${BgColorObj[type]}]`}
          onClick={() => navigate(`/admin/${slug}`)}
        >
          <ArrowForwardIos
            fontSize="large"
            className={
              type === "active"
                ? `text-[#009ef7]`
                : type === "pending"
                ? `text-[#ffc700]`
                : `text-[#f1416c]`
            }
          />
        </IconButton>
      </Box>
    </Grid>
  );

  return (
    <div className="Dashboard-page">
      <BreadCrumb title="Dashboard" pageTitle={"Dashboard"} />
      {!adminDashLoading ? (
        <div>
          <div
            className="bg-white mx-5 rounded-lg"
            style={{ marginBottom: 25, marginTop: 25 }}
          >
            <Card raised className="rounded-lg">
              <ModelSalesman />
              <CardHeader
                sx={{ padding: "0 !important" }}
                title={
                  <div className="main-container-header text-xl font-bold p-5 pr-5">
                    Merchandiser
                  </div>
                }
              />

              <CardContent>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "0px", padding: "20px" }}
                >
                  {renderCard(
                    "Active Merchandiser",
                    data?.salesmen?.active,
                    "active",
                    "merchandiser/active"
                  )}
                  {renderCard(
                    "Pending Merchandiser",
                    data?.salesmen?.pending,
                    "pending",
                    "merchandiser/pending"
                  )}
                  {renderCard(
                    "Deleted Merchandiser",
                    data?.salesmen?.deleted,
                    "delete",
                    "merchandiser/delete"
                  )}
                </Grid>
              </CardContent>
            </Card>
          </div>
          <div
            className="bg-white mx-5 rounded-lg"
            style={{ marginBottom: 25 }}
          >
            <Card raised className="border rounded-lg">
              <CardHeader
                sx={{ padding: "0 !important" }}
                title={
                  <div className="main-container-header text-xl font-bold p-5 pr-5">
                    Merchant
                  </div>
                }
              />
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "0px", padding: "20px" }}
                >
                  {renderCard(
                    "Active Merchant",
                    data?.merchants?.active,
                    "active",
                    "merchant/active"
                  )}
                  {renderCard(
                    "Inactive Merchant",
                    data?.merchants?.pending,
                    "pending",
                    "merchant/Inactive"
                  )}
                  {renderCard(
                    "Deleted Merchant",
                    data?.merchants?.deleted,
                    "delete",
                    "merchant/delete"
                  )}
                </Grid>
              </CardContent>
            </Card>
          </div>
          <div
            className="bg-white mx-5 rounded-lg"
            style={{ marginBottom: 25 }}
          >
            <Card raised className="border rounded-lg">
              <CardHeader
                sx={{ padding: "0 !important" }}
                title={
                  <div className="main-container-header text-xl font-bold p-5 pr-5">
                    Request
                  </div>
                }
              />
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "0px", padding: "20px" }}
                >
                  {renderCard(
                    "Completed Request",
                    data?.requests?.completed,
                    "active",
                    "request/completed"
                  )}
                  {renderCard(
                    "Pending Request",
                    data?.requests?.pending,
                    "pending",
                    "request/pending"
                  )}
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      ) : (
        <div className="bg-white mt-6 rounded-lg p-5 text-center">
          <CircularProgress className="bg-white" />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
