import {
  GitPullRequestArrow,
  House,
  ListCheck,
  MapPinned,
  Settings,
  Users,
} from "lucide-react";

const menuData = [
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: <House />,
  },
  {
    id: "merchandiser",
    label: "Merchandiser",
    icon: <Users />,
    subItems: [
      {
        id: "pendingmerchandiser",
        label: "Pending",
        link: "/admin/merchandiser/pending",
        parentId: "merchandiser",
      },
      {
        id: "activemerchandiser",
        label: "Active",
        link: "/admin/merchandiser/active",
        parentId: "merchandiser",
      },
      {
        id: "inactivemerchandiser",
        label: "Inactive",
        link: "/admin/merchandiser/Inactive",
        parentId: "merchandiser",
      },
      {
        id: "deletedmerchandiser",
        label: "Deleted",
        link: "/admin/merchandiser/delete",
        parentId: "merchandiser",
      },
    ],
  },
  {
    id: "merchant",
    label: "Merchant",
    icon: <Users />,
    subItems: [
      {
        id: "activemerchandiser",
        label: "Active",
        link: "/admin/merchant/active",
        parentId: "merchant",
      },
      {
        id: "pendingmerchant",
        label: "Inactive",
        link: "/admin/merchant/Inactive",
        parentId: "merchant",
      },
      {
        id: "deletedmerchant",
        label: "Deleted",
        link: "/admin/merchant/delete",
        parentId: "merchant",
      },
    ],
  },
  {
    id: "request",
    label: "Request",
    icon: <GitPullRequestArrow />,
    subItems: [
      {
        id: "assignreq",
        label: "Assign",
        link: "/admin/request/assign",
        parentId: "request",
      },
      {
        id: "pendingreq",
        label: "Pending",
        link: "/admin/request/pending",
        parentId: "request",
      },
      {
        id: "completedreq",
        label: "Completed",
        link: "/admin/request/completed",
        parentId: "request",
      },
      {
        id: "notcompleted",
        label: "Not Completed",
        link: "/admin/request/notcompleted",
        parentId: "request",
      },
    ],
  },
  {
    id: "location",
    label: "Location Management",
    icon: <MapPinned />,
    subItems: [
      {
        id: "countries",
        label: "Countries",
        link: "/admin/location/countries",
        parentId: "location",
      },
      {
        id: "provinces",
        label: "Provinces",
        link: "/admin/location/provinces",
        parentId: "location",
      },
      {
        id: "cities",
        label: "Cities",
        link: "/admin/location/cities",
        parentId: "location",
      },
      {
        id: "region",
        label: "Region",
        link: "/admin/location/Region",
        parentId: "location",
      },
    ],
  },
  {
    id: "cms",
    label: "CMS",
    icon: <ListCheck />,
    subItems: [
      {
        id: "terms",
        label: "Terms & Conditions",
        link: "/admin/cms/terms",
        parentId: "cms",
      },
      {
        id: "privacy",
        label: "Privacy & Policy",
        link: "/admin/cms/privacy",
        parentId: "cms",
      },
    ],
  },
  {
    id: "setting",
    label: "Settings",
    icon: <Settings />,
    link: "/admin/setting",
    parentId: 2,
  },
];

export { menuData };
