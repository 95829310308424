import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/auth";

const login = async (payload) => {
  const URL = `${URI}/login`;
  try {
    const response = await axios.post(URL, payload);
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

/*---------- Update  -------*/
const forgot = async (payload) => {
  const URL = `${URI}/forgotPassword`;

  try {
    const response = await axios.post(URL, payload);
    return response.data;
  } catch (error) {
    console.error("Error occurred during forgot:", error);
    throw error;
  }
};

const AuthService = {
  login,
  forgot,
};

export default AuthService;
