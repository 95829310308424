import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReq } from "../../../middlewares/request";
import { useEffect } from "react";
import RequestActionBar from "../../../component/tableActionbar/request";
import BreadCrumb from "../../../component/common/BreadCrumb";
import TableContainer from "../../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../../component/common/CustomLoader";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import blankSalesman from "../../../assets/Images/blank.png";
import { useFormik } from "formik";
import Select from "react-select";
import { getSalesman } from "../../../middlewares/salesman";
import { getMerchant } from "../../../middlewares/merchant";
import formatDate from "../../../utlils/formatDate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CompletedRequest() {
  const dispatch = useDispatch();
  const [requestToDisplay, setrequestToDisplay] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const { requests, getRequestLoading } = useSelector((state) => state.request);

  useEffect(() => {
    dispatch(
      getReq({
        type: "completed",
        date: "",
      })
    );
    dispatch(
      getSalesman({
        type: "active",
      })
    );
    dispatch(
      getMerchant({
        type: "active",
      })
    );
  }, [dispatch]);
  const { salesman } = useSelector((state) => state.salesman);
  const { merchant } = useSelector((state) => state.merchant);
  const handleSubmit = (values) => {
    const payload = {
      type: "completed",
    };
    if (values.salesman) {
      payload.salesmanId = values.salesman;
    }
    if (values.merchant) {
      payload.merchantId = values.merchant;
    }
    if (values.search) {
      payload.search = values.search;
    }
    payload.date = startDate ? formatDate(startDate) : "";
    dispatch(getReq(payload));
  };

  const formik = useFormik({
    initialValues: {
      salesman: "",
      merchant: "",
      search: "",
    },
    onSubmit: handleSubmit,
  });

  const MerchantOptions =
    merchant?.map((mer) => ({
      label: mer.fullName,
      value: mer.id,
    })) || [];

  const SalesmanOptions =
    salesman?.map((mer) => ({
      label: mer.fullName,
      value: mer.id,
    })) || [];

  useEffect(() => {
    setrequestToDisplay(requests);
  }, [requests]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Merchandiser image",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover "
              src={
                row.original.salesman.profileImage
                  ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${row.original.salesman.profileImage}`
                  : blankSalesman
              }
              alt={row.original.salesman.fullName}
            />
          </Box>
        ),
      },
      {
        header: "Merchandiser Name",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">
              {row.original.salesman.fullName}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Merchandiser Email",
        // accessorKey: "languageCode",
        enableColumnFilter: false,
        // enableSorting: true,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">{row.original.salesman.email}</Typography>
          </Box>
        ),
      },
      {
        header: "Merchant image",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover "
              src={
                row.original.merchant.profileImage
                  ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${row.original.merchant.profileImage}`
                  : blankSalesman
              }
              alt={row.original.merchant.fullName}
            />
          </Box>
        ),
      },
      {
        header: "Merchant Name",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">
              {row.original.merchant.fullName}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Merchant Email",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <Typography variant="p">{row.original.merchant.email}</Typography>
          </Box>
        ),
      },
      {
        header: "Merchant Address",
        enableColumnFilter: false,
        cell: ({ row }) => {
          let cityName = row.original.merchant.city?.name;
          let provinceName = row.original.merchant.city?.province?.name;
          let countryName = row.original.merchant.city?.province?.country?.name;
          let locationArray = [];
          if (cityName) {
            locationArray?.push(cityName);
          }
          if (provinceName) {
            locationArray?.push(provinceName);
          }
          if (countryName) {
            locationArray?.push(countryName);
          }
          return (
            <Box className="flex items-center">
              <Typography variant="p">{locationArray.join(",")}</Typography>
            </Box>
          );
        },
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <RequestActionBar row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="completedrequest-page">
      <BreadCrumb title="Completed Request" pageTitle={"Dashboard"} />
      <div className="card bg-white mt-5 p-3">
        {!getRequestLoading ? (
          <Box>
            <Box className="mb-2">
              <form
                className="space-y-4 md:space-y-6"
                onSubmit={formik.handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item sm={12} md={3}>
                    <Box>
                      <FormControl className="w-full" variant="outlined">
                        <label
                          className="mb-2 font-semibold"
                          sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                        >
                          Search
                        </label>
                        <input
                          type="text"
                          placeholder="search..."
                          name="search"
                          id="search"
                          className="form-input px-2 p-[7px] rounded border-slate-200 border focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300  disabled:text-slate-500 placeholder:text-slate-400"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.search}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Box>
                      <FormControl className="w-full" variant="outlined">
                        <label
                          className="mb-2 font-semibold"
                          sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                        >
                          Merchandiser
                        </label>
                        <Select
                          className="select-css custom-select-container"
                          classNamePrefix="react-select"
                          data-choices
                          isClearable
                          name="salesman"
                          placeholder="Select a merchandiser"
                          options={SalesmanOptions}
                          value={SalesmanOptions.find(
                            (option) => option.value === formik.values.salesman
                          )}
                          onChange={(selectedOption) =>
                            formik.setFieldValue(
                              "salesman",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                        />
                        {formik.errors.salesman && formik.touched.salesman && (
                          <Box className="text-red-500 text-sm mt-1">
                            {formik.errors.salesman}
                          </Box>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Box>
                      <FormControl className="w-full" variant="outlined">
                        <label
                          className="mb-2 font-semibold"
                          sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                        >
                          Merchant
                        </label>
                        <Select
                          className="select-css custom-select-container"
                          classNamePrefix="react-select"
                          data-choices
                          isClearable
                          name="merchant"
                          placeholder="Select a merchant"
                          options={MerchantOptions}
                          value={MerchantOptions.find(
                            (option) => option.value === formik.values.merchant
                          )}
                          onChange={(selectedOption) =>
                            formik.setFieldValue(
                              "merchant",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                        />
                        {formik.errors.merchant && formik.touched.merchant && (
                          <Box className="text-red-500 text-sm mt-1">
                            {formik.errors.merchant}
                          </Box>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <Box className="flex flex-col">
                      <label
                        className="mb-2 font-semibold"
                        sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                      >
                        Date
                      </label>
                      <DatePicker
                        selected={startDate}
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => setStartDate(date)}
                        className="border py-2 w-full px-3 rounded focus:outline-none focus:border"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} className="flex items-end">
                    <Box className="mb-[3px]">
                      <Button
                        type="submit"
                        className="text-white button-style capitalize"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <div className="card-body ">
              <TableContainer
                isPagination={true}
                isSelect={true}
                // isGlobalFilter={true}
                columns={columns}
                data={requestToDisplay}
                customPageSize={10}
                divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
                tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
                theadclassName="border-b border-slate-200"
                trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
                thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
                tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            </div>
          </Box>
        ) : (
          <CustomLoader />
        )}
      </div>
    </div>
  );
}

export default CompletedRequest;
