import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/importData";

const bulkUploadMerchant = async (payload) => {
  const URL = `${URI}/importMerchantData`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred  bulk upload:", error);
    throw error;
  }
};

const bulkUploadRequest = async (payload) => {
  const URL = `${URI}/importRequestData`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred bulk upload:", error);
    throw error;
  }
};

const bulkService = {
  bulkUploadMerchant,
  bulkUploadRequest,
};

export default bulkService;
