import { createSlice } from "@reduxjs/toolkit";

export const provinceSlice = createSlice({
  name: "province",
  initialState: {
    provinceLoading: false,
    provinceMessage: "",
    provinceError: "",
    province: [],
    provinceActionLoading: false,
    provinceActionMessage: "",
    provinceActionError: "",
    provinceActionCreateMessage: "",
    provinceActionCreateError: "",
    setDefaultLoading: false,
    setDefaultMessage: "",
    setDefaultError: "",
  },
  reducers: {
    /*---------- Get-Province -------*/

    provinceRequest: (state) => {
      state.provinceLoading = true;
      state.provinceMessage = "";
      state.provinceError = "";
      state.province = [];
    },
    provinceSuccess: (state, action) => {
      state.provinceLoading = false;
      state.province = action.payload.response;
      state.provinceMessage = "Get province successfully!";
    },
    provinceError: (state, action) => {
      state.provinceLoading = false;
      state.provinceError = action.payload;
      state.provinceMessage = "";
      state.province = [];
    },

    /*---------- Delete-Province -------*/

    provinceActionDeleteRequest: (state) => {
      state.provinceActionLoading = true;
      state.provinceActionMessage = "";
      state.provinceActionError = "";
    },
    provinceActionDeleteSuccess: (state, action) => {
      let { province } = state;
      let tempArray = province?.filter(
        (provinces) => provinces.id !== action.payload.id
      );
      state.provinceActionLoading = false;
      state.province = tempArray;
      state.provinceActionMessage = action.payload.message;
    },
    provinceActionDeleteError: (state, action) => {
      state.provinceActionLoading = false;
      state.provinceActionError = action.payload;
      state.provinceActionMessage = "";
    },
    provinceActionDeleteReset: (state) => {
      state.provinceActionLoading = false;
      state.provinceActionMessage = "";
      state.provinceActionError = "";
    },

    /*---------- Create-Province -------*/

    provinceActionCreateRequest: (state) => {
      state.provinceActionLoading = true;
      state.provinceActionCreateMessage = "";
      state.provinceActionCreateError = "";
    },
    provinceActionCreateSuccess: (state, action) => {
      state.provinceActionLoading = false;
      state.province.push(action.payload.province);
      state.provinceActionCreateMessage = action.payload.message;
    },
    provinceActionCreateError: (state, action) => {
      state.provinceActionLoading = false;
      state.provinceActionCreateError = action.payload;
      state.provinceActionCreateMessage = "";
    },
    provinceActionCreateReset: (state) => {
      state.provinceActionLoading = false;
      state.provinceActionMessage = "";
      state.provinceActionCreateMessage = "";
    },

    /*---------- Update-Province -------*/

    provinceActionUpdateRequest: (state) => {
      state.provinceActionLoading = true;
      state.provinceActionMessage = "";
      state.provinceActionError = "";
    },
    provinceActionUpdateSuccess: (state, action) => {
      state.provinceActionLoading = false;
      const index = state.province.findIndex(
        (provinces) => provinces.id === action.payload.province.id
      );
      if (index !== -1) {
        state.province[index] = action.payload.province;
      }
      state.provinceActionMessage = action.payload.message;
    },
    provinceActionUpdateError: (state, action) => {
      state.provinceActionLoading = false;
      state.provinceActionError = action.payload;
      state.provinceActionMessage = "";
    },
    provinceActionUpdateReset: (state) => {
      state.provinceActionLoading = false;
      state.provinceActionMessage = "";
      state.provinceActionError = "";
    },

    // /*---------- set-default-Province -------*/

    // setDefaultProvinceRequest: (state) => {
    //   state.setDefaultLoading = true;
    //   state.setDefaultMessage = "";
    //   state.setDefaultError = "";
    // },
    // setDefaultProvinceSuccess: (state, action) => {
    //   let {
    //     payload: { id },
    //   } = action;
    //   let { province } = state;
    //   state.setDefaultLoading = false;
    //   state.province = province.map((item) => {
    //     if (item.id === id) {
    //       return { ...item, is_default: item?.is_default === 0 ? 1 : 0 };
    //     }
    //     return { ...item, is_default: 0 };
    //   });
    //   state.setDefaultMessage = action.payload.message;
    // },
    // setDefaultProvinceError: (state, action) => {
    //   state.setDefaultLoading = false;
    //   state.setDefaultError = action.payload.message;
    //   state.setDefaultMessage = "";
    // },
  },
});

export const {
  provinceRequest,
  provinceSuccess,
  provinceError,
  provinceActionDeleteRequest,
  provinceActionDeleteSuccess,
  provinceActionDeleteError,
  provinceActionDeleteReset,
  provinceActionCreateRequest,
  provinceActionCreateSuccess,
  provinceActionCreateError,
  provinceActionCreateReset,
  provinceActionUpdateRequest,
  provinceActionUpdateSuccess,
  provinceActionUpdateError,
  provinceActionUpdateReset,
  // setDefaultProvinceError,
  // setDefaultProvinceRequest,
  // setDefaultProvinceSuccess,
} = provinceSlice.actions;

export default provinceSlice.reducer;
