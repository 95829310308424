import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ title, pageTitle }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col gap-2 py-4 md:flex-row md:items-center print:hidden">
        <div className="grow">
          <h5 className="text-16 font-bold text-black">{title}</h5>
        </div>
        <ul className="flex items-center gap-2 text-sm font-normal shrink-0">
          <li className="relative before:content-['\ea54'] before:font-remix before:-right-1 before:absolute before:text-[18px] before:-top-[3px] pr-4 before:text-slate-400 ">
            <Link to="/admin/dashboard" className="text-[#34383d] ">
              {pageTitle}
            </Link>
          </li>
          <li className="text-[#74788d] ">{title}</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BreadCrumb;
