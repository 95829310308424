import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../slices/auth";
import { adminSlice } from "../slices/adminProfile";
import { salesmanSlice } from "../slices/salesman";
import { merchantSlice } from "../slices/merchant";
import { countrySlice } from "../slices/Country";
import { provinceSlice } from "../slices/Province";
import { citySlice } from "../slices/City";
import { requestSlice } from "../slices/request";
import { cmsSlice } from "../slices/admin/cms/cms.slice";
import { layoutSlice } from "../slices/layout";
import { resetpassSlice } from "../slices/resetPassApp";
import { regionSlice } from "../slices/region";

function configStore() {
  const store = configureStore({
    reducer: {
      auth: authSlice.reducer,
      admin: adminSlice.reducer,
      salesman: salesmanSlice.reducer,
      merchant: merchantSlice.reducer,
      country: countrySlice.reducer,
      province: provinceSlice.reducer,
      city: citySlice.reducer,
      request: requestSlice.reducer,
      cms: cmsSlice.reducer,
      layout: layoutSlice.reducer,
      resetpass: resetpassSlice.reducer,
      region: regionSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
  return store;
}

export default configStore;
