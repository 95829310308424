import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { getSingleAdmin, updateAdmin } from "../../../middlewares/adminProfile";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import { email } from "../../../helpers/yup.validation.schema";
import BreadCrumb from "../../../component/common/BreadCrumb";

function Profile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleAdmin());
  }, [dispatch]);

  const { getAdmin, updateAdminMsg, adminLoading } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    if (updateAdminMsg) {
      ToastService.success(updateAdminMsg);
    }
  }, [updateAdminMsg]);

  const handleSubmit = (values) => {
    const payload = {
      name: values.name,
      email: values.email,
    };
    dispatch(updateAdmin(payload));
  };
  const initialValues = {
    name: getAdmin?.response.name ?? "",
    email: getAdmin?.response.email ?? "",
  };

  const profilevalidation = Yup.object({
    name: Yup.string().required("Admin name is required"),
    email: email,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: profilevalidation,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div>
        <ToastContainer />
        <BreadCrumb title="Profile" pageTitle={"Dashboard"} />
        <div className="mt-5 mx-5">
          <div className="profile-form">
            <div className="bg-white p-5 rounded-lg">
              <Box className="text-center">
                {!adminLoading ? (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 ">
                      <div className="p-5 py-2">
                        <FormControl className="w-full">
                          <label className="font-medium text-left"> Name</label>
                          <OutlinedInput
                            type="text"
                            name="name"
                            id="name"
                            placeholder="abc"
                            className={`rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.errors.name && formik.touched.name ? (
                            <div className="text-red-500 text-start text-sm mt-1">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </FormControl>
                      </div>
                      <div className="p-5 py-2">
                        <FormControl className="w-full">
                          <label className="font-medium text-left">
                            {" "}
                            Email
                          </label>
                          <OutlinedInput
                            type="email"
                            name="email"
                            id="email"
                            className={`rounded-lg border-solid border border-zinc-400 mt-2 w-full rounded-m`}
                            $
                            {...(formik.errors.email && formik.touched.email
                              ? "border-red-500"
                              : "border-gray-300")}
                            placeholder="ABC@gmail.com"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            // required
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <div className="text-red-500 text-start text-sm mt-1">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </FormControl>
                      </div>
                      <div className="ml-5 mt-5 text-left">
                        <Button
                          type="submit"
                          variant="outlined"
                          className="updatebtn capitalize"
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
