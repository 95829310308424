import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ToastService from "../../helpers/toast-services";
import { getCity } from "../../middlewares/City";
import { cityActionDeleteReset } from "../../slices/City";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../component/common/CustomLoader";
import { Button, Typography } from "@mui/material";
import BreadCrumb from "../../component/common/BreadCrumb";
import CityActions from "../../component/tableActionbar/location/CityActions";
// import DefaultLocationSwitch from "../../component/tableActionbar/location/DefaultLocationSwitch";

function City() {
  const dispatch = useDispatch();
  const [cityToDisplay, setcityToDisplay] = useState([]);
  const { city, cityLoading, cityActionMessage, cityActionError } = useSelector(
    (state) => state.city
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCity());
  }, [dispatch]);

  useEffect(() => {
    setcityToDisplay(city);
  }, [city]);

  useEffect(() => {
    if (cityActionMessage) {
      ToastService.success(cityActionMessage);
    }
    if (cityActionError) {
      ToastService.error(cityActionError);
    }
    dispatch(cityActionDeleteReset());
  }, [cityActionMessage, cityActionError, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <CityActions row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="city-page">
      <BreadCrumb title="Cities" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!cityLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={cityToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search City"
              extraButton={
                <Button
                  className="text-white button-style capitalize"
                  variant="contained"
                  sx={{ marginLeft: "10px !important" }}
                  onClick={() => navigate(`/admin/location/cities/add`)}
                >
                  Add city
                </Button>
              }
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default City;
