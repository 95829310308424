import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/location";

/*---------- Get Province -------*/
const province = async (payload) => {
  const URL = `${URI}/get-province`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get province:", error);
    throw error;
  }
};

/*---------- Delete Province -------*/
const provinceDelete = async (payload) => {
  const URL = `${URI}/delete-province`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete salesman:", error);
    throw error;
  }
};

/*---------- Create Province -------*/
const provinceCreate = async (payload) => {
  const URL = `${URI}/create-province`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create province:", error);
    throw error;
  }
};

/*---------- Update Province -------*/
const provinceUpdate = async (payload) => {
  const URL = `${URI}/update-province`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create province:", error);
    throw error;
  }
};

// /*---------- set Province -------*/
// const setDefaultProvince = async (payload) => {
//   const URL = `${URI}/set-default-province`;

//   try {
//     let user = localStorage.getItem("user-details");
//     let token = JSON.parse(user).token;

//     const response = await axios.post(URL, payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log("Error occurred set default province:", error);
//     throw error;
//   }
// };

const provinceService = {
  province,
  provinceDelete,
  provinceCreate,
  provinceUpdate,
  // setDefaultProvince,
};

export default provinceService;
