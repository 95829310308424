import ResetPassService from "../services/resetPassApp";
import {
  resetpassError,
  resetpassSuccess,
  resetpassRequest,
} from "../slices/resetPassApp";
//------------ reset password App -----------
export function resetUserPassword(payload) {
  return (dispatch) => {
    dispatch(resetpassRequest());
    ResetPassService.reset(payload)
      .then((data) => {
        const { responce, message, status } = data;
        if (status === 1) {
          dispatch(resetpassSuccess({ responce, message }));
        } else {
          dispatch(resetpassError({ message }));
        }
      })
      .catch((error) => {
        dispatch(resetpassError(error?.response?.data?.message));
      });
  };
}

//------------ reset password Admin -----------
export function resetPasswordAdmin(payload) {
  return (dispatch) => {
    dispatch(resetpassRequest());
    ResetPassService.AdminResetPassWord(payload)
      .then((data) => {
        const { responce, message, status } = data;
        if (status === 1) {
          dispatch(resetpassSuccess({ responce, message }));
        } else {
          dispatch(resetpassError({ message }));
        }
      })
      .catch((error) => {
        dispatch(resetpassError(error?.response?.data?.message));
      });
  };
}
