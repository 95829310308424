import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getProvince } from "../../middlewares/Province";
import { provinceActionDeleteReset } from "../../slices/Province";
import ToastService from "../../helpers/toast-services";
import { useNavigate } from "react-router-dom";
import ProvinceActions from "../../component/tableActionbar/location/ProvinceActions";
import { Button, Typography } from "@mui/material";
import BreadCrumb from "../../component/common/BreadCrumb";
import TableContainer from "../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../component/common/CustomLoader";
// import DefaultLocationSwitch from "../../component/tableActionbar/location/DefaultLocationSwitch";

function Province() {
  const dispatch = useDispatch();
  const [provinceToDisplay, setprovinceToDisplay] = useState([]);
  const {
    province,
    provinceLoading,
    provinceActionMessage,
    provinceActionError,
  } = useSelector((state) => state.province);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProvince());
  }, [dispatch]);

  useEffect(() => {
    setprovinceToDisplay(province);
  }, [province]);

  useEffect(() => {
    if (provinceActionMessage) {
      ToastService.success(provinceActionMessage);
    }
    if (provinceActionError) {
      ToastService.error(provinceActionError);
    }
    dispatch(provinceActionDeleteReset());
  }, [provinceActionMessage, provinceActionError, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: "Default Province",
      //   enableColumnFilter: false,
      //   cell: ({ row }) => (
      //     <Typography variant="p">
      //       <DefaultLocationSwitch row={row.original} type="Province" />
      //     </Typography>
      //   ),
      // },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <ProvinceActions row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="province-page">
      <BreadCrumb title="Province" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!provinceLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={provinceToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search Province"
              extraButton={
                <Button
                  className="text-white button-style capitalize"
                  variant="contained"
                  sx={{ marginLeft: "10px !important" }}
                  onClick={() => navigate(`/admin/location/provinces/add`)}
                >
                  Add Province
                </Button>
              }
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Province;
