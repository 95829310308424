import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { email } from "../../helpers/yup.validation.schema";
import Logo from "../../assets/Images/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotAdmin } from "../../middlewares/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../helpers/toast-services";
import { forgotReset } from "../../slices/auth";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotMessage, forgotError } = useSelector((state) => state.auth);

  useEffect(() => {
    if (forgotMessage) {
      navigate("/resetpassword/successfully");
    }
    if (forgotError) {
      ToastService.error(forgotError);
      dispatch(forgotReset());
    }
  }, [forgotMessage, forgotError, dispatch, navigate]);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
    };
    dispatch(forgotAdmin(payload));
  };

  const ForgotSchema = Yup.object({
    email: email,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotSchema,
    onSubmit: handleSubmit,
  });

  return (
    <section className="bg-gray-50">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img src={Logo} alt="Logo" className="mb-5 h-[100px]" />
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Forgot Password
              </h1>
              <p className="mt-1 text-[#b5b5c3] font-semibold text-sm">
                Enter your email to reset your password.
              </p>
            </div>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`bg-gray-50 border ${
                    formik.errors.email && formik.touched.email
                      ? "border-red-500"
                      : "border-gray-300"
                  } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  placeholder="name@company.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
