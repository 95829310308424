import { createSlice } from "@reduxjs/toolkit";

export const cmsSlice = createSlice({
  name: "cms",
  initialState: {
    cmsLoading: false,
    cmsMessage: "",
    cmsError: false,
    cms: null,
    editSuccess: false,
    editError: false,
    editMessage: "",
    editTermsSuccess: false,
    editTermsMessage: false,
    editTermsError: "",
  },
  reducers: {
    cmsRequest: (state) => {
      state.cmsLoading = true;
      state.cmsError = false;
      state.cmsMessage = "";
      state.cms = null;
    },
    cmsSuccess: (state, action) => {
      state.cmsLoading = false;
      state.cmsError = false;
      state.cms = action.payload.data;
      state.cmsMessage = action.payload.message;
    },
    cmsError: (state, action) => {
      state.cmsLoading = false;
      state.cmsError = true;
      state.cmsMessage = action.payload.message;
      state.cms = null;
    },
    editcmsRequest: (state) => {
      state.editSuccess = false;
      state.editError = false;
      state.editMessage = "";
    },
    editcmsSuccess: (state, action) => {
      state.editSuccess = true;
      state.editError = false;
      state.cms = action.payload.data;
      state.editMessage = action.payload.message;
    },
    editcmsError: (state, action) => {
      state.editSuccess = false;
      state.editError = true;
      state.editMessage = action.payload.message;
      state.cms = null;
    },

    editcmsTermsRequest: (state) => {
      state.editTermsSuccess = false;
      state.editTermsError = false;
      state.editTermsMessage = "";
    },
    editcmsTermsSuccess: (state, action) => {
      state.editTermsSuccess = true;
      state.editTermsError = false;
      state.cms = action.payload.data;
      state.editTermsMessage = action.payload.message;
    },
    editcmsTermsError: (state, action) => {
      state.editTermsSuccess = false;
      state.editTermsError = true;
      state.editTermsMessage = action.payload.message;
      state.cms = null;
    },

    cmsReset: (state) => {
      state.cmsLoading = false;
      state.cmsError = "";
      state.cmsMessage = "";
      state.editMessage = "";
    },
  },
});

export const {
  cmsRequest,
  cmsSuccess,
  cmsError,
  editcmsSuccess,
  editcmsError,
  editcmsRequest,
  cmsReset,
  editcmsTermsRequest,
  editcmsTermsSuccess,
  editcmsTermsError,
} = cmsSlice.actions;

export default cmsSlice.reducer;
