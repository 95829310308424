import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  OutlinedInput,
} from "@mui/material";
import * as Yup from "yup";
import BlankSvg from "../../../assets/Images/blank.png";
import { useFormik } from "formik";
import {
  address,
  email,
  lat,
  long,
  name,
  phone,
  selectCity,
  selectCountry,
  selectProvince,
  selectRegion,
} from "../../../helpers/yup.validation.schema";
import { Edit } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../helpers/toast-services";
import {
  getMerchantDetail,
  updateMerchantDetail,
} from "../../../middlewares/merchant";
import { getCountry } from "../../../middlewares/Country";
import { getProvince } from "../../../middlewares/Province";
import { getCity } from "../../../middlewares/City";
import { getMerchantDetailReset } from "../../../slices/merchant";
import BreadCrumb from "../../../component/common/BreadCrumb";
import { getRegion } from "../../../middlewares/region";

function EditSalesman() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCountry());
    dispatch(getProvince());
    dispatch(getCity());
    dispatch(getRegion());
  }, [dispatch]);

  const {
    getMerchantDetailLoading,
    merchantDetail,
    getMerchantDetailMessage,
    getMerchantDetailError,
  } = useSelector((state) => state.merchant);
  const { region } = useSelector((state) => state.region);
  const { country } = useSelector((state) => state.country);
  const { city } = useSelector((state) => state.city);
  const { province } = useSelector((state) => state.province);
  const [imgSrc, setImgSrc] = useState(BlankSvg);
  const [errorMessage, setErrorMessage] = useState("");
  const [cityArray, setCityArray] = useState([]);
  const [provinceArray, setProvinceArray] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const defaultCountry = country?.find((cntry) => cntry?.is_default === 1);
  useEffect(() => {
    let tempArray = province?.filter(
      (prov) => prov?.country_id === defaultCountry?.id
    );
    setProvinceArray(tempArray);
    setCountryCode(defaultCountry ? defaultCountry?.code : "");
  }, [defaultCountry, province]);

  useEffect(() => {
    if (id) {
      dispatch(
        getMerchantDetail({
          merchantId: id,
        })
      );
    }
  }, [dispatch, id]);

  const handleSubmit = (values) => {
    const payload = {
      id: id,
      fullName: values.name,
      email: values.email,
      countryCode: countryCode,
      phoneNo: values.phone,
      locationLAT: values.locationLat,
      locationLONG: values.locationLong,
      addressLine: values.address,
      cityId: values.city,
      provinceId: values.province,
      countryId: values.country,
      regionId: values.region,
    };
    if (values.file) {
      payload.file = values.file;
    }
    dispatch(updateMerchantDetail(payload));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImgSrc(reader.result);
          setErrorMessage("");
          formik.setFieldValue("file", file);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("file", null);
      }
    }
  };

  // console.log(merchantDetail);

  const EditMerchant = Yup.object({
    name: name,
    email: email,
    phone: phone,
    locationLong: long,
    locationLat: lat,
    address: address,
    city: selectCity,
    country: selectCountry,
    province: selectProvince,
    region: selectRegion,
  });

  const formik = useFormik({
    initialValues: {
      file: null,
      name: merchantDetail ? merchantDetail.fullName : "",
      email: merchantDetail ? merchantDetail.email : "",
      phone: merchantDetail ? merchantDetail.phoneNo : "",
      locationLong: merchantDetail ? merchantDetail.locationLONG : "",
      locationLat: merchantDetail ? merchantDetail.locationLAT : "",
      address: merchantDetail ? merchantDetail.addressLine : "",
      city: merchantDetail ? merchantDetail.cityId : "",
      country: merchantDetail
        ? merchantDetail.countryId
          ? merchantDetail.countryId
          : defaultCountry.id
        : "",
      province: merchantDetail ? merchantDetail.provinceId : "",
      region: merchantDetail ? merchantDetail.regionId : "",
    },
    enableReinitialize: true,
    validationSchema: EditMerchant,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (merchantDetail) {
      setImgSrc(
        merchantDetail?.profileImage
          ? `${process.env.REACT_APP_AWS_URL}/${merchantDetail?.profileImage}`
          : BlankSvg
      );

      setCityArray(
        city?.filter((prov) => prov?.province_id === merchantDetail?.provinceId)
      );
    }
  }, [merchantDetail, city, province, country]);

  const handleChangeCountry = (e) => {
    let tempArray = province?.filter(
      (prov) => prov.country_id === e.target.value
    );
    let selectedCountry = country.find(
      (country) => country.id === e.target.value
    );
    let cntryCode = selectedCountry ? selectedCountry.code : null;
    setCountryCode(cntryCode);
    setProvinceArray(tempArray);
    setCityArray([]);
    if (!merchantDetail.provinceId) {
      setCityArray([]);
    }
    // merchantDetail.provinceId ? "" : setCityArray([]);
    // console.log(provinceArray);
  };

  const handleProvinceCountry = (e) => {
    let tempArray = city.filter((prov) => prov.province_id === e.target.value);
    setCityArray(tempArray);
  };

  useEffect(() => {
    if (getMerchantDetailMessage) {
      navigate("/admin/merchant/active");
    }
    if (getMerchantDetailError) {
      ToastService.error(getMerchantDetailError);
      dispatch(getMerchantDetailReset());
    }
  }, [getMerchantDetailMessage, getMerchantDetailError, navigate, dispatch]);

  return (
    <div className="requestdetail-page">
      <BreadCrumb title="Edit Merchant" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="bg-white my-5 p-5 rounded-lg">
        {!getMerchantDetailLoading ? (
          merchantDetail ? (
            <Grid
              container
              spacing={2}
              style={{ marginTop: "0px", padding: "20px" }}
            >
              <Grid sm={12} md={12} style={{ marginBottom: "20px" }}>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={formik.handleSubmit}
                >
                  <Box className="">
                    <div>
                      <label className="font-medium">Profile Image</label>
                      <br />
                      <div className="flex flex-col mt-2 justify-center items-start gap-x-2.5">
                        <div className="relative">
                          <div className="w-24 h-24 overflow-hidden rounded-[50%]">
                            <img
                              src={imgSrc}
                              alt="flag"
                              className="w-full h-full object-cover"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0 h-[32px] w-[32px] rounded-[50%] bg-[#E5FFF1] flex items-center justify-center">
                            <input
                              type="file"
                              name="file"
                              accept="image/png, image/jpeg,image/jpg"
                              id="editimg"
                              onChange={(e) => {
                                handleFileChange(e); // Call your custom function
                              }}
                              hidden
                            />
                            <label
                              htmlFor="editimg"
                              className="cursor-pointer "
                            >
                              <Edit className="text-[#00B052]" />
                            </label>
                          </div>
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="text-red-500 text-sm mt-1">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Name</label>
                      <OutlinedInput
                        placeholder="Enter Name"
                        name="name"
                        id="name"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.name}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Email</label>
                      <OutlinedInput
                        placeholder="Enter Email"
                        name="email"
                        id="email"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        disable
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.email}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Phone Number
                      </label>
                      <div className="relative">
                        <span className="absolute top-[10%] px-4 py-[10px] z-20 border-r-[2px] border-solid">
                          {countryCode}
                        </span>
                        <OutlinedInput
                          placeholder="Enter Phone Number"
                          name="phone"
                          id="phone"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          className="w-full pl-16"
                        />
                      </div>
                      {formik.errors.phone && formik.touched.phone && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.phone}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Latitude
                      </label>
                      <OutlinedInput
                        placeholder="Enter location latitude"
                        name="locationLat"
                        id="locationLat"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.locationLat}
                      />
                      {formik.errors.locationLat &&
                        formik.touched.locationLat && (
                          <Box className="text-red-500 text-sm mt-1">
                            {formik.errors.locationLat}
                          </Box>
                        )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Longitude
                      </label>
                      <OutlinedInput
                        placeholder="Enter location longitude"
                        name="locationLong"
                        id="locationLong"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.locationLong}
                      />
                      {formik.errors.locationLong &&
                        formik.touched.locationLong && (
                          <Box className="text-red-500 text-sm mt-1">
                            {formik.errors.locationLong}
                          </Box>
                        )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Address
                      </label>
                      <OutlinedInput
                        placeholder="Enter Address"
                        name="address"
                        id="address"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.address}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label
                        htmlFor="language"
                        sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                      >
                        Region
                      </label>
                      <select
                        name="region"
                        id="region"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.region}
                        className="select-style font-medium p-4 mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
                      >
                        <option value="" hidden>
                          Select Region
                        </option>
                        {region?.map((reg) => (
                          <option key={reg.id} value={reg.id}>
                            {reg.name}
                          </option>
                        ))}
                      </select>
                      {formik.errors.region && formik.touched.region && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.region}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label
                        htmlFor="language"
                        sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                      >
                        Country
                      </label>
                      <select
                        name="country"
                        id="country"
                        onChange={(e) => {
                          handleChangeCountry(e);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                        placeholder="dnhlnhlsfglsjlf"
                        className="select-style font-medium p-4  mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
                      >
                        <option selected hidden>
                          Select Country
                        </option>
                        {country?.map((cntry) => (
                          <option key={cntry.id} value={cntry.id}>
                            {cntry.name}
                          </option>
                        ))}
                      </select>
                      {formik.errors.country && formik.touched.country && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.country}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Province
                      </label>
                      <select
                        name="province"
                        id="province"
                        onChange={(e) => {
                          handleProvinceCountry(e);
                          formik.handleChange(e);
                        }}
                        disabled={provinceArray.length === 0}
                        onBlur={formik.handleBlur}
                        value={formik.values.province}
                        className="select-style font-medium p-4  mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
                      >
                        <option selected hidden>
                          Select Province
                        </option>
                        {provinceArray?.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                      {formik.errors.province && formik.touched.province && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.province}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl className="w-full" variant="outlined">
                      <label sx={{ color: "rgba(0, 0, 0, 0.6)" }}>City</label>
                      <select
                        name="city"
                        id="city"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        disabled={cityArray.length === 0}
                        // value={`${formik?.values?.languageId}`}
                        placeholder="dnhlnhlsfglsjlf"
                        className="select-style font-medium p-4  mt-2 w-full border border-[#0000003B] rounded-2xl shadow-sm focus:border focus:border-gray-400 focus:outline-none focus:ring focus:ring-white"
                      >
                        {/* <option selected={!formik?.values?.languageId} hidden> */}
                        <option selected hidden>
                          Select City
                        </option>
                        {cityArray?.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      {formik.errors.city && formik.touched.city && (
                        <Box className="text-red-500 text-sm mt-1">
                          {formik.errors.city}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                  <Box className="flex items-end justify-end gap-4 text-end">
                    <Button
                      type="submit"
                      className="text-white button-style capitalize"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )
        ) : (
          <Box className="text-center">
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
}

export default EditSalesman;
