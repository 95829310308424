import { Grid } from "@mui/material";
import verified from "../../assets/Images/verified.svg";
import React from "react";
import { useSelector } from "react-redux";

function ResetPassSuccess() {
  const { forgotMessage } = useSelector((state) => state.auth);
  const { resetpassMessage } = useSelector((state) => state.resetpass);

  return (
    <div className="verified-page bg-white flex h-screen ">
      <Grid container className="md:h-screen flex items-center justify-center">
        <Grid item className="max-h-auto">
          {/* <Box className='flex justify-center flex-col h-full p-5 w-[500px] rounded-2xl small-device-width'> */}
          <div className="flex justify-center">
            <img src={verified} alt="logo" className=" text-center w-48 " />
          </div>
          <p className="text-center mt-10 mb-5 text-3xl font-medium text-[#00b052]">
            {resetpassMessage && "Successfully Password Updated "}
            {forgotMessage &&
              "We have send one reset password link to your registered email address. Please proceed by clicking on send link"}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResetPassSuccess;
