import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Typography } from "@mui/material";
import blankSalesman from "../../../assets/Images/blank.png";
import { getSalesmanDetail } from "../../../middlewares/salesman";
import BreadCrumb from "../../../component/common/BreadCrumb";

function MerchantDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { salesmanDetail, getSalesmanDetailLoading } = useSelector(
    (state) => state.salesman
  );

  useEffect(() => {
    if (true) {
      dispatch(
        getSalesmanDetail({
          salesmanId: id,
        })
      );
    }
  }, [dispatch, id]);

  return (
    <div className="requestdetail-page">
      <BreadCrumb title="Detail" pageTitle={"Dashboard"} />
      <div className="bg-white m-5 p-5 rounded-lg">
        {!getSalesmanDetailLoading ? (
          salesmanDetail ? (
            <Grid
              container
              spacing={2}
              style={{ marginTop: "0px", padding: "20px" }}
            >
              <Grid sm={12} md={6} style={{ marginBottom: "20px" }}>
                <Typography variant="h7" className="text-center font-semibold">
                  Details
                </Typography>
                <img
                  className="h-24 w-24 my-4 object-cover "
                  alt={salesmanDetail.fullName}
                  src={
                    salesmanDetail.profileImage
                      ? `https://glastonbury-media.s3.us-east-1.amazonaws.com/${salesmanDetail.profileImage}`
                      : blankSalesman
                  }
                />
                <p>
                  <strong>Name:</strong> {salesmanDetail.fullName}
                </p>
                <p>
                  <strong>email:</strong> {salesmanDetail.email}
                </p>
                <p>
                  <strong>Phone No.:</strong> {salesmanDetail.countryCode}{" "}
                  {salesmanDetail.phoneNo}
                </p>
                <p>
                  <strong>Pending Request :</strong>{" "}
                  {salesmanDetail.pendingRequestCount}
                </p>
              </Grid>
              {/* <p>{merchantDetail.fullName}</p> */}
            </Grid>
          ) : (
            <p>No data found</p>
          )
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

export default MerchantDetail;
