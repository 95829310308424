import requestService from "../services/request";
import {
  addRequest,
  addRequestError,
  addRequestSuccess,
  getRequest,
  getRequestSuccess,
  getRequestError,
  getRequestDetail,
  getRequestDetailSuccess,
  getRequestDetailError,
} from "../slices/request";
import { handleAuthError } from "../utlils/authErrorHandler";

/*---------- get request -------*/
export function getReq(payload) {
  return (dispatch) => {
    dispatch(getRequest());
    requestService
      .getreq(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(getRequestSuccess({ ...data, message }));
        } else {
          dispatch(getRequestError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(getRequestError(error));
      });
  };
}
/*---------- get request detail-------*/
export function getReqDetail(payload) {
  return (dispatch) => {
    dispatch(getRequestDetail());
    requestService
      .getreqdetail(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(getRequestDetailSuccess({ ...data, message }));
        } else {
          dispatch(getRequestDetailError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(getRequestDetailError(error));
      });
  };
}
/*---------- assign request -------*/
export function assignRequest(payload) {
  return (dispatch) => {
    dispatch(addRequest());
    requestService
      .assignRequest(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(addRequestSuccess({ message }));
        } else {
          dispatch(addRequestError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addRequestError(error));
      });
  };
}

/*---------- swap request -------*/
export function swapRequest(payload) {
  return (dispatch) => {
    dispatch(addRequest());
    requestService
      .swapRequest(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(addRequestSuccess({ message }));
        } else {
          dispatch(addRequestError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addRequestError(error));
      });
  };
}

/*---------- Swap Request for Merchant -------*/
export function swapRequestForMerchant(payload) {
  return (dispatch) => {
    dispatch(addRequest());
    requestService
      .swapRequestForMerchant(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(addRequestSuccess({ message }));
        } else {
          dispatch(addRequestError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addRequestError(error));
      });
  };
}
