import { handleAuthError } from "../../src/utlils/authErrorHandler";
import salesmanService from "../services/salesman";
import {
  salesmanError,
  salesmanRequest,
  salesmanSuccess,
  salesmanActionRequest,
  salesmanActionSuccess,
  salesmanActionError,
  getSalesmanDetailError,
  getSalesmanDetailRequest,
  getSalesmanDetailSuccess,
  addSalesmanRequest,
  addSalesmanSuccess,
  addSalesmanError,
  salesmanFilterRequest,
  salesmanFilterSuccess,
  salesmanFilterError,
} from "../slices/salesman";

/*---------- get salesman -------*/
export function getSalesman(payload) {
  return (dispatch) => {
    dispatch(salesmanRequest());
    salesmanService
      .salesman(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(salesmanSuccess({ ...data, message }));
        } else {
          dispatch(salesmanError(message));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanError(error));
      });
  };
}

/*---------- active-deactive salesman -------*/

export function salesmanStatus(payload) {
  return (dispatch) => {
    dispatch(salesmanActionRequest());
    salesmanService
      .salesmanStatus(payload)
      .then((data) => {
        const { message, status } = data;
        let { salesmanId } = payload;
        if (status === 1) {
          dispatch(
            salesmanActionSuccess({
              salesmanId,
              message,
            })
          );
        } else {
          dispatch(salesmanActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanActionError(error));
      });
  };
}

/*---------- pending to active salesman -------*/

export function salesmanPenToAct(payload) {
  return (dispatch) => {
    dispatch(salesmanActionRequest());
    salesmanService
      .salesmanPendingtoActive(payload)
      .then((data) => {
        const { message, status } = data;
        let { salesmanId } = payload;
        if (status === 1) {
          dispatch(
            salesmanActionSuccess({
              salesmanId,
              message,
            })
          );
        } else {
          dispatch(salesmanActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanActionError(error));
      });
  };
}

/*---------- create marchant -------*/

export function salesmanAdd(payload) {
  return (dispatch) => {
    dispatch(addSalesmanRequest());
    salesmanService
      .addSalesmam(payload)
      .then((data) => {
        const { message, status } = data;
        if (status === 1) {
          dispatch(addSalesmanSuccess({ message }));
        } else {
          dispatch(addSalesmanError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(addSalesmanError(error));
      });
  };
}

/*---------- delete salesman -------*/

export function salesmanDelete(payload) {
  return (dispatch) => {
    dispatch(salesmanActionRequest());
    salesmanService
      .salesmanDelete(payload)
      .then((data) => {
        const { message, status } = data;
        let { salesmanId } = payload;
        if (status === 1) {
          dispatch(
            salesmanActionSuccess({
              salesmanId,
              message,
            })
          );
        } else {
          dispatch(salesmanActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanActionError(error));
      });
  };
}

/*---------- restore salesman -------*/

export function salesmanRestore(payload) {
  return (dispatch) => {
    dispatch(salesmanActionRequest());
    salesmanService
      .salesmanRestore(payload)
      .then((data) => {
        const { message, status } = data;
        let { salesmanId } = payload;
        if (status === 1) {
          dispatch(
            salesmanActionSuccess({
              salesmanId,
              message,
            })
          );
        } else {
          dispatch(salesmanActionError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanActionError(error));
      });
  };
}

/*---------- get salesman detail-------*/
export function getSalesmanDetail(payload) {
  return (dispatch) => {
    dispatch(getSalesmanDetailRequest());
    salesmanService
      .getSalesmanDetail(payload)
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          dispatch(getSalesmanDetailSuccess({ response }));
        } else {
          dispatch(getSalesmanDetailError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(getSalesmanDetailError(error));
      });
  };
}

export function updateSalesmanDetail(payload) {
  return (dispatch) => {
    dispatch(getSalesmanDetailRequest());
    salesmanService
      .updateSalesmanDetail(payload)
      .then((data) => {
        const { message, status, response } = data;
        if (status === 1) {
          let profileData = response?.profile;
          dispatch(
            getSalesmanDetailSuccess({
              response: profileData,
              message,
            })
          );
        } else {
          dispatch(getSalesmanDetailError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(getSalesmanDetailError(error));
      });
  };
}

/*---------- get filter salesman -------*/
export function getFilterSalesman(payload) {
  return (dispatch) => {
    dispatch(salesmanFilterRequest());
    salesmanService
      .salesmanFilter(payload)
      .then((data) => {
        const { response, message, status } = data;
        if (status === 1) {
          dispatch(salesmanFilterSuccess({ response, message }));
        } else {
          dispatch(salesmanFilterError({ message }));
        }
      })
      .catch((error) => {
        handleAuthError(error.response.status);
        dispatch(salesmanFilterError(error));
      });
  };
}
