import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/location";

/*---------- get Country -------*/
const country = async (payload) => {
  const URL = `${URI}/get-country`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get country:", error);
    throw error;
  }
};

/*---------- Delete Country -------*/
const countryDelete = async (payload) => {
  const URL = `${URI}/delete-country`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete country:", error);
    throw error;
  }
};

/*---------- Create Country -------*/
const countryCreate = async (payload) => {
  const URL = `${URI}/create-country`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create country:", error);
    throw error;
  }
};

/*---------- Update Country -------*/
const countryUpdate = async (payload) => {
  const URL = `${URI}/update-country`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred create country:", error);
    throw error;
  }
};

/*---------- set Country -------*/
const setDefaultCountry = async (payload) => {
  const URL = `${URI}/set-default-country`;

  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error occurred set default country:", error);
    throw error;
  }
};

const countryService = {
  country,
  countryDelete,
  countryCreate,
  countryUpdate,
  setDefaultCountry,
};

export default countryService;
