import * as Yup from "yup";
const spaceRegExp = /^\S*$/;
const phoneRegExp =
  /^((\+[1-9]{1,4})|(\([0-9]{2,3}\))|([0-9]{2,4}))*?[0-9]{3,4}?[0-9]{3,4}?$/;
const latlogRegExp = /^-?\d{2,3}\.\d{2,}$/;

export const email = Yup.string()
  .email("Email is invalid")
  .required("Email is required");

export const Password = Yup.string()
  .min(8, "Password should be at least 8 characters long")
  .matches(spaceRegExp, "Password cannot contain spaces")
  .max(50, "Password should not contain more than 50 characters")
  .required("Password is required");

export const oldPassword = Yup.string()
  .min(8, "Old password should be at least 8 characters long")
  .matches(spaceRegExp, "Old password cannot contain spaces")
  .max(50, "Old password should not contain more than 50 characters")
  .required("Old password is required");

export const newPassword = Yup.string()
  .min(8, "New password should be at least 8 characters long")
  .matches(spaceRegExp, "New password cannot contain spaces")
  .max(50, "New password should not contain more than 50 characters")
  .required("New password is required");

export const newPasswordApp = Yup.string()
  .min(6, "New password should be at least 6 characters long")
  .matches(spaceRegExp, "New password cannot contain spaces")
  .max(50, "New password should not contain more than 50 characters")
  .required("New password is required");

export const confirmPassword = Yup.string()
  .oneOf(
    [Yup.ref("password")],
    "New password and confirm password do not match!"
  )
  .matches(spaceRegExp, "Confirm password cannot contain spaces")
  .required("Confirm password Is required");

export const name = Yup.string().required("Merchant name is required");

export const Countryname = Yup.string().required("Country name is required");
export const Provincename = Yup.string().required("Province name is required");
export const Cityname = Yup.string().required("City name is required");

export const CountryCode = Yup.string()
  .matches(/^\+\d+$/, "Country code must start with a '+' followed by numbers")
  .required("Country code is required");

export const phone = Yup.string()
  .required("Phone number is required")
  .matches(
    phoneRegExp,
    "Phone number is not valid; it should be a valid format without character,spaces or hyphens"
  )
  .max(10, "Phone number should not exceed 10 characters")
  .min(10, "Phone number should be at least 10 characters");

export const lat = Yup.string()
  .required("Latitude is required")
  .matches(latlogRegExp, "Enter proper value");

export const long = Yup.string()
  .required("Longitude is required")
  .matches(latlogRegExp, "Enter proper value");

export const address = Yup.string().required("Address is required");
export const file = Yup.string().required("File is required");
export const selectCity = Yup.string().required("Please select city");
export const selectCountry = Yup.string().required("Please select country");
export const selectProvince = Yup.string().required("Please select province");
export const selectRegion = Yup.string().required("Please select region");
