import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import BreadCrumb from "../../../component/common/BreadCrumb";
import TableContainer from "../../../component/common/tablecomponet/TableContainer";
import CustomLoader from "../../../component/common/CustomLoader";
import ToastService from "../../../helpers/toast-services";
import RegionActions from "../../../component/tableActionbar/region";
import { getRegion } from "../../../middlewares/region";
import {
  addRegionreset,
  editRegionreset,
  regiondeleteReset,
} from "../../../slices/region";

function Region() {
  const dispatch = useDispatch();
  const [regionToDisplay, setRegionToDisplay] = useState([]);
  const {
    region,
    regionLoading,
    addRegionMessage,
    regionDeleteError,
    regionDeleteMessage,
    editRegionMessage,
  } = useSelector((state) => state.region);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRegion());
  }, [dispatch]);

  useEffect(() => {
    setRegionToDisplay(region);
  }, [region]);

  useEffect(() => {
    if (addRegionMessage) {
      ToastService.success(addRegionMessage);
      dispatch(addRegionreset());
    }
    if (editRegionMessage) {
      ToastService.success(editRegionMessage);
      dispatch(editRegionreset());
    }
    if (regionDeleteMessage) {
      ToastService.success(regionDeleteMessage);
      dispatch(regiondeleteReset());
    }
    if (regionDeleteError) {
      ToastService.error(regionDeleteError);
      dispatch(regiondeleteReset());
    }
  }, [
    regionDeleteMessage,
    addRegionMessage,
    editRegionMessage,
    regionDeleteError,
    dispatch,
  ]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: "action",
        header: <p className="text-right">Action</p>,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Typography variant="p">
            <RegionActions row={row.original} />
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <div className="region-page">
      <BreadCrumb title="Region" pageTitle={"Dashboard"} />
      <ToastContainer />
      <div className="card bg-white mt-5">
        <div className="card-body p-3">
          {!regionLoading ? (
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={regionToDisplay}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200"
              trclassName="group-[.stripe]:even:bg-slate-50 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left "
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              SearchPlaceholder="Search region"
              extraButton={
                <Button
                  className="text-white button-style capitalize"
                  variant="contained"
                  sx={{ marginLeft: "10px !important" }}
                  onClick={() => navigate(`/admin/location/Region/add`)}
                >
                  Add region
                </Button>
              }
            />
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Region;
