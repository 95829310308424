import axios from "axios";
const URI = process.env.REACT_APP_BASE_URL + "/admin/cms";

const editCms = async (payload) => {
  const URL = `${URI}/update`;
  try {
    let user = localStorage.getItem("user-details");
    let token = JSON.parse(user).token;

    const response = await axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

const getSingleCMS = async (payload) => {
  const URL = `${URI}/get`;
  let user = localStorage.getItem("user-details");
  let headers = {
    Authorization: `Bearer ${JSON.parse(user)?.token}`,
  };
  try {
    const response = await axios.post(URL, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

const CMSService = {
  editCms,
  getSingleCMS,
};

export default CMSService;
