import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/global.scss";
import "./assets/scss/themes.scss";
import configStore from "./config/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import { ErrorBoundary } from "react-error-boundary";

const store = configStore();
// const ErrorFallback = lazy(() => import("./component/common/ErrorFallback"));
// const Loader = lazy(() => import("./component/common/Loading"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
    <Router>
      {/* <Suspense fallback={<Loader />}> */}
      <Provider store={store}>
        <App />
      </Provider>
      {/* </Suspense> */}
    </Router>
    {/*</ErrorBoundary> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
